<!-- 特卖头部 -->
<template>
	<div class="topbg">
		<div class="bg">
			<!-- <button class="b">立即抢购</button> -->
		<!-- 	<p class="p">每日<span>零</span>点更新，超低价好货</p>
			<p class="p1">限时限量<span>·</span>抢完即止</p> -->
			<img :src="imgs">
		</div>
	</div>
</template>
<script>
export default {
  name: 'superSellTop',
  components: {
  },
  props: {
    msg: String,
    imgs:null,
  },
  methods:{
  }
}
</script>
<style  lang="scss" scoped>
.topbg {
	// height: 815px;
	width: 1920px;margin:0 auto;position: relative;
	.bg {
		// background: url('../../assets/img/superselltop/top.png') center -1px no-repeat;
		// width: 1380px;left:-89px;top:0px;background-size: 100%;height: 815px;position: absolute;
		width: 100vw;overflow: hidden;

		.b {
			color:#fff;border:8px solid #fff;border-radius: 15px;width: 200px;height: 50px;margin:0 auto;display: block;font-size: 24px;background: none;line-height: 30px;margin-top:65px;position: relative;z-index: 9;
			&:hover {
				opacity: 0.8;
			}
		}
		.p {
			color:#f7ec5f;text-align: center;font-size: 24px;margin-top:320px;
			span {
				font-size: 36px;display: inline-block;border:4px solid #f7ec5f;border-radius: 50%;width: 70px;height: 70px;text-align: center;line-height: 70px;margin:0 10px;
			}
		}
		.p1 {
			color:#fff;text-align: center;margin-top:60px;font-size: 38px;font-weight: bold;
			span {
				font-size: 40px;margin:0 10px;
			}
		}
		img {
			// position: absolute;top:0px;left:0px;width: 100%;height: auto;
			margin-left:calc((100vw - 1920px)/2);
		}
	}
	@media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
	    .bg {
	      width:1200px;
	      img {
				// position: absolute;top:0px;left:0px;width: 100%;height: auto;
				margin-left:calc((1200px - 1920px)/2);
			}
	    }
	}
}
</style>
