<!-- 底部备案 -->
<template>
  <div class="cnt"  :class='{othertype:this.$route.path.indexOf("/accountlogin")!=-1||this.$route.path.indexOf("/accountregister")!=-1,types1:types===1,shows:show}'>
    <p name='insetgs'><a  href="https://beian.miit.gov.cn">粤ICP备14072167号</a> <span>深圳市微微数码有限公司</span></p>
    <div class="links">
      <a href="javascript:;" @click='links(0)'>关于我们</a>
      <a href="javascript:;" @click='links(1)'>联系我们</a>
      <a href="javascript:;" @click='links(2)'>商务合作</a>
      <a href="javascript:;" @click='links(3)'>版权声明</a>
      <a href="javascript:;" @click='links(4)'>服务协议</a>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Vrecord',
  props: {
    height: String,
    carousels:Array,
    types:{
      default:0,
      type:Number
    }
  },
  data(){
    return {
      show:false
    }
  },
  methods:{
    links(v){
      // 跳转路由
      switch(v) {
        case 0:
          this._router({then:()=>{
            this.$router.push({path: "/aboutus.html"})
          }})
          break;
        case 1:
          this._router({then:()=>{
            this.$router.push({path: "/aboutuscontact.html"})
          }})
          break;
        case 2:
          this._router({then:()=>{
            this.$router.push({path: "/aboutusteamwork.html"})
          }})
          break;
        case 3:
          this._router({then:()=>{
            this.$router.push({path: "/aboutusstate.html"})
          }})
          break;
        case 4:
          this._router({then:()=>{
            this.$router.push({path: "/aboutusservice.html"})
          }})
          break;
        default:
          break;
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var footb = function() {
          return new Promise(function(resolve, reject) {
              // 工商icon
              // $('a').each((i,v)=>{
              //   if(v.href=='https://szcert.ebs.org.cn/cd53381e-413c-4907-b3b5-ecdb82db689c'){
              //     $(v).css({
              //       'position':'absolute',
              //       "top":'-5px',
              //       'left':'0px'
              //     })
              //     var _a = $(v).clone(true)
              //     $(v).remove()
              //     $('p[name=insetgs]').prepend(_a)
              //   }
              // })
              $('a[href="https://szcert.ebs.org.cn/cd53381e-413c-4907-b3b5-ecdb82db689c"]').css({
                'position':'absolute',
                "top":'-5px',
                'left':'0px'
              })
              var _a = $('a[href="https://szcert.ebs.org.cn/cd53381e-413c-4907-b3b5-ecdb82db689c"]').clone(true)
              $('a[href="https://szcert.ebs.org.cn/cd53381e-413c-4907-b3b5-ecdb82db689c"]').remove()
              $('p[name=insetgs]').prepend(_a)
              $('a[href="https://beian.miit.gov.cn"]').css({
                'position':'inherit',
                'top':'auto',
                'color':'#363f46'
              })
              _this.show = true
              resolve()
          })
      }
      this.$emit('footPomise',footb)
    })
  }
}
</script>
<style  lang="scss" scoped>
.cnt {
  background: #f5f5f5;opacity: 0; 
  p {
    text-align: center;color:#292929;font-size: 14px;height: 26px;line-height: 26px;position: relative;width: 520px;margin:0 auto;
    span {
      color:#363f46;font-size: 14px;
    }
    a {
      position: absolute;top:-5px;left:0px;
    }
  }
  .links {
    text-align: center;margin-bottom:5px;
    a {
      display: inline-block;color:#292929;margin-right:30px;font-size: 14px;position: relative;
      &:last-child {
        margin-right: 0px;
      }
      &:after {
        content: '';display: block;position: absolute;width: 3px;height: 3px;background: #292929;top:9px;right:-16px;border-radius: 50%;
      }
      &:last-child:after {
        display: none;
      }
      &:hover {
        color:#777;
      }
    }
  }
}
.types1 {
  background: #f2f2f2;
}
.othertype {
  padding-top:50px;
  // background: #fff;
}
.shows {
  opacity: 1;
}
</style>
