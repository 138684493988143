<!-- 通用头部 -->
<template>
  <div class="content" >
    <!-- v-if='this.$store.state.topimgshow' -->
    <div class="top" id="topImgBanner" v-show='banners&&closeTopimgs'>
      <div class="imgbox" @click='joinLink' >
   <!--      <span>加入微微定</span>
        <span>获取您的专属定制</span>
        <a href="javascript:;" target="_Blank">立即加入</a> -->
        <img class="imgs" :title='bannersName' :src="banners" >
        <button class="closeImg" @click.stop='closeTopimg'><svg class="icon"><use xlink:href="#iconshanchu"></use></svg></button>
      </div>
    </div>
    <div class="navsBox">
      <el-row  class="navs" >
        <el-col :span="8" class='lt'>
          <p v-if='this.$store.state.user?false:true'><span>您好，欢迎来到微微定，请</span><button @click='logins'>【登录】</button><button @click='registers'>【免费注册】</button></p>
          <p v-if='this.$store.state.user?true:false'>HI~欢迎您，<span class="name" @click='linka(3)'>{{this.$store.state.user?this.$store.state.user.ContactName:'null' | formatPhoneHide(1)}}</span><button :disabled="outlogind" @click='outlogin' class="outlg">【安全退出】</button></p>
        </el-col>
        <el-col :span="16" class='rt'>
          <!-- <a href="">网站导航</a> -->
          <a href="javascript:;" @click='linka(1)'>商务合作</a>
          <a href="javascript:;" @click='linka(2)'>帮助中心</a>
          <a href="javascript:;" @click='linka(3)'>我的微微定</a>
          <a href="javascript:;" class="moveP">
            <svg class="icon"><use xlink:href="#iconshouji1"></use></svg>移动端
            <div class="moveBox">
              <div class="moveBoxS">
                <div class="div1">
                  <img src="../../assets/moveP1.jpg">
                  <div class="div2">
                    <p>微微定公众号平台</p>
                    <p>新用户享受<span>1</span>元体验购</p>
                  </div>
                </div>
                <div class="div1">
                  <img src="../../assets/moveP.jpg">
                  <div class="div2">
                    <p>微微定微信小程序</p>
                    <p>体验流畅，操作简单</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a href="javascript:;" @click='linka(4)'>我的订单</a>
          <a class="buycarlink" href="javascript:;" @click='linkbuycar'><svg class="icon"><use xlink:href="#iconziyuan5"></use></svg>购物车(<span>{{this.$store.state.buycarum}}</span>)</a>
        </el-col>
      </el-row>
    </div>
    <el-row  class="navs1" type='flex' align='bottom' v-if='this.$store.state.layout.buycar&&this.$route.path.indexOf("/usercenter")==-1&&this.$route.path.indexOf("/usercenterorder")==-1&&this.$route.path.indexOf("/orderdetail")==-1&&this.$route.path.indexOf("/applyafter")==-1&&this.$route.path.indexOf("/userremark")==-1&&this.$route.path.indexOf("/remarkinfo")==-1&&this.$route.path.indexOf("/remarkdetail")==-1&&this.$route.path.indexOf("/remarkresult")==-1&&this.$route.path.indexOf("/userprice")==-1&&this.$route.path.indexOf("/userrecharge")==-1&&this.$route.path.indexOf("/rechargemethod")==-1&&this.$route.path.indexOf("/rechargepay")==-1&&this.$route.path.indexOf("/payresultre")==-1&&this.$route.path.indexOf("/safecenter")==-1&&this.$route.path.indexOf("/setsteps")==-1&&this.$route.path.indexOf("/idverify")==-1&&this.$route.path.indexOf("/verifysetpas")==-1&&this.$route.path.indexOf("/verifysetresult")==-1&&this.$route.path.indexOf("/changepas")==-1&&this.$route.path.indexOf("/userdiscount")==-1&&this.$route.path.indexOf("/userdata")==-1&&this.$route.path.indexOf("/addressdata")==-1&&this.$route.path.indexOf("/discountcenter")==-1' >
      <el-col :span="4" class='lt'>
        <!-- <svg class="icon"><use xlink:href="#iconziyuan8"></use></svg> -->
        <img :src="logos" :title='logosName' class="logos" @click='logoslink' v-if='logosBox&&logosBox.length>0'>
      </el-col>
      <el-col :span="14" class='cnt'>
        <button  @click='links(item,index)' :class='{choice:index==0}' v-for='item,index in nav1Txt' ><span>{{item.BannerName}}</span></button>
      </el-col>
      <el-col :span="6" class='rt'>
        <!-- <input type="password" style="position: fixed;z-index:-9999;background: none;">
        <input @focus='focus' @blur='blur' class="ipt" type="text" v-model='searchTxt'>
        <button @click.stop='searchLink'  class="sh"><svg class="icon"><use xlink:href="#iconziyuan3"></use></svg></button>
         <div v-show='hotShow' class="hots" v-if='hotSearch&&hotSearch.length>0'>
          <button v-for='item in hotSearch'>{{item.v}}</button>
        </div>-->
        <hotsearch></hotsearch>
      </el-col>
    </el-row>
    <div v-show='this.$route.path.indexOf("/usercenter")!=-1||this.$route.path.indexOf("/usercenterorder")!=-1||this.$route.path.indexOf("/orderdetail")!=-1||this.$route.path.indexOf("/applyafter")!=-1||this.$route.path.indexOf("/userremark")!=-1||this.$route.path.indexOf("/remarkinfo")!=-1||this.$route.path.indexOf("/remarkdetail")!=-1||this.$route.path.indexOf("/remarkresult")!=-1||this.$route.path.indexOf("/userprice")!=-1||this.$route.path.indexOf("/userdiscount")!=-1||this.$route.path.indexOf("/userdata")!=-1||this.$route.path.indexOf("/addressdata")!=-1||this.$route.path.indexOf("/discountcenter")!=-1' class="navType">
      <div class="box">
        <div class="lt">
          <img :src="logos" :title='logosName' class="logos" @click='logoslink' v-if='logosBox&&logosBox.length>0'>
          <span>我的微微定</span>
        </div>
        <div class="rt">
          <hotsearch class='search'></hotsearch>
        </div>
      </div>
    </div>
    <register v-show='this.$store.state.regtagger' ></register>
    <login v-show='this.$store.state.logintagger'  ></login>
  </div>
</template>
<script>
// import Vue from 'vue'
// import {Row,Col} from 'element-ui';//单独提示组件可布局全局对象
// Vue.use(Row);
// Vue.use(Col);
import register from '@/components/register/register.vue';//注册
import login from '@/components/login/login.vue';//登录
import hotsearch from '@/components/hotsearch/hotsearch.vue';//带热门搜索组件
import {mixin,usermethod,setCarlist} from '../../assets/js/common.js' //公用js
export default {
  name: 'Vheader',
  mixins: [mixin],//混入
  props: {
    msg: String
  },
  components:{
    register,
    hotsearch,
    login
  },
  data(){
    return {
      // hotSearch:[{v:'盒装纸巾'},{v:'名片'}],
      hotSearch:[],
      hotShow:true,
      searchTxt:null,
      nav1Txt:[
        // {v:'首页',hr:'/'},{v:'营销展会',hr:'/'},{v:'餐饮印品',hr:'/'},{v:'行政办公',hr:'/'},{v:'一次性用品',hr:'/'},{v:'礼品定制',hr:'/'},{v:'头条资讯',hr:'/'},
      ],
      // regtagger:false,
      // logintagger:false,
      banners:null,
      bannersName:null,
      logos:null,
      logosName:null,
      logosBox:null,
      LinkUrl:null,
      logoslinkUrl:null,
      closeTopimgs:true,//关闭顶部banner
      closeTopimgsShow:null,
      outlogind:false,
      // buycarum:0,// 购物车数量
    }
  },
  methods:{
    linka(v){
      // a标签跳转
      if(v==4){
        // 我的订单
        var _obj = {
          orderTypes:0,
          orderNumbers:'',
          pageIndexs:1
        }
        window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
        this._router({then:()=>{
          this.$router.push({path:'/usercenterorder.html'})
          // this._open({path: "/buycar"})
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==3){
        this._router({then:()=>{
          this.$router.push({path:'/usercenter.html'})
          // this._open({path: "/buycar"})
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenter.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==2){
        this._router({then:()=>{
          this.$router.push({path: "/guidequestion.html"})
        }})
      }
      if(v==1){
        this._router({then:()=>{
          this.$router.push({path: "/aboutusteamwork.html"})
        }})
      }
    },
    searchLink(){
      // 搜索跳转
      this.$store.dispatch('cmtsearchClick',this.$store.state.searchClick+1)
     if(this.$route.path.indexOf('/classlist')==-1){
        // console.log(1)
        return;
        this._router({exp:false,catch:()=>{
          // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
          this._open({path: "/accountlogin",query: {redirect:'/index.html'}})
        },then:()=>{
          this.$router.push({path:'/classlist',query:{keys:123}})
          // this._open({path: "/buycar"})
        }})
      }
    },
    joinLink(){
      // 加入微微定跳转
      // this.LinkUrl
      if(this.LinkUrl){
        window.open(this.LinkUrl)
      }
    },
    logoslink(){
      // logo跳转
      // window.open()
      // window.location.href=this.logoslinkUrl
      if(this.$store.state.logoUrl){
        window.location.href=this.$store.state.logoUrl
      }
    },
    outlogin(){
      // 退出登录
      this.outlogind  = true
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Login/LoginOut?token='+this._token({method:'get'}),this.keys())
        .then(res => {
            if(res.data.Success){
              this.outlogind = false
              this.$store.dispatch("cmtSetUser",null);//赋值
              usermethod({method:'del'});//存本地
              this._token({method:'del'});//存本地
              window.location.href='/accountlogin.html'
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
      // this.$MessageBox.alert('退出登录成功！',{type:'success'});
    },
    focus(){
      // 搜索框聚焦
      this.hotShow = false;
    },
    blur(){
      // 搜索框失去焦点
      if(!this.searchTxt){
        this.hotShow = true;
      }
    },
    // login(){
    //   // 登录请求获取用户信息-改变用户对象状态
    //   this.$store.dispatch("cmtSetUser",{name:'killer'})
    // },
    registers(){
      // 打开关闭注册
      // this.regtagger = !this.regtagger
      this.$store.dispatch("cmtregtagger",!this.$store.state.regtagger)
    },
    logins(){
      // 打开关闭登录
      // this.logintagger = !this.logintagger
      this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
    },
    // taggerLogOrReg(){
    //   // 登录切换注册
    //   this.regtagger = !this.regtagger
    //   this.logintagger = !this.logintagger
    // }
    linkbuycar(){
      // 跳转购物车
      this._router({exp:false,catch:()=>{
        // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
        this._open({path: "/accountlogin",query: {redirect:'/buycar.html'}})
      },then:()=>{
        // this.$router.push('buycar')
        this._open({path: "/buycar.html"})
      }})
    },
    links(item,index){
      // 路由跳转
      if(item.HrefLink){
        var _obj = {
          SeoTitle:item.SeoTitle,
          SeoKey:item.SeoKey,
          SeoDescrition:item.SeoDescrition
        }
        window.sessionStorage.setItem('localseo',JSON.stringify(_obj))
        window.open(item.HrefLink)
      }else{
        this._router({then:()=>{
          this._open({path:'/showcolumn',query:{linkes:index+'.html'}})
          // this.$router.push({path:'showcolumn',query:{linkes:index}})
        }})
      }
    },
    closeTopimg(){
      // 关闭顶部banner
      // console.log(2)
      this.closeTopimgs = false
      // this.$store.dispatch("cmttopimgshow",false);//改状态
      window.sessionStorage.setItem('topImg',1)
    },
    addUserCarlist(params){
      return;
      // 给用户批量添加数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/BatchAddShoppingCart?productIds='+params._ids+'&nums='+params.nums+'&SellingTypes='+params.types+'&dynamicIds='+params.idcxs,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              if(params.fn){
                params.fn()
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
        }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
    getUserCarlist(params){
      return;
      // 获取用户购物车列表
      this._axios({then:()=>{
      // this.axios.post(process.env.VUE_APP_URL+'Shop/ShoppingCartList?CustomerId='+params.CustomerID,this.keys())
      this.axios.post(process.env.VUE_APP_URL+'Shop/ShoppingCartList',this.keys({token:this._token({method:'get'})}))
        .then(res => {
          if(res.data.Success){
            // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
            //   this.$store.dispatch("cmtSetUser",null);//赋值
            //   this._usermet({method:'del'});//存本地
            //   this._token({method:'del'});//存本地
            //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
            // }
            this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.list.length))
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var a = function() {
          return new Promise((resolve, reject)=> {
              _this._axios({then:()=>{
              // 请求顶部banner
              _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAdvertisingImg?type=0',_this.keys())
                .then(res => {
                    if(res.data.Success){
                      if(res.data.Data.List&&res.data.Data.List.length>0){
                        _this.banners = _this._src + res.data.Data.List[0].AdvertisingPath
                        _this.bannersName =  res.data.Data.List[0].AdvertisingName
                        _this.LinkUrl =  res.data.Data.List[0].LinkUrl
                      }
                      _this.$set(_this,'closeTopimgsShow',res.data.Data.List)
                      resolve()
                    }else{
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});

                      resolve()
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
          })
      }
      var b = function() {
          return new Promise((resolve, reject)=> {
              _this._axios({then:()=>{
              // logo
              _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAdvertisingImg?type=2',_this.keys())
                .then(res => {
                    if(res.data.Success){
                      if(res.data.Data.List&&res.data.Data.List.length>0){
                        _this.logos = _this._src + res.data.Data.List[0].AdvertisingPath
                        _this.logosName = res.data.Data.List[0].AdvertisingName
                        _this.$store.dispatch("cmtLogoUrl",res.data.Data.List[0].LinkUrl);//改状态-logourl
                        _this.$store.dispatch("cmtLogoUrl1",_this._src + res.data.Data.List[0].AdvertisingPath);//改状态-logourl
                      }
                      _this.$set(_this,'logosBox',res.data.Data.List)
                        resolve()
                      // this.logoslinkUrl = res.data.Data.List[0].LinkUrl
                    }else{
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                      resolve()
                    }
                  }, res => {
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                    resolve()
                  })
              }})
          })
      }
      var c = function() {
          return new Promise((resolve, reject)=> {
             // 导航栏目组
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAllBanner',_this.keys())
                  .then(res => {_this
                      if(res.data.Success){
                        _this.$set(_this,'nav1Txt',res.data.Data.List)
                        resolve('next')
                      }else{
                        _this.$MessageBox.alert(res.data.Error,{type:'error'});
                        resolve('next')
                      }
                    }, res => {
                      // this.$MessageBox.alert('系统异常',{type:'error'});
                      resolve('next')
                    })
                }})
          })
      }
      var headPomise = {
        a:a,
        b:b,
        c:c
      }
      this.$emit('headPomise',headPomise)
      // end
      // 读取session是否显示顶部
      // var _topImg = JSON.parse(window.sessionStorage.getItem('topImg'));
      // if(_topImg){
      //   this.closeTopimgs = _topImg.val
      // }else{
      //   this.closeTopimgs = true
      //   window.sessionStorage.setItem('topImg',JSON.stringify({val:this.closeTopimgs}))
      // }
      // end
      // 读取session是否显示顶部
      var _topImg = window.sessionStorage.getItem('topImg');
      if(_topImg){
        this.closeTopimgs = false
      }
      // end
      // 获取用户购物车数量
      if(this.$store.state.user){
        // console.log(this.$store.state.user)
        var _carlist = setCarlist({method:'get'})
        if(_carlist){
          var _ids = '',nums='',types='',idcxs='';
          for(let i in _carlist){
            if(i<(_carlist.length-1)){
              _ids+=_carlist[i].carId+','
              nums+=_carlist[i].buynumber+','
              types+=_carlist[i].type+','
              idcxs+=_carlist[i].idcx+','
            }
            if(i==(_carlist.length-1)){
              _ids+=_carlist[i].carId
              nums+=_carlist[i].buynumber
              types+=_carlist[i].type
              idcxs+=_carlist[i].idcx
            }
          }
          this.addUserCarlist({CustomerID:this.$store.state.user.CustomerID,_ids:_ids,nums:nums,types:types,idcxs:idcxs,fn:()=>{
            setCarlist({method:'del'})
            this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
          }})
        }else{
          this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
        }
      }else{
        var _carlist = setCarlist({method:'get'})
        if(_carlist){
          this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
        }
      }
      // end
    })
  },
  watch:{
    '$store.state.user'(n,o){
      if(this.$route.path.indexOf('/buycar')==-1){
        if(n&&!o){
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            var _ids = '',nums='',types='',idcxs='';
            for(let i in _carlist){
              if(i<(_carlist.length-1)){
                _ids+=_carlist[i].carId+','
                nums+=_carlist[i].buynumber+','
                types+=_carlist[i].type+','
                idcxs+=_carlist[i].idcx+','
              }
              if(i==(_carlist.length-1)){
                _ids+=_carlist[i].carId
                nums+=_carlist[i].buynumber
                types+=_carlist[i].type
                idcxs+=_carlist[i].idcx
              }
            }
            this.addUserCarlist({CustomerID:n.CustomerID,_ids:_ids,nums:nums,types:types,fn:()=>{
              setCarlist({method:'del'})
              this.getUserCarlist({CustomerID:n.CustomerID})
            }})
          }else{
            this.getUserCarlist({CustomerID:n.CustomerID})
          }
        }else{
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
          }
          // else{
          //   this.$store.dispatch('cmtBuycarum',0)
          // }
        }
      }
    },
  },
  destroyed(){
    // 组件销毁清空定时变量
    this.banners = null
  }
}
</script>
<style  lang="scss" scoped>
.content {
  width:100%;
  .top {
    width: 100%;height: 60px;line-height: 60px;overflow: hidden;
    .imgbox {
        width: 1200px;margin:0 auto;text-align: center;position: relative;cursor: pointer;
        span {
        font-size:22px;font-weight:bold;position: relative;z-index: 2;
        &:nth-child(1) {
          margin-right:25px;
        }
        &:nth-child(2) {
          margin-right:115px;
        }
      }
      a {
        width: 100px;height: 30px;line-height: 30px;border-radius: 20px;color:#fff;background: #2c2c2c;font-size: 14px;vertical-align:3px;display: inline-block;position: relative;z-index: 2;
        &:hover {
          opacity: 0.8;
        }
      }
      .imgs {
        left:calc((1200px - 1920px)/2);position: absolute;top:0px;
      }
      @media screen and (min-width: 1918px){
        .closeImg {
          position: absolute;top:15px;right:-320px;z-index: 3;background: none;border:1px solid #333;width: 26px;height:26px;border-radius: 50%;
          svg {
            fill: #333;width: 15px;height: 15px;vertical-align: -2px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
      @media screen and (max-width: 1918px){
        .closeImg {
          position: absolute;top:15px;right:0px;z-index: 3;background: none;border:1px solid #333;width: 26px;height:26px;border-radius: 50%;
          svg {
            fill: #333;width: 15px;height: 15px;vertical-align: -2px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .navsBox {
    background: #dedee0;width: 100%;height: 40px;
    .navs {
      width: 1200px;height: 40px;margin:0 auto;
      .lt {
        height: 40px;line-height: 40px;color:#767676;
        p {
          font-size:12px;
          span {
            font-size: 12px;
          }
          button {
            background: none;color:#6c6c6c;font-size: 14px;
            &:hover {
              opacity: 0.8;
            }
          }
          .name {
            color:#ed008c;margin-right: 5px;cursor: pointer;
          }
          .outlg {
            margin-left:5px;
          }
        }
      }
      .rt {
        height: 40px;line-height:40px;text-align: right;
        a {
          color:#767676;font-size: 12px;display: inline-block;padding:0 10px;
          &:hover {
            opacity: 0.8;
          }
          svg {
            width: 14px;height: 14px;vertical-align: -1px;margin-right: 3px;fill: #767676;
          }
        }
        .moveP {
          &:hover {
            background:#fff;opacity:1;
            .moveBox {
              display: block;opacity:1;
            }
          }
          position: relative;z-index: 10;
          .moveBox {
            width: 480px;height: 100px;top:50px;left:-407px;background:#fff;top:39px;position: absolute;display: none;
            .moveBoxS {
              display: flex;align-items: center;justify-content: space-between;height: 100px;padding:0 10px;
              .div1 {
                display: flex;align-items: center;height: 100px;
                img {
                  width: 80px;height: 80px;display: block;margin:0px;padding:0px;
                }
                .div2 {
                  p {
                    display: flex;justify-content: safe flex-start;color:#333;height: 25px;line-height: 25px;font-size: 14px;
                    &:nth-child(1) {
                      font-size: 17px;font-weight: bold;color:#333;
                    }
                    span {
                      font-size: 20px;font-weight: bold;color:rgb(255, 73, 129);
                    }
                  }
                }
              }
            }
          }
        }
        .buycarlink {
          span {
            color:#fb7474;
          }
        }
      }
    }
  }
  .navs1 {
    width: 1200px;height: 40px;margin:0 auto;height:65px;margin-bottom:10px;
    .lt {
      // svg {
      //   width: 160px;height: 100px;float:left;margin-left:50px;margin-bottom:-10px;
      // }
      .logos {
        display: block;width: 150px;height:50px;float:left;cursor: pointer;
      }
    }
    .cnt {
      height: 45px;line-height: 40px;
      button {
        background: none;margin:0 15px;border-radius: 20px;
        span {
          font-size: 16px;color:#333;
        }
        &:nth-child(1) {
          background: #f88288;color:#fff;padding:3px 12px;
          span {
            color:#fff;
          }
        }
        &:nth-child(2) {
          background: #7dc8fb;color:#fff;padding:3px 12px;
          span {
            color:#fff;
          }
        }
        &:hover {
          // background: #f88288;color:#fff;
          span {
            color:#ca141d;
          }

        }
        &:nth-child(1):hover {
          opacity: 0.8;
          span {
            color:#fff;
          }
        }
        &:nth-child(2):hover {
          opacity: 0.8;
          span {
            color:#fff;
          }
        }
      }
      // .choice {
      //   background: #f88288;color:#fff;
      // }
    }
    .rt {
      height: 45px;line-height:40px;text-align: right;position: relative;
      .ipt {
        width: 316px;height: 28px;background: #ebebeb;border-radius: 10px;border:1px solid #ebebeb;padding-left:20px;
        &:hover {
          border:1px solid #cecece;
        }
      }
      .sh {
        position: absolute;top:0px;right:0px;background: none;width:40px;height: 40px;line-height: 40px;
        svg {
          width: 16px;height: 16px;vertical-align: -1px;
        }
      }
      .hots {
        position: absolute;top:0px;right: 60px;
        button {
          margin-left:20px;background: none;font-size: 12px;color:#6e6e6e;
        }
      }
    }
  }
  .navType {
    height: 65px;width: 100%;background: #fff;border-bottom:1px solid #f7f7f7;
    .box {
      width: 1200px;margin:0 auto;zoom:1;height: 65px;
      .lt {
        float:left;
        img {
          display: inline-block;margin-top:7.5px;margin-left:0px;cursor: pointer;float:left;
        }
        span {
          font-size: 24px;height: 65px;line-height: 65px;display: block;float:left;color:#2c2c2c;padding-left:20px;position: relative;
          &:after {
            content: '';display: block;position: absolute;width: 1px;height: 40px;background: #dadada;top:13px;left:5px;
          }
        }
      }
      .rt {
        float:right;
        .search {
          margin-top:20px;
        }
      }
      &:after {
        content: '';display: block;clear:both;
      }
    }
  }
}
</style>
