<!-- 无限滚动图片展示 -->
<template>
  <div class="cnt ffcnts" v-show='infinShow'>
    <h6 class="h6">
      <span>TA们都选择微微定</span>
    </h6>
    <div class="imgs" name='imgs'>
      <a :href="item.LinkUrl?item.LinkUrl:'javascript:;'" target="_blank" v-for='item,index in infiniteImgData' :title='item.tip?item.tip:""' :key='index'><img :src="item.src"  ></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'infiniteImg',
  props: {
    height: String,
    carousels:Array,
    type:null,
  },
  data(){
    return {
      infiniteImgData:[
      ],
      t:0,//定时函数变量
      infinShow:false,
    }
  },
  methods:{
  },
  mounted(){
    this.$nextTick(() => {
      var _this = this
      var m = function() {
          return new Promise(function(resolve, reject) {
              // 获取合作方
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAllPartners',_this.keys())
                .then(res => {
                    if(res.data.Success){
                      var _data= [];
                      for(let i in res.data.Data.List){
                        var _obj = {};
                        _obj.src = _this._src + res.data.Data.List[i].PartnersPath
                        _obj.tip = res.data.Data.List[i].PartnersName
                        _obj.LinkUrl = res.data.Data.List[i].LinkUrl
                        _data.push(_obj)
                      }
                      _this.$set(_this,'infiniteImgData',_data)
                      setTimeout(()=>{
                        var _width=0;
                        $('div[name=imgs]').find('img').each(function(i,v){
                          if(_this.type==0){
                            if($(v)[0].height==91){
                              $(v)[0].style.display='none'
                               $(v).parent()[0].style.display='none'
                            }
                            if($(v)[0].height==380){
                              _this.infinShow = true
                              _width+=$(v)[0].width
                            }
                          }
                        })
                        $('div[name=imgs]').css('width',_width)
                        $('div[name=imgs]').css('opacity',1);
                         // 自动滚动展示
                         if(!_this.infinShow) return;
                         _this.t = setInterval(()=>{
                          if(Math.abs(parseInt($('div[name=imgs]')[0].offsetLeft))>parseInt($('div[name=imgs] img')[0].offsetWidth)){
                            var a = $('div[name=imgs] a:eq(0)').clone(true)
                            $('div[name=imgs]').append(a)
                            $('div[name=imgs] a:eq(0)').remove()
                            $('div[name=imgs]').css('left',0)
                          }else{
                          }
                          $('div[name=imgs]').css('left',parseInt($('div[name=imgs]')[0].offsetLeft)-1)
                        },15)
                      },1000)
                      // end
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                })
              }})
              // end
          })
      }
      this.$emit('pomise',m)
    })
  },
  destroyed(){
    // 组件销毁清空定时变量
    clearInterval(this.t)
  },
  // beforeUpdate(){
  //   console.log(2)
  // },
  // updated(){
  //   console.log(3)
  // }
}
</script>
<style  lang="scss" scoped>
.cnt {
  height: 589px;background: #e5e5e5;width: calc(100vw - 4px);margin-left:calc((1200px - 100vw - 9px)/2);position: relative;overflow: hidden;
  .h6 {
    span {
      display: block;height: 140px;line-height: 140px;width: 200px;margin:0 auto;text-align: center;font-size: 22px;position: relative;
      &:after,&:before {
        content: '';display: block;position: absolute;width: 190px;height: 1px;background: #0a0a0a;top:70px;
      }
      &:after {
        left:-210px;
      }
      &:before {
        right:-210px;
      }
    }
  }
  .imgs {
    position: absolute;top:140px;left:0px;height: 380px;overflow: hidden;opacity:0;
    a  {
      cursor: pointer;
      img {
        display: inline-block;
      }
    }
  }
}
@-moz-document url-prefix() {
  .ffcnts {
    width: calc(100vw - 9px)!important;
  }
}
@media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
  .cnt {
    width: 1200px;margin-left:calc(( 1200px - 1200px)/2);
  }
  @-moz-document url-prefix() {
    .ffcnts {
      width: 1200px!important;margin-left:calc(( 1200px - 1200px)/2)!important;
    }
  }
}
</style>
