<!-- 分类导航--产品菜单 -->
<template>
  <div class="classmenu-content" name='classmenuCcontent' v-show='loading'>
    <div v-for='item,index in menus' :key='index' class="classParent">
      <h5 class="h">{{item.name}}</h5>
      <!-- <p class="p" v-html='item.tip?item.tip.split(" ").join("&nbsp;&nbsp;&nbsp;"):""'></p> -->
      <div class="linkbox"><span :title='it.name' v-for='it,ix in item.tip' @click='linkbtn(it)'>{{it.name}}</span>
      </div>
      <div v-if='item.class.length>0' class="classRt" name='classmenuclassRt' :class='{nocarousel:!item.carouselImg}'  :style='{top:-index*83+"px"}'>
        <div class="classSon" >
          <!-- :style='{width:items.class.length<7?items.class.length*100+"px":"600px"}' -->
          <div v-for='items,indexs in item.class' :key='indexs' class="classSonTitler"   ref='wwww'>
            <h5 class="h1" name='classSonsH'>{{items.name}}</h5>
            <div v-for='itemss,indexss in items.class'  name='classSons' @click='linkbtns(itemss)' :key='indexss' class="classSons">
              <div class="iconBox">
                <img :src="itemss.src">
              </div>
              <p class="p1" :title='itemss.name'>{{itemss.name}}</p>
            </div>
          </div>
        </div>
        <div class="classCarousel" v-if='item.carouselImg'>
          <carousel  height='500px' :autoplay='true' :carousels='item.carouselImg'></carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import carousel from '@/components/carousel/carousel.vue';//轮播组件
import {Base64code} from '../../assets/js/common.js' //公用js
export default {
  name: 'classmenu',
  props: {
    height: String,
    carousels:Array
  },
  components: {
    carousel,
  },
  data(){
    return {
      loading:false,
      menus:[
        // {
        //   name:'商户印品',
        //   tip:'台历 画册 名片 宣传单',
        //   carouselImg:[{src:require('../../assets/img/classmenu/rtCarousel.jpg')},{src:require('../../assets/img/classmenu/rtCarousel.jpg')},{src:require('../../assets/img/classmenu/rtCarousel.jpg')},],
        //   // carouselImg:null,
        //   class:[
        //     {
        //       name:'热销定制',
        //       class:[
        //         {
        //           name:'台历',
        //           src:require('../../assets/img/classmenu/img.jpg')
        //         },
        //         {
        //           name:'红包',
        //           src:require('../../assets/img/classmenu/img1.jpg')
        //         },
        //         {
        //           name:'挂历',
        //           src:require('../../assets/img/classmenu/img2.jpg')
        //         },
        //         {
        //           name:'对联',
        //           src:require('../../assets/img/classmenu/img3.jpg')
        //         },
        //         {
        //           name:'福字',
        //           src:require('../../assets/img/classmenu/img4.jpg')
        //         },
        //         {
        //           name:'菜单',
        //           src:require('../../assets/img/classmenu/img5.jpg')
        //         },
        //       ]
        //     },
        //     {
        //       name:'办公用品',
        //       class:[
        //         {
        //           name:'名片',
        //           src:require('../../assets/img/classmenu/img6.jpg')
        //         },
        //         {
        //           name:'便签本',
        //           src:require('../../assets/img/classmenu/img7.jpg')
        //         },
        //         {
        //           name:'档案袋',
        //           src:require('../../assets/img/classmenu/img8.jpg')
        //         },
        //         {
        //           name:'付款单',
        //           src:require('../../assets/img/classmenu/img9.jpg')
        //         },
        //         {
        //           name:'信封',
        //           src:require('../../assets/img/classmenu/img10.jpg')
        //         },
        //         {
        //           name:'收据',
        //           src:require('../../assets/img/classmenu/img11.jpg')
        //         },
        //       ]
        //     },
        //     {
        //       name:'企业宣传',
        //       class:[
        //         {
        //           name:'画册',
        //           src:require('../../assets/img/classmenu/img12.jpg')
        //         },
        //         {
        //           name:'宣传单',
        //           src:require('../../assets/img/classmenu/img13.jpg')
        //         },
        //         {
        //           name:'折页',
        //           src:require('../../assets/img/classmenu/img14.jpg')
        //         },
        //         {
        //           name:'宣传扇',
        //           src:require('../../assets/img/classmenu/img15.jpg')
        //         },
        //       ]
        //     },
        //   ]
        // },
        // {
        //   name:'包装定制',
        //   tip:'无纺布袋 手提袋 不干胶贴',
        //   class:[
        //     {
        //       name:'热销定制',
        //       class:[
        //         {
        //           name:'台历',
        //           src:require('../../assets/img/classmenu/img.jpg')
        //         },
        //         {
        //           name:'红包',
        //           src:require('../../assets/img/classmenu/img1.jpg')
        //         },
        //         {
        //           name:'挂历',
        //           src:require('../../assets/img/classmenu/img2.jpg')
        //         },
        //         {
        //           name:'对联',
        //           src:require('../../assets/img/classmenu/img3.jpg')
        //         },
        //         {
        //           name:'福字',
        //           src:require('../../assets/img/classmenu/img4.jpg')
        //         },
        //         {
        //           name:'菜单',
        //           src:require('../../assets/img/classmenu/img5.jpg')
        //         },
        //       ]
        //     },
        //     {
        //       name:'办公用品',
        //       class:[
        //         {
        //           name:'名片',
        //           src:require('../../assets/img/classmenu/img6.jpg')
        //         },
        //         {
        //           name:'便签本',
        //           src:require('../../assets/img/classmenu/img7.jpg')
        //         },
        //         {
        //           name:'档案袋',
        //           src:require('../../assets/img/classmenu/img8.jpg')
        //         },
        //         {
        //           name:'付款单',
        //           src:require('../../assets/img/classmenu/img9.jpg')
        //         },
        //         {
        //           name:'信封',
        //           src:require('../../assets/img/classmenu/img10.jpg')
        //         },
        //         {
        //           name:'收据',
        //           src:require('../../assets/img/classmenu/img11.jpg')
        //         },
        //       ]
        //     },
        //     {
        //       name:'企业宣传',
        //       class:[
        //         {
        //           name:'画册',
        //           src:require('../../assets/img/classmenu/img12.jpg')
        //         },
        //         {
        //           name:'宣传单',
        //           src:require('../../assets/img/classmenu/img13.jpg')
        //         },
        //         {
        //           name:'折页',
        //           src:require('../../assets/img/classmenu/img14.jpg')
        //         },
        //         {
        //           name:'宣传扇',
        //           src:require('../../assets/img/classmenu/img15.jpg')
        //         },
        //       ]
        //     },
        //   ]
        // },
        // {
        //   name:'纸巾定制',
        //   tip:'方盒纸 荷包纸 盒抽纸 散装纸',
        //   class:[]
        // },
        // {
        //   name:'杯类定制',
        //   tip:'奶茶杯 纸杯 配件',
        //   class:[]
        // },
        // {
        //   name:'一次性用品',
        //   tip:'牙签套 纸杯',
        //   class:[]
        // },
        // {
        //   name:'礼品定制',
        //   class:[]
        // },
      ]
    }
  },
  methods:{
    linkbtn(it){
      // 分类导航--产品菜单跳转
      // console.log(it)
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:it.Id,idcx:'',detailType:1}})
        var id = it.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})

    },
    linkbtns(it){
      // 分类产品跳转
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:it.Id,idcx:'',detailType:1}})
        // this._open({path:'predetail',query:{id:it.Id,idcx:'',detailType:1}})
        // let detailType = '1.html'
        // var _Base64code = new Base64code()
        // let _json = _Base64code.encode(JSON.stringify({
        //   id:it.Id,idcx:'',detailType:'1'
        // }))+'.html'
        // let _json = {
        //   id:it.Id,idcx:'',detailType:'1.html'
        // }
        // this._open({name:'predetail',params:{id:it.Id,idcx:'-1',detailType:'1.html'}})
        var id = it.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
        // this.$router.push({name:'predetail',params:{id:it.Id,idcx:'1',detailType:detailType}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var b = function() {
          return new Promise(function(resolve, reject) {
             // 获取分类数据
            _this._axios({then:()=>{
              _this.axios.post(process.env.VUE_APP_URLS+'Home/GetProductMenu',_this.keys())
              .then(res => {
                  if(res.data.Success){
                    _this.loading =true;
                    var _data = [];
                    for(let i in res.data.Data){
                      var _obj = {}
                      _obj.class = []
                      _obj.tip = []
                      _obj.name = res.data.Data[i].Name
                      if(res.data.Data[i].imgList.length>0){
                        _obj.carouselImg = [];
                         for(let o in res.data.Data[i].imgList){
                            _obj.carouselImg.push({src:_this._src + res.data.Data[i].imgList[o].ImageUrl,title:res.data.Data[i].imgList[o].BannerName,linkurl:res.data.Data[i].imgList[o].LinkHref})
                          }
                      }else{
                        _obj.carouselImg = null;
                      }
                      for(let j in res.data.Data[i].ptList){
                        if(res.data.Data[i].ptList[j].ParentID==0){
                          var _classObj = {}
                          // _obj.tip +=res.data.Data[i].ptList[j].TypeName+' '
                          _classObj.name = res.data.Data[i].ptList[j].TypeName
                          _classObj._id = res.data.Data[i].ptList[j].Id
                          _obj.class.push(_classObj)
                        }
                      }
                      for(let u in _obj.class){
                        _obj.class[u].class= []
                        for(let j in res.data.Data[i].ptList){
                          if(_obj.class[u]._id == res.data.Data[i].ptList[j].ParentID){
                            _obj.class[u].class.push({Id:res.data.Data[i].ptList[j].Id,name:res.data.Data[i].ptList[j].TypeName,src:_this._src + res.data.Data[i].ptList[j].ImagePath})
                          }
                        }
                      }
                      _data.push(_obj)
                    }
                    for(let i in _data){
                      var _tip = [];
                      for(let j in _data[i].class){
                        if(_data[i].class[j].class.length>0){
                          var _tip_obj = {};
                          _tip_obj = _data[i].class[j].class[0]
                          _tip.push(_tip_obj)
                        }
                      }
                      _data[i].tip = _tip
                    }
                    _this.$set(_this,'menus',_data)
                    setTimeout(()=>{
                      for(let i in _this.$refs.wwww){
                        var _width = 0;
                        // var _hw = 0;
                        var _length = $(_this.$refs.wwww[i]).find('div[name=classSons]').length
                        $(_this.$refs.wwww[i]).find('div[name=classSons]').each((i,v)=>{
                          _width+=$(v).width()
                        })
                        if(_width>752){
                          $(_this.$refs.wwww[i]).css('width','752px')
                        }else{
                          $(_this.$refs.wwww[i]).css('width',10+_width+_length*5+'px')
                        }
                      }
                      $('div[name=classmenuclassRt]').css({
                        'opacity':'1',
                      })
                    },500)
                    resolve()
                  }else{
                    _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    resolve()
                  }
                }, res => {
                  resolve()
                  // this.$MessageBox.alert('系统异常',{type:'error'});
                })
            }})
            // end
          })
      }
      this.$emit('pomise',b)
    })
  }
}
</script>
<style  lang="scss" scoped>
.classmenu-content {
  width: 200px;height: 500px;background: #fff;border-radius: 10px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .classParent {
    height: 83px;width: 200px;position: relative;
    .h {
      color:#3b3b3b;font-size: 16px;font-weight: normal;padding-top:20px;width: 168px;margin:0 auto;
    }
    &:before {
      content: '';display: block;position: absolute;width: 5px;height: 35px;background: #fd858a;bottom:24px;left:3px;border-radius: 50px;display: none;
    }
    // &:after {
    //   content: '';display: block;position: absolute;width: 168px;height: 1px;background: #e7e7e7;bottom:0px;left:16px;
    // }
    &:nth-child(1) {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
    }
    &:last-child:after {
      display: none;
    }
    .p {
      color:#939393;font-size: 12px;padding-top:5px;width: 168px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;margin:0 auto;
    }
    .linkbox {
      // text-overflow: ellipsis;
      margin-top:8px;white-space: nowrap;overflow: hidden;width: 170px;margin-left:15px;
      button {
        background: none;color:#939393;margin-right: 5px;
        &:hover {
          opacity: 0.8;
        }
      }
      span {
        background: none;color:#939393;margin-right: 5px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .classRt {
      height: 500px;background: #fff;position: absolute;top:0px;left:200px;zoom:1;display: block;padding-right: 10px;visibility: hidden;opacity: 0;
      .classSon {
        float:left;
        .classSonTitler {
          // min-width: 760px;max-width: 760px;
          display: block;zoom: 1;margin-bottom:10px;padding-left: 10px;
          .h1 {
            padding-left:10px;height: 34px;line-height: 34px;font-size: 14px;color:#535353;display: block;white-space:nowrap;
            // max-width: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;
          }
          &:after {
            display: block;content: '';clear:both;visibility: hidden;height: 0px;
          }
          .classSons {
             float:left;
            cursor: pointer;margin-right: 5px;
            .iconBox {
              width:100px;height: 60px;display: flex;justify-content: center;align-items: center;margin:0 auto;
              img {
                max-width: 80%;max-height: 80%;
              }
            }
            .p1 {
              // white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;width: 70px;
              text-align: center;color:#7a7a7a;margin:0 auto;
            }
            &:hover {
              .p1 {
                color:#d18991;
              }
            }
            &:nth-child(2) {
              // margin-left:10px;
            }
          }
          &:nth-child(1) {
            .h1 {
              padding-top:10px;
            }
          }
        }
      }
      .classCarousel {
        width: 228px;position: absolute;top:0px;right:-228px;border-top-right-radius: 10px;border-bottom-right-radius: 10px;overflow: hidden;display: none;
      }
      &:after {
        width: 20px;height: 500px;content: '';display: block;background: #fff;top:0px;left:-20px;position: absolute;z-index: -1;
      }
      &:before {
        content: '';display: block;position:absolute;width: 3px;height: 400px;left:0px;bottom:0px;background-image: linear-gradient(rgba(251,195,202,0.05), rgba(251,195,202,1));z-index: 99;
      }
    }
    .nocarousel {
      border-top-right-radius: 10px;border-bottom-right-radius: 10px;
    }
    &:nth-child(n+2) {
      .h {
        // &:after {
        //   content: '';display: block;position: absolute;width: 168px;height: 1px;background: #e7e7e7;bottom:0px;left:16px;
        // }
        &:before {
          content: '';display: block;position: absolute;width: 168px;height: 1px;background: #e7e7e7;top:0px;left:16px;
        }
      }
    }
    &:last-child {
      height: 85px;
    }
    &:hover {
      cursor: pointer;
      background-image: linear-gradient(to right, rgba(251,232,233,1) , rgba(255,255,255,0.01));
      .h {
        color:#f88288;
        &:before {
          display: none;
        }
      }
      .p {
        color:#dfb3b0;
      }
      .classRt {
        visibility: visible;
        .classCarousel {
          display: block;
        }
      }
      &:before {
        display: block;
      }
    }
    &:hover + div {
      .h {
        &:before {
          display: none;
        }
      }
    }
  }
}
</style>
