<!-- 猜你喜欢 -->
<template>
  <div class="cnt" v-if='likesData&&likesData.length>0'>
    <h6 class="h6">猜你喜欢<span>VVDING</span></h6>
    <el-row :gutter='12'>
      <el-col :span="6" v-for='item,index in likesData' :key='index'>
        <div class="box" @click='link(item)'>
          <div class="imgs">
            <img :src="item.src">
          </div>
          <h6 :title='item.title'>{{item.title}}</h6>
          <p class="price">&yen;{{item.price}}</p>
          <p class="people">{{item.people}}人购买</p>
        </div>
      </el-col>
    </el-row>
  <!--   <el-row :gutter='12'>
      <el-col :span="6" v-for='item,index in likesData.slice(5,likesData.length)' :key='index'>
        <div class="box">
          <div class="imgs">
            <img :src="item.src">
          </div>
          <h6>{{item.title}}</h6>
          <p class="price">&yen;{{item.price}}</p>
          <p class="people">{{item.people}}人购买</p>
        </div>
      </el-col>
    </el-row> -->
  </div>
</template>
<script>
export default {
  name: 'likes',
  props: {
    height: String,
    carousels:Array
  },
  data(){
    return {
      likesData:[
        // {src:require('../../assets/img/likes/img.jpg'),title:'荷包纸巾纸巾定制纸巾定制纸巾定制',price:380,people:937},
        // {src:require('../../assets/img/likes/img1.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img2.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img3.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img4.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img5.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img6.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img7.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img8.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img9.jpg'),title:'荷包纸巾',price:380,people:937},
      ]
    }
  },
  methods:{
    link(i){
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      // 请求猜你喜欢数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetProductUserLikeList?CustomerID='+CustomerID,this.keys())
        .then(res => {
            if(res.data.Success){
              var _data = [];
              for(let i in res.data.Data){
                var _obj = {};
                _obj.src = this._src + res.data.Data[i].ImagePath
                _obj.title = res.data.Data[i].AttachTypeName
                _obj.price = res.data.Data[i].Price
                _obj.people = res.data.Data[i].SalesVolume
                _obj.Id = res.data.Data[i].Id
                _data.push(_obj)
              }
              this.$set(this,'likesData',_data)
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.cnt {
  .h6 {
    text-align: center;height: 100px;line-height: 100px;font-size: 22px;color:#343434;position: relative;
    span {
      color:#dddddd;font-size: 32px;position: absolute;width: 150px;height: 50px;line-height: 50px;text-align: center;left: 525px;top:12px;z-index: -1;
    }
    &:after,&:before {
      content: '';display: block;position: absolute;width: 1px;height: 40px;transform: rotate(9deg);background: #808080;top:25px;
    }
    &:after {
      left:505px;
    }
    &:before {
      right:505px;
    }
  }
  .el-row {
    // margin-bottom:12px;
    .el-col-6 {
      width: 20%;margin-bottom:12px;
      .box {
        background: #fff;height: 280px;border-radius: 10px;
        .imgs {
          height: 200px;display: flex;justify-content: center;align-items: center;
          img {
            display: block;max-width: 150px;max-height: 150px;
          }
        }
        h6 {
          text-align: center;padding:0 20px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size: 15px;
        }
        .price {
          text-align: center;font-size: 16px;color:#d41e44;margin-top:5px;
        }
        .people {
          text-align:center;font-size: 12px;color:#828282;
        }
        &:hover {
          // opacity: 0.8;
          cursor: pointer;
          box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
          h6 {
            color:#ca141d;
          }
        }
      }
    }
    &:last-child {
      margin-bottom:0px;
    }
  }
}
</style>
