<!-- 弹窗 注册 -->
<template>
  <div class="register">
    <div  class="content">
      <button class="close" @click='closeReg'><svg class="icon"><use xlink:href="#iconshanchu"></use></svg></button>
      <h6 class="h6">
        注册
        <p>已有账号？<button @click='taggerRegister'>立即登录</button></p>
      </h6>
      <!-- :class='{err:phonereg}' -->
      <div class="ipt" >
        <!-- <input type="password" style="position: fixed;z-index:-9999;"> -->
        <svg class="icon"><use xlink:href="#iconzhanghao"></use></svg>
        <input type="text" v-model='phone' autocomplete="new-password" @focus='iptfocus("phonetxt")' :placeholder="phonetxt">
        <span class="errorData">{{errorData.phonetxt}}</span>
      </div>
      <!-- :class='{err:codesreg}' -->
      <div class="ipt yzm" >
        <!-- <input type="password" style="position: fixed;z-index:-9999;"> -->
        <input type="text" v-model='codes'  autocomplete="new-password" @focus='iptfocus("codestxt")' :placeholder="codestxt">
        <span class="errorData">{{errorData.codestxt}}</span>
        <getcodebtn :initPhone='phone' @exportErr='phoneError' @exportpass='phonepass' class='btn'></getcodebtn>
      </div>
      <!-- :class='{err:passwordreg}' -->
      <div class="ipt" >
        <!-- <input type="password" style="position: fixed;z-index:-9999;"> -->
        <svg class="icon"><use xlink:href="#iconmima"></use></svg>
        <!-- <input type="password"  v-model='password_' @input='pasIpt' autocomplete="new-password" :placeholder="passwordtxt"> -->
        <input type="password"  v-model='password'  autocomplete="new-password" @focus='iptfocus("passwordtxt")' :placeholder="passwordtxt">
        <span class="errorData">{{errorData.passwordtxt}}</span>
      </div>
      <!-- :class='{err:passwordreg1}' -->
      <div class="ipt" >
        <!-- <input type="password" style="position: fixed;z-index:-9999;"> -->
        <svg class="icon"><use xlink:href="#iconmima"></use></svg>
        <!-- <input type="password" v-model='password1_' @input='pasIpt1' autocomplete="new-password" @keyup.enter='regbtn'  :placeholder="passwordtxt1"> -->
        <input type="password" v-model='password1'  autocomplete="new-password" @focus='iptfocus("passwordtxt1")' @keyup.enter='regbtn'  :placeholder="passwordtxt1">
        <span class="errorData">{{errorData.passwordtxt1}}</span>
      </div>
      <div class="tip">
        注册代表同意<a href="javascript:;" @click='linkUrl'>《服务协议》</a></a>
      </div>
      <button class="reg" :disabled='regck'  @click='regbtn'>{{regtxt}}</button>
    </div>
  </div>
</template>
<script>
import {regPhone,setOrGetCode,usermethod} from '../../assets/js/common.js' //公用js
import getcodebtn from '@/components/getcodebtn/getcodebtn.vue';//获取验证码按钮组件
import md5 from 'js-md5';
export default {
  name: 'register',
  data(){
    return {
      phone:'',
      codes:'',
      password:'',
      password_:'',
      password1:'',
      password1_:'',
      phonetxt:'请输入手机号码',
      phonereg:false,
      codestxt:'验证码',
      codesreg:false,
      passwordtxt:'设置密码',
      passwordreg:false,
      passwordtxt1:'确认密码',
      passwordreg1:false,
      regtxt:'立即注册',
      regck:false,//请求按钮不能多次点击
      errorData:{
        phonetxt:'',
        codestxt:'',
        passwordtxt:'',
        passwordtxt1:''
      }
    }
  },
  components: {
    getcodebtn
  },
  props: {
    msg: String
  },
  methods:{
    linkUrl(){
      // this.$store.dispatch("cmtregtagger",false)
      // this.$store.dispatch("cmtlogintagger",false)
      // this._router({then:()=>{
      //   this.$router.push({path: "/aboutusservice"})
      // }})
      this._open({path:'/aboutusservice.html'})
    },
    iptfocus(v){
      for(let i in this.errorData){
        if(i==v){
          this.errorData[i] = ''
        }
      }
    },
    phoneError(v){
      // 手机号错误
      this.errorData.phonetxt = v
      this.phonereg = true
      // this.phone=''
    },
    phonepass(){
      // 手机号通过
      // this.phonetxt = '请输入手机号码'
      this.phonereg = false
    },
    regbtn(){
      var _this = this;
      // this.phonetxt='请输入手机号码';
      this.phonereg=false;
      // this.codestxt='验证码';
      this.codesreg=false;
      // this.passwordtxt='设置密码';
      this.passwordreg=false;
      // this.passwordtxt1='确认密码';
      this.passwordreg1=false;
      // 立即注册
      if(this.phone==''){
        this.errorData.phonetxt = '请填写手机号码'
        this.phonereg = true
      }else if(!regPhone(this.phone)){
        this.errorData.phonetxt = '请填写正确的手机号码'
        this.phonereg = true
        // this.phone=''
      }
      if(setOrGetCode({method:'get'})&&md5(this.codes).toLocaleUpperCase()==setOrGetCode({method:'get'})){
        this.codesreg = false
      }else{
        this.errorData.codestxt = '验证码错误'
        this.codesreg = true
        // this.codes = ''
      }
      if(this.password.length<6||this.password.length>18){
        this.errorData.passwordtxt = '密码长度为6-18位之间'
        this.passwordreg = true
        // this.password = ''
        // this.password_ = ''
      }else if(this.password1!=this.password){
        this.errorData.passwordtxt1='与上一次密码输入不一致';
        this.passwordreg1=true;
        // this.password1 = ''
        // this.password1_ = ''
      }
      if(!this.phonereg&&!this.codesreg&&!this.passwordreg&&!this.passwordreg1){
        // 发送注册请求
        this.regtxt = '注册中...'
        this.regck = true
        if(this._spf(this.$route.query.id)){
          this.axios.post(process.env.VUE_APP_URL+'Login/RegisterBindOAuth?Phone='+this.phone+'&PassWord='+this.password+'&Openid='+this._spf(this.$route.query.id)+'&type='+this._spf(this.$route.query.type),this.keys())
          .then(res => {
            if(res.data.Success){
              _this.regtxt = '立即注册'
              _this.regck = false
              _this.reset()
              _this.$store.dispatch("cmtSetUser",res.data.Data.CustomersDto);//改状态
              usermethod({method:'set',user:res.data.Data.CustomersDto});//存数据
              _this._token({method:'set',token:res.data.Data.token})
              // 关闭弹窗---如果重定向登录跳转重定向
              if(_this.$route.query.redirect){
                _this.$router.push(_this.$route.query.redirect)
              }else{
                if(_this.$route.path.indexOf('/accountregister')!=-1){
                  // this._open({path:'/'})
                  // window.location.href='/'
                  _this.$store.dispatch("cmtregtagger",false)
                  _this.$store.dispatch("cmtlogintagger",false)
                  this.$router.push('/index.html')
                }else{
                  _this.$store.dispatch("cmtregtagger",false)
                  _this.$store.dispatch("cmtlogintagger",false)
                }
              }

              // // 弹窗注册成功切换登录弹窗
              // // 如果是跳转页面注册--跳转登录页面---再跳转首页
              // if(_this.$route.query.redirect){
              //   _this.$router.push(_this.$route.query.redirect)
              // }else{
              //   _this.$store.dispatch("cmtregtagger",!this.$store.state.regtagger)
              //   _this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
              // }
            }else{
              // this.$MessageBox.alert(res.data.Error,{type:'error'})
              this.errorData.phonetxt = res.data.Error
              _this.regtxt = '立即注册'
              _this.regck = false
              // _this.reset()
            }
          }, res => {
            // _this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
        }else{
          this.axios.post(process.env.VUE_APP_URL+'Login/RegisterCustomer?phone='+this.phone+'&pwd='+this.password,this.keys())
          .then(res => {
            if(res.data.Success){
              _this.regtxt = '立即注册'
              _this.regck = false
              _this.reset()
              _this.$store.dispatch("cmtSetUser",res.data.Data.CustomersDto);//改状态
              usermethod({method:'set',user:res.data.Data.CustomersDto});//存数据
              _this._token({method:'set',token:res.data.Data.token})
              // 关闭弹窗---如果重定向登录跳转重定向
              if(_this.$route.query.redirect){
                _this.$router.push(_this.$route.query.redirect)
              }else{
                if(_this.$route.path.indexOf('/accountregister')!=-1){
                  // this._open({path:'/'})
                  // window.location.href='/'
                  _this.$store.dispatch("cmtregtagger",false)
                  _this.$store.dispatch("cmtlogintagger",false)
                  this.$router.push('/index.html')
                }else{
                  _this.$store.dispatch("cmtregtagger",false)
                  _this.$store.dispatch("cmtlogintagger",false)
                }
              }

              // // 弹窗注册成功切换登录弹窗
              // // 如果是跳转页面注册--跳转登录页面---再跳转首页
              // if(_this.$route.query.redirect){
              //   _this.$router.push(_this.$route.query.redirect)
              // }else{
              //   _this.$store.dispatch("cmtregtagger",!this.$store.state.regtagger)
              //   _this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
              // }
            }else{
              // this.$MessageBox.alert(res.data.Error,{type:'error'})
              this.errorData.phonetxt = res.data.Error
              _this.regtxt = '立即注册'
              _this.regck = false
              // _this.reset()
            }
          }, res => {
            // _this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
        }
      }
    },
    closeReg(){
      // 关闭注册
      this.reset()
      this.$store.dispatch("cmtregtagger",!this.$store.state.regtagger)
    },
    taggerRegister(){
      // 切换登录弹窗
      this.reset()
      if(this.$route.path.indexOf('/accountregister')!=-1){
        // 跳转购物车
        this._router({exp:false,catch:()=>{
          // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
        },then:()=>{
          this.$router.push('/accountlogin.html')
        }})
      }else{
        this.$store.dispatch("cmtregtagger",!this.$store.state.regtagger)
        this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
      }
    },
    reset(){
      // 重置输入框
      this.phone='';
      this.codes='';
      this.password='';
      this.password_='';
      this.password1='';
      this.password1_='';
      this.phonetxt='请输入手机号码';
      this.phonereg=false;
      this.codestxt='验证码';
      this.codesreg=false;
      this.passwordtxt='设置密码';
      this.passwordreg=false;
      this.passwordtxt1='确认密码';
      this.passwordreg1=false;
      this.regtxt='立即注册';
    },
    pasIpt(){
      // 处理input为密码的时候会保存账号密码
      // 监听密码输入
      // console.log(this.password_.length,this.password.length)
      if(this.password_.length>this.password.length){
        //获取最后一个字符加到到str,因为除了最后一个字符，其他的已经为*
        this.password+=this.password_.substr(this.password_.length-1,1);
        this.password_ = this.password_.replace(/./g,'*') //输入框内容全部变为*
      }else{
        this.password = this.password.substring(0,this.password.length - 1)

      }
      // console.log(this.password); //str即为输入框的内容
    },
    pasIpt1(){
      // 处理input为密码的时候会保存账号密码
      // 监听密码输入
      // console.log(this.password_.length,this.password.length)
      if(this.password1_.length>this.password1.length){
        //获取最后一个字符加到到str,因为除了最后一个字符，其他的已经为*
        this.password1+=this.password1_.substr(this.password1_.length-1,1);
        this.password1_ = this.password1_.replace(/./g,'*') //输入框内容全部变为*
      }else{
        this.password1 = this.password1.substring(0,this.password1.length - 1)

      }
      // console.log(this.password1); //str即为输入框的内容
    }
  },
  watch:{
    '$store.state.regtagger'(n,o){
      // 记录账号--自动填入账号
      this.reset()
    }
  }
}
</script>
<style  lang="scss" scoped>
.register  {
  width: 100vw;height: 100vh;display: block;position: fixed;top:0px;left:0px;background: rgba(0,0,0,0.6);z-index: 999;
  .content {
    width: 460px;height: 515px;background: #fff;border-radius: 5px;position: absolute;top:calc((100vh - 515px)/2);left:calc((100vw - 460px)/2);
    .close {
      width: 35px;height: 35px;background: none;position: absolute;top:0px;right:-47px;background: #fff;border-radius: 50%;
      svg {
        width: 20px;height:20px;fill: #6f6d6e;vertical-align: -2px;
      }
    }
    .h6 {
      font-size:20px;height: 75px;line-height: 75px;padding:0 45px;font-weight: normal;
      p {
        float:right;color:#d1d1d1;font-weight: normal;font-size: 14px;
        button  {
          color:#f44e65;background: none;
        }
      }
    }
    .ipt {
      width: 370px;height: 46px;margin:0 auto;position: relative;margin-bottom:26px;
      svg {
        position: absolute;top:14px;left:10px;width: 18px;height: 18px;fill: #d9d9d9;
      }
      input {
        width: 333px;height: 44px;border:1px solid #e9e9e9;border-radius: 5px;padding-left:35px;
        &::placeholder {
          color:#b1b1b1;
        }
      }
      .errorData {
        font-size: 12px;color:#fa686e;
      }
    }
    .err {
      svg {
        fill: #fa686e;
      }
      input {
        border:1px solid #fa686e;
        &::placeholder {
          color:#fa686e;
        }
      }
    }
    .yzm {
      input {
        width: 186px;padding-left:14px;
      }
      .btn {
        float:right;
      }
      .errorData {
        position: absolute;top:50px;left:0px;
      }
    }
    .tip {
      text-align: center;font-size: 14px;color:#b1b1b1;
      a {
        color:#f55f73;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .reg {
      width: 370px;height: 46px;margin:0 auto;display: block;border-radius: 5px;margin-top:25px;background: #f54e59;color:#fff;font-size: 16px;line-height: 46px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
