<!-- 全局footer -->
<template>
  <div class="cnt" :class='{shows:show}'>
    <div class="footer">
      <el-row>
        <el-col :span="4">
          <h6>购物指南</h6>
          <a href="javascript:;" @click='links(0)'>新用户注册</a>
          <a href="javascript:;" @click='links(1)'>找回密码</a>
          <a href="javascript:;" @click='links(2)'>购物流程</a>
          <!-- <a href="javascript:;" @click='links(3)'>合伙人介绍</a> -->
        </el-col>
        <el-col :span="4">
          <h6>安全支付</h6>
          <a href="javascript:;" @click='links(4)'>在线支付</a>
          <a href="javascript:;" @click='links(5)'>银行汇款</a>
          <a href="javascript:;" @click='links(6)'>发票说明</a>
        </el-col>
        <el-col :span="4">
          <h6>关于配送</h6>
          <a href="javascript:;" @click='links(7)'>配送方式</a>
          <a href="javascript:;" @click='links(8)'>配送范围</a>
          <a href="javascript:;" @click='links(9)'>签收与验货</a>
        </el-col>
        <el-col :span="4">
          <h6>关于定制</h6>
          <a href="javascript:;" @click='links(10)'>定制流程</a>
          <a href="javascript:;" @click='links(11)'>在线设计</a>
          <a href="javascript:;" @click='links(12)'>定制须知</a>
          <a href="javascript:;" @click='links(13)'>注意事项</a>
        </el-col>
        <el-col :span="4">
          <h6>售后服务</h6>
          <a href="javascript:;" @click='links(14)'>售后政策</a>
          <a href="javascript:;" @click='links(15)'>退款说明</a>
          <a href="javascript:;" @click='links(16)'>返工/退换货</a>
          <a href="javascript:;" @click='links(17)'>取消订单</a>
          <a href="javascript:;" @click='links(18)'>人工服务</a>
        </el-col>
        <el-col :span="4">
          <h6>帮助中心</h6>
          <a href="javascript:;" @click='links(19)'>常见问题</a>
          <a href="javascript:;" @click='links(20)'>查询物流</a>
          <!-- <a href="javascript:;" @click='links(21)'>意见建议</a> -->
        </el-col>
        <el-col :span="4">
          <h5>{{phone?phone:'400-6070-818'}}</h5>
          <p>周一至周日 8:30-18:00</p>
          <p>(仅收市话费)</p>
          <button class="lianxikefu" @click='lianxikefu'>在线交谈</button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Vfooter',
  props: {
    height: String,
    carousels:Array
  },
  data(){
    return {
      show:false,
      phone:null
    }
  },
  methods:{
    links(v){
      // 跳转路由
      switch(v) {
        case 0:
          this._router({then:()=>{
            this.$router.push({path: "/accountregister.html"})
          }})
          break;
        case 1:
          this._router({then:()=>{
            this.$router.push({path: "/setsteps?types=3.html"})
          }})
          break;
        case 2:
          this._router({then:()=>{
            this.$router.push({path: "/guideshop.html"})
          }})
          break;
        case 3:
          this._router({then:()=>{
            this.$router.push({path: "/setsteps?types=3.html"})
          }})
          break;
        case 4:
          this._router({then:()=>{
            this.$router.push({path: "/guideonlinepay.html"})
          }})
          break;
        case 5:
          this._router({then:()=>{
            this.$router.push({path: "/guidebank.html"})
          }})
          break;
        case 6:
          this._router({then:()=>{
            this.$router.push({path: "/guidenote.html"})
          }})
          break;
        case 7:
          this._router({then:()=>{
            this.$router.push({path: "/guidesend.html"})
          }})
          break;
        case 8:
          this._router({then:()=>{
            this.$router.push({path: "/guiderange.html"})
          }})
          break;
        case 9:
          this._router({then:()=>{
            this.$router.push({path: "/guidetake.html"})
          }})
          break;
        case 10:
          this._router({then:()=>{
            this.$router.push({path: "/guidemade.html"})
          }})
          break;
        case 11:
          this._router({then:()=>{
            this.$router.push({path: "/guidedesign.html"})
          }})
          break;
        case 12:
          this._router({then:()=>{
            this.$router.push({path: "/guidemadeknow.html"})
          }})
          break;
        case 13:
          this._router({then:()=>{
            this.$router.push({path: "/guidekeepitem.html"})
          }})
          break;
        case 14:
          this._router({then:()=>{
            this.$router.push({path: "/guidesale.html"})
          }})
          break;
        case 15:
          this._router({then:()=>{
            this.$router.push({path: "/guiderefund.html"})
          }})
          break;
        case 16:
          this._router({then:()=>{
            this.$router.push({path: "/guiderework.html"})
          }})
          break;
        case 17:
          this._router({then:()=>{
            this.$router.push({path: "/guidecancel.html"})
          }})
          break;
        case 18:
          this._router({then:()=>{
            this.$router.push({path: "/guidelabour.html"})
          }})
          break;
        case 19:
          this._router({then:()=>{
            this.$router.push({path: "/guidequestion.html"})
          }})
          break;
        case 20:
          var _obj = {
            orderTypes:2,
            orderNumbers:'',
            pageIndexs:1
          }
          window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
          this._router({then:()=>{
            this.$router.push('/usercenterorder.html')
          },exp:true,catch:()=>{
            this.$router.push({path:'accountlogin',query:{redirect:'/usercenterorder.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
          }})
          break;
        case 21:
          this._router({then:()=>{
            this.$router.push({path: "/guidepropose.html"})
          }})
          break;
        default:
          break;
      }
    },
    lianxikefu(){
      // 联系客服
      _MEIQIA('showPanel');
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var foota = function() {
          return new Promise(function(resolve, reject) {
              _this.show = true
              resolve()
          })
      }
      this.$emit('footPomise',foota)
      this.axios.post(process.env.VUE_APP_URLS+'Home/GetGlobalSEO',_this.keys())
      .then(res => {
        this.phone = res.data.Data.PhoneNumber
      }, res => {
        // this.$MessageBox.alert('系统异常',{type:'error'});
      })
    })
  }
}
</script>
<style  lang="scss" scoped>
.cnt {
  width:100%;height: 210px;background: #f5f5f5;opacity:0;
  .footer {
    width: 1200px;margin:0 auto;height: 210px;
    .el-row {
      .el-col-4 {
        width: 13.5%;height: 210px;position: relative;
        &:after {
          content: '';display: block;position: absolute;height: 115px;width: 1px;background: #f5f5f5;top:35px;right:0px;
        }
        &:last-child:after {
          display: none;
        }
        &:last-child {
          width: 19%;
        }
        &:nth-child(6):after {
          background: #665b6e;
        }
        .lianxikefu {
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .el-col {
        h6 {
          color:#333333;font-size: 16px;text-align: center;height: 60px;line-height: 60px;
        }
        a {
          display: block;color:#665b6e;padding-left:49px;font-size: 14px;margin-bottom:8px;
          &:hover {
            // color:#ccc;
            opacity: 0.8;
          }
        }
        h5 {
          font-size:26px;color:#333333;text-align: center;height: 50px;line-height: 50px;font-weight: normal;padding-top:15px;
        }
        p {
          color:#665b6e;text-align: center;font-size: 14px;
        }
        button {
          width: 180px;height: 38px;background: #333333;color:#fff;border-radius: 20px;display: block;margin:0 auto;margin-top:15px;line-height: 38px;
        }
      }
    }
  }
}
.shows {
  opacity: 1;
}
</style>
