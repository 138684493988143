<!-- 特卖 -->
<template>
  <div class="supersell">
  	<div class="product">
  		<superselltop :imgs='topbg'></superselltop>
      <supertitle class='tit' :tits='tits' v-if='arr&&arr.length>0'></supertitle>
      <div class='limitkillerlist'><limitkillerlist type='0' v-if='arr&&arr.length>0' :arrlists='arr' :DaySaleDto='DaySaleDto' :start='start'></limitkillerlist></div>
      <supertitle class='tit' :tits='tits1' v-if='arrdata&&arrdata.length>0'></supertitle>
      <limitsell class='limitsell' :arrdata='arrdata' v-if='arrdata&&arrdata.length>0'></limitsell>
  	</div>
  	<div class="layout">
  		<likes class='likes'></likes>
  		<infiniteImg type='0' class='infiniteImg'></infiniteImg>
  	</div>
  </div>
</template>
<script>
import likes from '@/components/likes/likes.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import superselltop from '@/components/superSellTop/superSellTop.vue';//特卖头部
import supertitle from '@/components/supertitle/supertitle.vue';//特卖标题
import limitkillerlist from '@/components/limitkillerlist/limitkillerlist.vue';//秒杀产品列表
import limitsell from '@/components/limitsell/limitsell.vue';//限时特卖
export default {
  name: 'superSell',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'每日特卖-微微定',
      arr:[
        // {url:require('../assets/img/limitkillerList/1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:200,out:1},
        // {url:require('../assets/img/limitkillerList/2.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:100,out:100},
        // {url:require('../assets/img/limitkillerList/3.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:20,out:20},
        // {url:require('../assets/img/limitkillerList/4.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:80,out:30},
        // {url:require('../assets/img/limitkillerList/1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:90,out:40},
        // {url:require('../assets/img/limitkillerList/2.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:300,out:50},
        // {url:require('../assets/img/limitkillerList/3.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:400,out:60},
        // {url:require('../assets/img/limitkillerList/4.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:500,out:70},
        // {url:require('../assets/img/limitkillerList/1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:90,out:40},
        // {url:require('../assets/img/limitkillerList/2.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:300,out:50},
        // {url:require('../assets/img/limitkillerList/3.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:400,out:60},
        // {url:require('../assets/img/limitkillerList/4.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:500,out:70},
      ],
      arrdata:[
        // {url:require('../assets/img/limitsell/img.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾21321321321321321321定制',pirce:380},
        // {url:require('../assets/img/limitsell/img1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制1',pirce:380},
        // {url:require('../assets/img/limitsell/img.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制2',pirce:380},
        // {url:require('../assets/img/limitsell/img1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制3',pirce:380},
        // {url:require('../assets/img/limitsell/img.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制4',pirce:380},
        // {url:require('../assets/img/limitsell/img1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制5',pirce:380}
      ],
      start:true,
      tits:null,//标题1
      tits1:null,//标题2
      topbg:null,//头部bg
      DaySaleDto:null,//特卖id
    }
  },
  components: {
  	likes,
  	infiniteImg,
  	superselltop,
    supertitle,
    limitkillerlist,
    limitsell
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      let d = function() {
          return new Promise(function(resolve, reject) {
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URL+'DaySale/GetDaySaleData?CustomerID='+CustomerID,_this.keys())
                .then(res => {
                    if(res.data.Success){
                      _this.$set(_this,'tits',res.data.Data.DaySaleDto.OneBannerName)
                      _this.$set(_this,'tits1',res.data.Data.DaySaleDto.TwoBannerName)
                      _this.$set(_this,'topbg',_this._src+res.data.Data.DaySaleDto.BackGroundIMG)
                      var _data = [],_data1=[];
                      for(let i  in  res.data.Data.DzproducttypeVM){
                        if(res.data.Data.DzproducttypeVM[i].BannerType==1){
                          var _obj = {};
                          _obj.url = _this._src + res.data.Data.DzproducttypeVM[i].ImagePath
                          _obj.title = res.data.Data.DzproducttypeVM[i].AttachTypeName
                          _obj.pirce = res.data.Data.DzproducttypeVM[i].Price
                          _obj.oldPirce = res.data.Data.DzproducttypeVM[i].OriginalPrice
                          _obj.all = res.data.Data.DzproducttypeVM[i].Stock
                          _obj.out = res.data.Data.DzproducttypeVM[i].SalesVolume
                          _obj.Id = res.data.Data.DzproducttypeVM[i].Id
                          _data.push(_obj)
                        }
                        if(res.data.Data.DzproducttypeVM[i].BannerType==2){
                          var _obj = {};
                          _obj.url = _this._src + res.data.Data.DzproducttypeVM[i].ImagePath
                          _obj.title = res.data.Data.DzproducttypeVM[i].AttachTypeName
                          _obj.pirce = res.data.Data.DzproducttypeVM[i].Price
                          _obj.Id = res.data.Data.DzproducttypeVM[i].Id
                          _data1.push(_obj)
                        }
                      }
                      // console.log(res.data.Data)
                      _this.$set(_this,'DaySaleDto',res.data.Data.DaySaleDto)
                      _this.$set(_this,'arr',_data)
                      _this.$set(_this,'arrdata',_data1)
                      if(res.data.Data.DaySaleDto.SeoTitle){
                        document.title = res.data.Data.DaySaleDto.SeoTitle
                      }
                      if(res.data.Data.DaySaleDto.SeoDescrition){
                        $('meta[name=description]').attr('content',res.data.Data.DaySaleDto.SeoDescrition)
                      }
                      if(res.data.Data.DaySaleDto.SeoKey){
                         $('meta[name=Keywords]').attr('content',res.data.Data.DaySaleDto.SeoKey)
                      }
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
          })
      }
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c,d])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.supersell {
	.product {
		width: 100%;height: auto;
    background: url('../assets/img/supersell/bg.png') center 0px repeat-y;
    overflow: hidden;
    .tit {
      margin-top:60px;
    }
    .limitkillerlist {
      width: 1200px;margin:0 auto;margin-top:30px;
    }
    .limitsell {
      width: 1200px;margin:0 auto;margin-top:30px;
    }
	}
	@media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
	    .product {
	      width:1200px;
	    }
	}
	.layout {
		width: 1200px;margin:0 auto;position: relative;
		.likes {
		    width: 1200px;overflow: hidden; margin-top:40px;
		}
		.infiniteImg {
			margin-top:40px;
		}
	}
}
</style>
