<!-- 特卖 标题-->
<template>
  <div class="content">
   <span style="visibility: hidden;" class="sp">SUPER VALUE MAD JOB</span>
   <h6>{{tits}}</h6>
   <span class="sp1">SALE</span>
   <span class="sp2"></span>
  </div>
</template>
<script>
export default {
  name: 'supertitle',
  props: {
    tits:String
  },
  methods:{
  }
}
</script>
<style  lang="scss" scoped>
.content {
  background: url('../../assets/img/supertitle/bdr.png') center center no-repeat;width: 256px;height: 116px;margin:0 auto;
  h6 {
    color:#fff;font-size: 32px;text-align: center;letter-spacing: 10px;text-indent:10px;margin:10px 0px;
  }
  span {
    color:#fff;
  }
  .sp {
    text-align: center;display: block;font-size: 14px;
  }
  .sp1 {
    display: block;width: 188px;height: 23px;text-align: center;line-height: 23px;margin:0 auto;letter-spacing: 20px;text-indent: 20px;background-image: linear-gradient(#f3e8ca, #dbb380);font-size: 12px;
  }
  .sp2 {
    display: block;width: 36px;height: 14px;position: relative;margin:0 auto;overflow: hidden;margin-top:8px;
    &:before {
      content: '';display: block;width: 7px;height: 20px;background: #fff;top:-6px;left:9px;position: absolute;transform:rotate(-45deg);
    }
    &:after {
      content: '';display: block;width: 7px;height: 20px;background: #fff;top:-6px;right:9px;position: absolute;transform:rotate(45deg);
    }
  }
}
</style>
