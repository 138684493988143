<!-- 限时特卖 -->
<template>
  <div class="content">
    <div class="list" v-for='item,index in arrdata.length%2==0?arrdata.length/2:(arrdata.length-1)/2+1' :key='index'>
      <div class="box">
        <el-row :gutter='17'>
          <el-col :span="12" >
            <div class="lt">
              <img :src="arrdata[index*2].url">
            </div>
            <div class="rt">
              <h6 :title='arrdata[index*2].title'>{{arrdata[index*2].title}}</h6>
              <div class="price">
                <span>折扣价:</span>
                <span><i>&yen;</i>{{arrdata[index*2].pirce | priceF}}</span>
              </div>
              <button @click='link(arrdata[index*2])'>立即购买</button>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="lt" v-if='arrdata[index*2+1]&&arrdata[index*2+1].pirce'>
              <img :src="arrdata[index*2+1].url">
            </div>
            <div class="rt"  v-if='arrdata[index*2+1]&&arrdata[index*2+1].pirce'>
              <h6 :title="arrdata[index*2+1].title">{{arrdata[index*2+1].title}}</h6>
              <div class="price">
                <span>折扣价:</span>
                <span><i>&yen;</i>{{parseInt(arrdata[index*2+1].pirce)}}</span>
              </div>
              <button @click='link(arrdata[index*2])'>立即购买</button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'limitsell',
  props: {
    arrdata:Array
  },
  methods:{
    link(i){
      if(i){
        // 跳转详情
        this._router({then:()=>{
          // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
          var id = i.Id + '.html'
          this._open({path:'/predetail',query:{idcx:'',id:id,}})
        }})
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(this.arrdata)
    })
  },
  filters:{
    priceF(v){
      return parseInt(v);
    }
  }
}
</script>
<style  lang="scss" scoped>
.list {
  width: 1190px;height: 450px;border:5px solid #ee2b4b;border-radius: 5px;margin-bottom:50px;background: #640201;
  .box {
    width: 1157px;margin:0 auto;margin-top:29px;
    .el-row {
      .lt {
        width: 330px;height: 390px;float:left;display: flex;justify-content: center;align-items: center;background: #fff;
        img {
          display: block;width:300px;height: 300px;
        }
      }
      .rt {
        width: 240px;height: 390px;background: linear-gradient(#f7e3ca, #dcb280);float:right;
        h6 {
          font-size: 16px;margin-top:80px;padding:0 24px;line-height: 30px;  text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;color:#302d34;
        }
        .price {
          width: 200px;margin:0 auto;margin-top:10px;
          span {
            display: inline-block;width: 200px;
          }
          span:nth-child(1) {
            font-size: 30px;font-weight: bold;color:#30332c;padding-left:4px;
          }
          span:nth-child(2) {
            font-size: 66px;color:#bd0020;letter-spacing: -5px;vertical-align: 6px;font-weight: bold;text-align: left;
            i {
              font-style: normal;font-size: 24px;margin-right: 10px;
            }
          }
        }
        button {
          width: 130px;height: 50px;background: #5e0012;color:#ddb087;border-radius: 30px;font-size: 18px;line-height: 50px;margin:0 auto;display: block;margin-top:40px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
