<!-- 获取验证码 按钮-->
<template>
  <div class="getcodebtn">
    <button :disabled="dis" @click='getcode'>
    	{{name}}
    </button>
  </div>
</template>
<script>
import {regPhone,setOrGetCode} from '../../assets/js/common.js' //公用js
export default {
  name: 'getcodebtn',
  data(){
  	return {
  		name:'免费获取验证码',
      dis:false,//按钮只可点一次
      names:60,
      t:0,//倒计时存储
  	}
  },
  props: {
    initPhone: null,
    types:{
      default:0,
      type:Number
    },
    newPhone:null
  },
  methods:{
  	getcode(){
  		var _this = this;
  		// 发送请求获得验证码-启动倒计时
      if(this.initPhone==''){
        this.$emit('exportErr','请输入手机号')
        return;
      }else if(!regPhone(this.initPhone)){
        this.$emit('exportErr','请输入正确的手机号')
        return;
      }
      if(this.types===2){
        if(this.newPhone){
          if(!regPhone(this.newPhone)){
            this.$emit('exportErr','请输入正确的手机号')
            return;
          }
          if(this.newPhone==this.initPhone){
            this.$emit('exportErr','不能绑定原手机')
            return;
          }
        }else{
          this.$emit('exportErr','请输入手机号')
          return;
        }
      }
      this.$emit('exportpass')
      this.name = '发送中...'
      this.dis = true
      let _types;
      if(this._spf(this.$route.query.types)){
        _types = this._spf(this.$route.query.types)
      }else{
        _types = ''
      }
      var _url = ['Login/ObtainMoblieCode?phone=','UserCenter/ObtainMoblieCode?type='+_types+'&phone=','UserCenter/ObtainMoblieCode?phone=','UserCenter/ObtainMoblieCode?phone='][this.types]
      this.axios.post(process.env.VUE_APP_URL+_url+this.initPhone,this.keys())
      .then(res => {
        if(res.data.Success){
          // 成功获取
          setOrGetCode({method:'set',code:res.data.Data.rexCode});//存验证码
          _this.names = 60;
          _this.name = _this.names+'s';
          _this.t = setInterval(function(){
            _this.names = _this.names - 1;
            _this.name = _this.names+'s';
            if(_this.names==0){
              clearTimeout(_this.t)
              _this.name='免费获取验证码'
              _this.dis = false
            }
          },1000)
        }else{
          // 失败获取
          // _this.$MessageBox.alert('获取验证码失败',{type:'error'});
          this.$emit('exportErr',res.data.Error)
          _this.name='免费获取验证码'
          _this.dis = false
        }
      }, res => {
        // _this.$MessageBox.alert('系统异常',{type:'error'});
        _this.name='免费获取验证码'
        _this.dis = false
      })
  	}
  }
}
</script>
<style  lang="scss" scoped>
.getcodebtn {
	button {
		width: 144px;height: 46px;background: #f7f8f9;color:#252525;border:1px solid #e9e9e9;border-radius: 5px;font-size: 14px;
		&:hover {
			opacity: 0.8;
		}
	}
}
</style>
