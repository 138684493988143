<template>
  <div class="carouselFade" >
    <div @mouseenter='mouseenter' @mouseleave='mouseleave'  class="cnt" :class='{typeCnt:type==1}' :style='{height:height}' >
      <div class="img" >
        <a v-if='type==0' :href="item.linkurl?item.linkurl:'javascript:;'" v-for='item,index in datas' :key='index'><img  :title='item.title' :src="item.src" :class='{showimg:choice==index}' ></a>
        <div v-if='type==1' v-for="item,index in datas" :key="index">
          <img   v-if='expimg(item)' :class='{showimg:choice==index}' @mouseenter='mouseenter2' @mouseleave='mouseleave1' :src='item.ImagePath | _src(that)' >
          <video  v-if='exvideo(item)' autoplay="autoplay" loop="loop"  @mouseenter='mouseenter2' @mouseleave='mouseleave1' muted :class='{showimg:choice==index}' :src="item.ImagePath | _src(that)"  ref='video' controls="controls">
            <!-- controls="controls" -->
          您的浏览器不支持 video 标签。
          </video>
        </div>
      </div>
      <div class="menu" :style='{width:menu.width,top:menu.top}' v-show='hoverSlt||hoverBtm'>
        <button @mouseenter='mouseenter'   @mouseleave='mouseleave1' @click='back' class='lt' :style='{left:menu.left}'><svg class="icon"><use xlink:href="#iconziyuan2"></use></svg></button>
        <button @mouseenter='mouseenter'  @mouseleave='mouseleave1'  @click='next' class='rt' :style='{right:menu.right}'><svg class="icon"><use xlink:href="#iconziyuan4"></use></svg></button>
      </div>
      <div class="menu1" :style='{width:menu1.width,top:menu1.top}' v-show='menu1.show'>
        <div v-if='menu1.left' class="box" :style='{left:menu1.left,top:menu1.top}'>
          <button @mouseenter='mouseenter1' :class='{choicemenu:choice==index}'  v-for='item,index in datas' :key='index'></button>
        </div>
        <div v-if='menu1.right' class="box" :style='{right:menu1.right,top:menu1.top}'>
          <button @mouseenter='mouseenter1(index)'  :class='{choicemenu:choice==index}' v-for='item,index in datas' :key='index'></button>
        </div>
      </div>
      <div class="menu2" v-if='type==1' >
        <div class="showbox">
          <div class="box" :style='{width:82*datas.length+"px",left:boxleft+"px"}'  >
            <div v-for="item,index in datas" :key="index" :class='{choicemenu:choice==index}' @mouseenter='mouseenter1(index)'>
              <img   v-if='expimg(item)' :class='{showimg:choice==index}' :src='item.ImagePath | _src(that)' >
              <video  v-if='exvideo(item)'  :class='{showimg:choice==index}' :src="item.ImagePath | _src(that)" >
              您的浏览器不支持 video 标签。
              </video>
            </div>
          </div>
        </div>
        <button  @click='btmnext' class="btmback"><svg class="icon"><use xlink:href="#iconziyuan2"></use></svg></button>
        <button  @click='btmback' class="btmnext"><svg class="icon"><use xlink:href="#iconziyuan4"></use></svg></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'carouselFade',
  props: {
    datas: null,
    carouselImgBtm:null,
    type:{
      default:0,
      type:Number
    },
    hoverSlt:{
      default:true,
      type:Boolean
    },
    height:{
      default:'550px',
      type:String
    },
    auto:{
      default:true,
      type:Boolean
    },
    menu:{
      default(){return {
        width:'1200px',
        top:'240px',
        left:'250px',
        right:'0px'
      }},
      type:Object
    },
    menu1:{
      default(){return {
        width:'1200px',
        top:'260px',
        right:'0px',
        left:null,
        show:true
      }},
      type:Object
    },
  },
  data(){
    return {
      choice:0,
      t:0,
      that:this,
      actives:0,
      boxleft:0,
      hoverBtm:false
    }
  },
  methods:{
    mouseleave1(){
      // 鼠标离开事件
      this.hoverBtm = false
    },
    mouseenter2(){
      // 鼠标滑过图片
      this.hoverBtm = true
    },
    btmback(){
      if(this.datas.length*82-450<this.boxleft){
        this.boxleft-=450
        if(this.boxleft<this.datas.length*82-450){
          this.boxleft=450-this.datas.length*82
        }
      }else{
        this.boxleft=463-this.datas.length*82
      }
    },
    btmnext(){
      if(this.boxleft<0){
        this.boxleft+=450
        if(this.boxleft>0){
          this.boxleft=0
        }
      }else{
        this.boxleft=0
      }
    },
    expimg(item){
      // console.log(item)
      var _ex = /\.jpg|\.png|\.jpeg/ig;
      // console.log(_ex.test(item.ImagePath))
      return _ex.test(item.ImagePath)
    },
    exvideo(item){
      var _ex = /\.mp4/ig;
      // console.log(_ex.test(item.ImagePath))
      return _ex.test(item.ImagePath)
    },
    back(){
      // 上一张
      if(this.choice==0){
        this.choice=this.datas.length-1
      }else{
        this.choice--
      }
    },
    next(){
      // 下一张
      if(this.choice<this.datas.length-1){
        this.choice++
      }else{
        this.choice=0
      }
    },
    autoplay(){
      this.t = setInterval(()=>{
        if(this.choice<this.datas.length-1){
          this.choice++
        }else{
          this.choice = 0
        }
      },7000)
    },
    mouseenter(){
      // 鼠标划入
      // console.log(1)
      clearInterval(this.t)
      this.mouseenter2()
    },
    mouseenter1(v){
      // 鼠标划入
      clearInterval(this.t)
      this.choice = v
      var _ex = /\.mp4/ig;
      // console.log(_ex.test(item.ImagePath))
      // console.log(_ex.test(this.datas[v].ImagePath))
    },
    mouseleave(){
      // 鼠标离开
      if(this.auto){
        this.autoplay()
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(this.datas)
      if(this.auto){
        this.autoplay()
      }
    })
  },
  filters:{
    _src(v,that){
      return that._src + v
    },
  },
  destroyed(){
    // 组件销毁清空定时变量
    clearInterval(this.t)
  }
}
</script>
<style  lang="scss" scoped>
.cnt {
  position: relative;
  .img {
    position: relative;
    a {
      cursor: pointer;
    }
    img {
      display: block;position: absolute;top:0px;left:0px;opacity: 0;transition:opacity 2s;cursor: pointer;z-index: 8;
    }
    .showimg {
      opacity: 1;z-index: 9;
    }
  }
  .menu {
    margin:0 auto;position: relative;z-index: 9;
    button {
      position: absolute;z-index: 10;width: 25px;height: 70px;top:0px;background: rgba(0,0,0,0.2);display: flex;justify-content: center;align-items: center;
      svg {
        fill: #fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .lt {
      left:0px;
    }
    .rt {
      right:0px;
    }
  }
  .menu1 {
    margin:0 auto;position: relative;z-index: 9;
    .box {
      position: absolute;
      button {
        width: 13px;height: 13px;border:1px solid #fff;background: none;border-radius: 50%;margin-right: 5px;
        &:last-child {
          margin-right: 0px;
        }
      }
      .choicemenu {
        background: #fff;
      }
    }
  }
}
.typeCnt {
  .img {
    img {
      width: 450px;height: 450px;
    }
    video {
      width: 450px;height: 450px;display: block;position: absolute;top:0px;left:0px;opacity: 0;transition:opacity 2s;cursor: pointer;object-fit: fill;z-index: 8;
    }
    .showimg {
      opacity: 1;
    }
  }
  .menu2 {
    position: absolute;bottom:0px;left:0px;height: 70px;width: 450px;
    .showbox {
      width: 450px;overflow: hidden;margin:0 auto;position: relative;height: 70px;
      .box {
        position: absolute;top:0px;height: 70px;transition: left 0.5s;
        img {
          width: 70px;height: 70px;float:left;margin-right: 12px;cursor: pointer;
          &:hover {
            width: 64px;height: 64px;border:2px solid red;
          }
        }
        video {
          width: 70px;height: 70px;object-fit: fill;float:left;margin-right: 12px;cursor: pointer;
          &:hover {
            width: 64px;height: 64px;border:2px solid red;
          }
        }
        .choicemenu {
          img,video {
            width: 64px;height: 64px;border:2px solid red;
          }
        }
      }
    }
    .btmback {
      position: absolute;top:15px;left:0px;background: rgba(0,0,0,0.3);width: 40px;height:40px;display: flex;justify-content: center;align-items: center;border-radius: 50%;z-index: 999;visibility: hidden;
      svg {
        fill: #fff;
      }
    }
    .btmnext {
      position: absolute;top:15px;right: 0px;background: rgba(0,0,0,0.3);width: 40px;height: 40px;display: flex;justify-content: center;align-items: center;border-radius: 50%;z-index: 999;visibility: hidden;
      svg {
        fill: #fff;
      }
    }
    &:hover {
      .btmback,.btmnext {
        visibility:visible;
      }
    }
  }
}
</style>
