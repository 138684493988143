import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import limit from '../views/limitKiller.vue'
import supers from '../views/superSell.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/index.html',
    name: 'index',
    component: index
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/limit.html',
    name: 'limit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: limit
  },
  {
    path: '/super.html',
    name: 'super',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "super" */ '../views/superSell.vue')
    component:supers
  },
  {
    path: '/predetail',
    name: 'predetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "predetail" */ '../views/predetail.vue')
  },
  {
    path: '/newpro.html',
    name: 'newpro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "newpro" */ '../views/newpro.vue')
  },
  {
    path: '/accountlogin',
    name: 'accountlogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "accountlogin" */ '../views/accountlogin.vue')
  },
  {
    path: '/accountlogin.html',
    name: 'accountlogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "accountlogin" */ '../views/accountlogin.vue')
  },
  {
    path: '/accountregister',
    name: 'accountregister',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "accountregister" */ '../views/accountregister.vue')
  },
  {
    path: '/accountregister.html',
    name: 'accountregister',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "accountregister" */ '../views/accountregister.vue')
  },
  {
    path: '/buycar.html',
    name: 'buycar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "buycar" */ '../views/buycar.vue')
  },
  {
    path: '/settlebill',
    name: 'settlebill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settlebill" */ '../views/settlebill.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "payment" */ '../views/payment.vue')
  },
  {
    path: '/paymentalipay',
    name: 'paymentalipay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "paymentalipay" */ '../views/paymentalipay.vue')
  },
  {
    path: '/paymentwechat',
    name: 'paymentwechat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "paymentwechat" */ '../views/paymentwechat.vue')
  },
  {
    path: '/payresult',
    name: 'payresult',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "payresult" */ '../views/payresult.vue')
  },
  {
    path: '/liveonlin.html',
    name: 'liveonlin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "liveonlin" */ '../views/liveonlin.vue')
  },
  {
    path: '/showcolumn',
    name: 'showcolumn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "showcolumn" */ '../views/showcolumn.vue')
  },
  {
    path: '/classlist',
    name: 'classlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "classlist" */ '../views/classlist.vue')
  },
  {
    path: '/classlist.html',
    name: 'classlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "classlist" */ '../views/classlist.vue')
  },
  {
    path: '/usercenter.html',
    name: 'usercenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "usercenter" */ '../views/usercenter.vue')
  },
  {
    path: '/usercenterorder.html',
    name: 'usercenterorder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "usercenterorder" */ '../views/usercenterorder.vue')
  },
  {
    path: '/orderdetail',
    name: 'orderdetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orderdetail" */ '../views/orderdetail.vue')
  },
  {
    path: '/applyafter',
    name: 'applyafter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "applyafter" */ '../views/applyafter.vue')
  },
  {
    path: '/applyaftercause',
    name: 'applyaftercause',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "applyaftercause" */ '../views/applyaftercause.vue')
  },
  {
    path: '/returngood',
    name: 'returngood',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "returngood" */ '../views/returngood.vue')
  },
  {
    path: '/userremark.html',
    name: 'userremark',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userremark" */ '../views/userremark.vue')
  },
  {
    path: '/remarkinfo',
    name: 'remarkinfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "remarkinfo" */ '../views/remarkinfo.vue')
  },
  {
    path: '/remarkdetail',
    name: 'remarkdetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "remarkdetail" */ '../views/remarkdetail.vue')
  },
  {
    path: '/remarkresult.html',
    name: 'remarkresult',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "remarkresult" */ '../views/remarkresult.vue')
  },
  {
    path: '/userprice.html',
    name: 'userprice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userprice" */ '../views/userprice.vue')
  },
  {
    path: '/userrecharge.html',
    name: 'userrecharge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userrecharge" */ '../views/userrecharge.vue')
  },
  {
    path: '/rechargemethod',
    name: 'rechargemethod',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rechargemethod" */ '../views/rechargemethod.vue')
  },
  {
    path: '/rechargepay',
    name: 'rechargepay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rechargepay" */ '../views/rechargepay.vue')
  },
  {
    path: '/payresultre',
    name: 'payresultre',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "payresultre" */ '../views/payresultre.vue')
  },
  {
    path: '/safecenter.html',
    name: 'safecenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "safecenter" */ '../views/safecenter.vue')
  },
  {
    path: '/setsteps',
    name: 'setsteps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setsteps" */ '../views/setsteps.vue')
  },
  {
    path: '/setsteps.html',
    name: 'setsteps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setsteps" */ '../views/setsteps.vue')
  },
  {
    path: '/idverify',
    name: 'idverify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "idverify" */ '../views/idverify.vue')
  },
  {
    path: '/verifysetpas',
    name: 'verifysetpas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "verifysetpas" */ '../views/verifysetpas.vue')
  },
  {
    path: '/verifysetresult',
    name: 'verifysetresult',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "verifysetresult" */ '../views/verifysetresult.vue')
  },
  // {
  //   path: '/changepas',
  //   name: 'changepas',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "changepas" */ '../views/changepas.vue')
  // },
  {
    path: '/userdiscount.html',
    name: 'userdiscount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userdiscount" */ '../views/userdiscount.vue')
  },
  {
    path: '/userdata.html',
    name: 'userdata',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userdata" */ '../views/userdata.vue')
  },
  {
    path: '/addressdata.html',
    name: 'addressdata',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "addressdata" */ '../views/addressdata.vue')
  },
  {
    path: '/discountcenter.html',
    name: 'discountcenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "discountcenter" */ '../views/discountcenter.vue')
  },
  {
    path: '/bindaccount',
    name: 'bindaccount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bindaccount" */ '../views/bindaccount.vue')
  },
  // {
  //   path: '/findpassword',
  //   name: 'findpassword',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "findpassword" */ '../views/findpassword.vue')
  // },
  {
    path: '/aboutus.html',
    name: 'aboutus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutus" */ '../views/aboutus.vue')
  },
  {
    path: '/aboutuscontact.html',
    name: 'aboutuscontact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutuscontact" */ '../views/aboutuscontact.vue')
  },
  {
    path: '/aboutusteamwork.html',
    name: 'aboutusteamwork',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutusteamwork" */ '../views/aboutusteamwork.vue')
  },
  {
    path: '/aboutusstate.html',
    name: 'aboutusstate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutusstate" */ '../views/aboutusstate.vue')
  },
  {
    path: '/aboutusservice.html',
    name: 'aboutusservice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutusservice" */ '../views/aboutusservice.vue')
  },
  // {
  //   path: '/helpcenter',
  //   name: 'helpcenter',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "helpcenter" */ '../views/helpcenter.vue')
  // },
  // {
  //   path: '/helpcentervade',
  //   name: 'helpcentervade',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "helpcentervade" */ '../views/helpcentervade.vue')
  // },
  // {
  //   path: '/helpcenterflow',
  //   name: 'helpcenterflow',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "helpcenterflow" */ '../views/helpcenterflow.vue')
  // },
  {
    path: '/meetwwd.html',
    name: 'meetwwd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "meetwwd" */ '../views/meetwwd.vue')
  },
  {
    path: '/headline',
    name: 'headline',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "headline" */ '../views/headline.vue')
  },
  {
    path: '/headlineclass',
    name: 'headlineclass',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "headlineclass" */ '../views/headlineclass.vue')
  },
  {
    path: '/headlinemessage',
    name: 'headlinemessage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "headlinemessage" */ '../views/headlinemessage.vue')
  },
  {
    path: '/headlinemade',
    name: 'headlinemade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "headlinemade" */ '../views/headlinemade.vue')
  },
  {
    path: '/guideshop.html',
    name: 'guideshop',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guideshop" */ '../views/guideshop.vue')
  },
  {
    path: '/guideonlinepay.html',
    name: 'guideonlinepay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guideonlinepay" */ '../views/guideonlinepay.vue')
  },
  {
    path: '/guidebank.html',
    name: 'guidebank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidebank" */ '../views/guidebank.vue')
  },
  {
    path: '/guidenote.html',
    name: 'guidenote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidenote" */ '../views/guidenote.vue')
  },
  {
    path: '/guidesend.html',
    name: 'guidesend',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidesend" */ '../views/guidesend.vue')
  },
  {
    path: '/guiderange.html',
    name: 'guiderange',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guiderange" */ '../views/guiderange.vue')
  },
  {
    path: '/guidetake.html',
    name: 'guidetake',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidetake" */ '../views/guidetake.vue')
  },
  {
    path: '/guidemade.html',
    name: 'guidemade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidemade" */ '../views/guidemade.vue')
  },
  {
    path: '/guidedesign.html',
    name: 'guidedesign',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidedesign" */ '../views/guidedesign.vue')
  },
  {
    path: '/guidemadeknow.html',
    name: 'guidemadeknow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidemadeknow" */ '../views/guidemadeknow.vue')
  },
  {
    path: '/guidekeepitem.html',
    name: 'guidekeepitem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidekeepitem" */ '../views/guidekeepitem.vue')
  },
  {
    path: '/guidesale.html',
    name: 'guidesale',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidesale" */ '../views/guidesale.vue')
  },
  {
    path: '/guiderefund.html',
    name: 'guiderefund',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guiderefund" */ '../views/guiderefund.vue')
  },
  {
    path: '/guiderework.html',
    name: 'guiderework',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guiderework" */ '../views/guiderework.vue')
  },
  {
    path: '/guidecancel.html',
    name: 'guidecancel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidecancel" */ '../views/guidecancel.vue')
  },
  {
    path: '/guidelabour.html',
    name: 'guidelabour',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidelabour" */ '../views/guidelabour.vue')
  },
  {
    path: '/guidequestion.html',
    name: 'guidequestion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidequestion" */ '../views/guidequestion.vue')
  },
  {
    path: '/guidepropose.html',
    name: 'guidepropose',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidepropose" */ '../views/guidepropose.vue')
  },
  // {
  //   path: '*',//访问路由不存在，跳转404路由暂时用about页面
  //   name: 'index',
  //   component: index
  //   // name: '404',
  //   // // route level code-splitting
  //   // // this generates a separate chunk (about.[hash].js) for this route
  //   // // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "404" */ '../views/nofound.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
