<!-- 首页 -->
<template>
  <div class="index-content">
  	<div class="carouselBox ffcarouselBoxs">
  		<carouselFade class='carouselFade' height='550px'  :datas='carouselsData'></carouselFade>
  	</div>
  	<classmenu class='classmenu' @pomise='pomiseB'></classmenu>
  	<limitAct  height='289px' class='limitAct' @pomise='pomiseC'></limitAct>
  	<everyDay height='260px' class='everyDay' @pomise='pomiseD'></everyDay>
  	<newAndNow class='newAndNow' @pomise='pomiseEF'></newAndNow>
  	<hotProduct1 class='hotProduct1' @pomise='pomiseGH'></hotProduct1>
  	<choiceRec1 class='choiceRec1' @pomise='pomiseIJ'></choiceRec1>
  	<imghrs @pomise='pomiseK'></imghrs>
  	<div class="likesAuto">
  		<likes2 class='likes2' @pomise='pomiseL'></likes2>
  	</div>
  	<infiniteImg type='0' class='infiniteImg' @pomise='pomiseM'></infiniteImg>
  	<div class="popbtm" v-if="popbtm">
  		<button class="close" @click="popbtmclick"><svg class="icon"><use xlink:href="#iconshanchu"></use></svg></button>
  		<div class="box">
  			<div class="lt">
	  			<img src="../assets/moveP1.jpg">
	  			<div class="msg">
	  				<p>关注微微定公众号平台</p>
	  				<p>新用户享受<span>1</span>元体验购</p>
	  			</div>
	  		</div>
	  		<div class="rt">
	  			<img src="../assets/moveP.jpg">
	  			<div class="msg">
	  				<p>关注微微定微信小程序</p>
	  				<p>体验流畅，操作简单</p>
	  			</div>
	  		</div>
  		</div>
  	</div>
  </div>
</template>

<script>
import carouselFade from '@/components/carouselFade/carouselFade.vue';//轮播组件
import classmenu from '@/components/classmenu/classmenu.vue';//分类菜单
import limitAct from '@/components/limitAct/limitAct.vue';//限时秒杀
import everyDay from '@/components/everyDay/everyDay.vue';//每日特卖
import newAndNow from '@/components/newAndNow/newAndNow.vue';//新品首发和在线直播
import hotProduct1 from '@/components/hotProduct1/hotProduct1.vue';//热销产品
import choiceRec1 from '@/components/choiceRec1/choiceRec1.vue';//精品推荐
import likes2 from '@/components/likes2/likes2.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import imghrs from '@/components/imghrs/imghrs.vue';//底部滚动展示
export default {
  name: 'index',
  metaInfo() {
  	return {
  		title:this.htmltitle,
    meta: [
      {
        name: "description",
        content:
          ""
      },
      {
        name: "Keywords",
        content:
          ""
      }
    ]
  	}
  },
  data(){
  	return {
  	  htmltitle:'loading...',
  	  carouselsData:[
  	  	// {src:require('../assets/img/carousel/carousel.jpg')},{src:require('../assets/img/carousel/carousel1.jpg')},{src:require('../assets/img/carousel/carousel2.jpg')},
  	  ],
  	  b:null,//pomise
  	  c:null,//pomise
  	  d:null,//pomise
  	  ef:null,//pomise
  	  gh:null,//pomise
  	  ij:null,//pomise
  	  k:null,//pomise
  	  l:null,//pomise
  	  m:null,//pomise
  	  popbtm:true
  	}
  },
  components: {
  	carouselFade,
  	classmenu,
  	limitAct,
  	everyDay,
  	newAndNow,
  	hotProduct1,
  	choiceRec1,
  	likes2,
  	infiniteImg,
  	imghrs
  },
  mounted(){
  	this.$nextTick(()=>{
  		var _this = this;
  		let _pomise = this.$store.state.headPomise
		var a = function() {
		    return new Promise(function(resolve, reject) {
		        // 获取首页头部轮播图
			    _this._axios({then:()=>{
			      _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAllOutLineImg',_this.keys())
			      .then(res => {
			          if(res.data.Success){
			          	for(let i  in res.data.Data.List){
			          		res.data.Data.List[i].src =_this._src + res.data.Data.List[i].OutlinePath
			          		res.data.Data.List[i].title =res.data.Data.List[i].OutlineName
			          		res.data.Data.List[i].linkurl =res.data.Data.List[i].LinkUrl
			          	}
			          	_this.$set(_this,'carouselsData',res.data.Data.List)
			          	resolve()
			          }else{
			          	resolve()
			            _this.$MessageBox.alert(res.data.Error,{type:'error'});
			          }
			        }, res => {
			        	resolve()
			          // this.$MessageBox.alert('系统异常',{type:'error'});
			        })
			    }})
			    // end
		    })
		}
		this._reduce([_pomise.a, _pomise.b, a])
		.then(()=> {
			if(window.sessionStorage.getItem('isReadFirst')){
			   	window.sessionStorage.removeItem('isReadFirst')
			   	// this.$store.dispatch("cmtCap",true);//显示隐藏
			   	$("#app").css('opacity',1)
			   	// $("#app").addClass('noAfter')
			}
			window.scrollTo(0,0);//刷新置顶
			console.log('头部加载完成')
			if(JSON.parse(window.sessionStorage.getItem('commonseo')).SeoTitle){
		        document.title = JSON.parse(window.sessionStorage.getItem('commonseo')).SeoTitle
		      }
		      if(JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition){
		        $('meta[name=description]').attr('content',JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition)
		      }
		      if(JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey){
		         $('meta[name=Keywords]').attr('content',JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey)
		      }
			//this._NProgress.done();//关闭加载动画
    		let _footPomiseA = this.$store.state.footPomiseA
  			let _footPomiseB = this.$store.state.footPomiseB
    		this._reduce([_pomise.c,this.b,this.c,this.d,this.ef.e,this.ef.f,this.gh.g,this.gh.h,this.ij.i,this.ij.j,this.k,this.l,this.m,_footPomiseA,_footPomiseB])
			.then(()=> {
				console.log('内容队列加载')
			})
			.catch(function(e) {
			    console.log(e)
			})
		})
		.catch(function(e) {
		    console.log(e)
		})
		if(window.sessionStorage.getItem("popbtm")){
			this.popbtm = false
		}
  	})
  },
  methods:{
  	popbtmclick(){
  		this.popbtm = false
  		window.sessionStorage.setItem("popbtm",1)
  	},
  	pomiseB(v){
  		this.b = v
  	},
  	pomiseC(v){
  		this.c = v
  	},
  	pomiseD(v){
  		this.d = v
  	},
  	pomiseEF(v){
  		this.ef = v
  	},
  	pomiseGH(v){
  		this.gh = v
  	},
  	pomiseIJ(v){
  		this.ij = v
  	},
  	pomiseK(v){
  		this.k = v
  	},
  	pomiseL(v){
  		this.l = v
  	},
  	pomiseM(v){
  		this.m = v
  	}
  }
}
</script>
<style lang="scss" scoped>
.index-content {
	width: 1200px;margin:0 auto;position: relative;
	.carouselBox {
		width: calc(100vw - 4px);margin-left:calc(( 1200px - 100vw - 9px)/2);overflow: hidden;
		/deep/.img {
			margin-left:calc((100vw  - 1911px)/2);
		}
		// /deep/.el-carousel__arrow {
		// 	border-radius: 0px;width: 25px;height: 56px;
		// 	// background-color:rgba(0,0,0,0.6);
		// }
		// /deep/.el-carousel__arrow--right {
		// 	right:calc((100vw - 1180px)/2);
		// }
		// /deep/.el-carousel__arrow--left {
		// 	left:calc((100vw - 1180px)/2 + 210px);
		// }
	}
	// @-moz-document url-prefix() {
	//   .ffcarouselBoxs {
	//     width: calc(100vw - 8px)!important;
	//   }
	// }
	@media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
		.carouselBox {
			width: 1200px;margin-left:calc(( 1200px - 1200px)/2);
			/deep/.img {
				margin-left:calc((1200px  - 1911px)/2);
			}
			// /deep/.el-carousel__arrow--right {
			// 	right:calc((1200px - 1180px)/2);
			// }
			// /deep/.el-carousel__arrow--left {
			// 	left:calc((1200px - 1180px)/2 + 210px);
			// }
		}
		@-moz-document url-prefix() {
		  .ffcarouselBoxs {
		    width: 1200px!important;margin-left:calc(( 1200px - 1200px)/2)!important;
		  }
		}
	}
	.classmenu {
		position: absolute;top:25px;left:0px;z-index: 99;
	}
	.limitAct {
		margin-top:35px;
	}
	.everyDay {
		width: 1200px;margin:0 auto;margin-top:15px;overflow: hidden;
	}
	.newAndNow {
		margin-top:50px;
	}
	.hotProduct1 {
		width: 1205px;margin:0 auto;margin-top:30px;overflow: hidden;
	}
	.choiceRec1 {
		width: 1205px;margin:0 auto;margin-top:30px;overflow: hidden;
	}
	.imghr {
		margin-top:40px;
		a {
			cursor: pointer;
			img {
				display: block;border-radius: 10px;
			}
		}
	}
	.likesAuto {
		width: 1210px;margin:0 auto;margin-top:40px;overflow: hidden;
	}
	.infiniteImg {
		margin-top:40px;
	}
}
.popbtm {
	position: fixed;bottom:0px;left:0px;width: 100%;height: 100px;background: url('../assets/popbtm.png') 0px 0px no-repeat;z-index: 99;box-shadow:0px 1px 10px 5px rgb(255, 73, 129);background-size: 100%  100%;
	.close {
		position: absolute;top:10px;right: 10px;background:none;border:1px solid #fff;border-radius: 50%;width: 25px;height: 25px;display: flex;justify-content: center;align-items: center;
		svg {
			fill: #fff;
		}
	}
	.box {
		width: 800px;margin:0 auto;display: flex;justify-content: space-between;align-items: center;height: 100px;
		.lt {
			display: flex;align-items: center;
			img {
				display: block;width: 80px;height: 80px;
			}
			.msg {
				margin-left:10px;
				p {
					color:#fff;font-weight: bold;font-size: 14px;font-style:italic;
				}
				p:nth-child(1) {
					font-size: 24px;color:#fff;font-weight: bold;
				}
				p:nth-child(2) {
					margin-top:2px;
					span {
						font-size: 22px;
					}
				}
			}
		}
		.rt {
			display: flex;align-items: center;
			img {
				display: block;width: 80px;height: 80px;
			}
			.msg {
				margin-left:10px;
				p {
					color:#fff;font-weight: bold;font-size: 14px;font-style:italic;
				}
				p:nth-child(1) {
					font-size: 24px;color:#fff;font-weight: bold;
				}
				p:nth-child(2) {
					margin-top:10px;
					span {

					}
				}
			}
		}
	}
}
</style>
