import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,//用户数据
    buycarum:0,//购物车数量
    searchClick:0,//点击查询计数
    searchTxt:'',//点击查询参数
    orderTypeClick:0,//切换订单
    layout:{
      head:true,
      buycar:true
    },//布局参数
    logoUrl:null,//logo图片导航地址
    logoUrl1:null,//logo图片地址
    regtagger:false,//注册参数
    logintagger:false,//登录参数
    globalCap:false,
    toFullPath:null,//目标url地址
    fromFullPath:null,//来自url地址
    loginNum:0,//登录++
    topimgshow:false,//顶部图片显示隐藏
    headPomise:null,//全局头部pomise
    footPomiseA:null,//全局底部pomiseA
    footPomiseB:null,//全局底部pomiseB
    loadingData:false,//加载数据
  },
  mutations: {
    setloadingData(state,status){
      // 加载数据
      state.loadingData = status
    },
    setfootPomiseA(state,status){
      // 全局底部pomiseA
      state.footPomiseA = status
    },
    setfootPomiseB(state,status){
      // 全局底部pomiseA
      state.footPomiseB = status
    },
    setheadPomise(state,status){
      // 全局头部pomise
      state.headPomise = status
    },
    topimgshow(state,status){
      // 修改顶部图片显示
      state.topimgshow = status
    },
    setloginNum(state,status){
      // 登录触发
      state.loginNum = status
    },
  	setUser(state,user){
      // 设置用户信息
      state.user = user
    },
    setLogoUrl(state,status){
      // 设置logo图片导航地址
      state.logoUrl = status
    },
    setLogoUrl1(state,status){
      // 设置logo图片地址
      state.logoUrl1 = status
    },
    setLayout(state,status){
      // 设置布局
      state.layout.head = status
    },
    setLayoutBuyCar(state,status){
      // 设置布局---购物车
      state.layout.buycar = status
    },
    setregtagger(state,status){
      // 显示隐藏---注册
      state.regtagger = status
    },
    setlogintagger(state,status){
      // 显示隐藏---登录
      state.logintagger = status
    },
    setCap(state,status){
      // 全局遮罩---组件加载完成加载页面
      state.globalCap = status
    },
    setBuycarum(state,status){
      // 全局遮罩---组件加载完成加载页面
      state.buycarum = status
    },
    setsearchClick(state,status){
      // 全局遮罩---组件加载完成加载页面
      state.searchClick = status
    },
    setorderTypeClick(state,status){
      // 订单切换
      state.orderTypeClick = status
    },
    setsearchTxt(state,status){
      // 全局遮罩---组件加载完成加载页面
      state.searchTxt = status
    },
    settoFullPath(state,status){
      // 目标url地址状态
      state.toFullPath = status
    },
    setFromFullPath(state,status){
      // 来自url地址状态
      state.fromFullPath = status
    }
  },
  actions: {
    cmtloadingData(context,status){
      // 加载数据
      context.commit('setloadingData',status)
    },
    cmtfootPomiseA(context,status){
      // 全局底部pomiseA
      context.commit('setfootPomiseA',status)
    },
    cmtfootPomiseB(context,status){
      // 全局底部pomiseB
      context.commit('setfootPomiseB',status)
    },
    cmtheadPomise(context,status){
      // 全局头部pomise
      context.commit('setheadPomise',status)
    },
    cmttopimgshow(context,status){
      // 修改顶部图片显示
      context.commit('topimgshow',status)
    },
    cmtsetloginNum(context,status){
      // 登录触发
      context.commit('setloginNum',status)
    },
  	cmtSetUser(context,user){
      // 设置用户信息
      context.commit('setUser',user)
    },
    cmtLogoUrl(context,status){
      // 设置logo图片导航地址
      context.commit('setLogoUrl',status)
    },
    cmtLogoUrl1(context,status){
      // 设置logo图片地址
      context.commit('setLogoUrl1',status)
    },
    cmtSetLayout(context,status){
      // 设置布局
      context.commit('setLayout',status)
    },
    cmtLayoutBuyCar(context,status){
      // 设置布局---购物车
      context.commit('setLayoutBuyCar',status)
    },
    cmtregtagger(context,status){
      // 显示隐藏---注册
      context.commit('setregtagger',status)
    },
    cmtlogintagger(context,status){
      // 显示隐藏---登录
      context.commit('setlogintagger',status)
    },
    cmtCap(context,status){
      // 全局遮罩---组件加载完成加载页面
      context.commit('setCap',status)
    },
    cmtBuycarum(context,status){
      // 全局遮罩---组件加载完成加载页面
      context.commit('setBuycarum',status)
    },
    cmtsearchClick(context,status){
      // 全局遮罩---组件加载完成加载页面
      context.commit('setsearchClick',status)
    },
    cmtorderTypeClick(context,status){
      // 订单切换
      context.commit('setorderTypeClick',status)
    },
    cmtsearchTxt(context,status){
      // 全局遮罩---组件加载完成加载页面
      context.commit('setsearchTxt',status)
    },
    cmttoFullPath(context,status){
      // 目标url地址状态
      context.commit('settoFullPath',status)
    },
    cmtFromFullPath(context,status){
      // 目标url地址状态
      context.commit('setFromFullPath',status)
    }
  },
  modules: {
  }
})
