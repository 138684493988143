<template>
  <!-- :style="{opacity:this.$store.state.globalCap?1:0}" -->
  <div class="globalcontent" id="app"  >
  <!-- <div class="globalcontent" > -->
	  <Vheader v-if='this.$store.state.layout&&this.$store.state.layout.head&&this.$route.path.indexOf("/meetwwd")==-1' @headPomise='headPomise'></Vheader>
    <router-view />
    <Vfooter v-if='this.$store.state.layout.head' @footPomise='footPomiseA'></Vfooter>
    <Vrecord :types='this.$route.path.indexOf("/findpassword")!=-1||this.$route.path.indexOf("/bindaccount")!=-1?1:0' @footPomise='footPomiseB'></Vrecord>
    <positionRt v-if='this.$route.path.indexOf("/accountregister")==-1&&this.$route.path.indexOf("/accountlogin")==-1&&this.$route.path.indexOf("/bindaccount")==-1'></positionRt>
    <!-- 遮罩等待组件加载------加载完成隐藏遮罩-->
    <!-- <div v-show='!this.$store.state.globalCap' class="cap"></div> -->
  </div>
</template>
<script>
import './assets/js/iconfont.js';//引入图标js
import Vheader from '@/components/Vheader/Vheader.vue';//头部组件
import {setCarlist} from './assets/js/common.js' //公用js
// const Vfooter = resolve => require(['./components/Vfooter/Vfooter.vue'], resolve);//懒加载底部-全局底部
// const Vrecord = resolve => require(['./components/Vrecord/Vrecord.vue'], resolve);//懒加载底部-全局底部备案号
// const positionRt = resolve => require(['./components/positionRt/positionRt.vue'], resolve);//懒加载底部-全局右侧浮动
import Vfooter from '@/components/Vfooter/Vfooter.vue';//全局底部
import Vrecord from '@/components/Vrecord/Vrecord.vue';//全局底部备案号
import positionRt from '@/components/positionRt/positionRt.vue';//全局右侧浮动
export default {
  name: 'app',
  components: {
  	Vheader,
    Vfooter,
    Vrecord,
    positionRt
  },
  data(){
    return {
      headpomise:Object
      // hid:true
      // carlist:false
    }
  },
  mounted(){
    var _this =this;
    // $('#app').css('opacity',1)
    // console.log(this.$route.path)
    // window.addEventListener('storage', function (e) {
    //   console.log(e)
    //   // if(e.key==='userdata'){
    //   //   // localStorage.clear();
    //   //   window.location.replace("/accountlogin");
    //   // }
    // });
    // window.addEventListener('storage', (e) => {
    //   if(e.key==='phonedata'){
    //     localStorage.setItem(e.key, e.oldValue) //重新赋值修改前的值
    //   }
    // })
    // console.log(this.$store.state.toFullPath)
    if(this.$store.state.toFullPath.indexOf('/')!=-1||this.$store.state.toFullPath.indexOf('/super')!=-1||this.$store.state.toFullPath.indexOf('/limit')!=-1){
      // console.log(1)
      // return;
      if(this.$store.state.user){
        var _carlist = setCarlist({method:'get'})
        if(_carlist){
          var _ids = '',nums='',types='',idcxs='';
          for(let i in _carlist){
            if(i<(_carlist.length-1)){
              _ids+=_carlist[i].carId+','
              nums+=_carlist[i].buynumber+','
              types+=_carlist[i].type+','
              idcxs+=_carlist[i].idcx+','
            }
            if(i==(_carlist.length-1)){
              _ids+=_carlist[i].carId
              nums+=_carlist[i].buynumber
              types+=_carlist[i].type
              idcxs+=_carlist[i].idcx
            }
          }
          this.addUserCarlist({CustomerID:this.$store.state.user.CustomerID,_ids:_ids,nums:nums,types:types,fn:()=>{
            setCarlist({method:'del'})
            this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
          }})
        }else{
          this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
        }
      }else{
        // console.log(2)
        var _carlist = setCarlist({method:'get'})
        if(_carlist){
          this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
        }
        // else{
        //   this.$store.dispatch('cmtBuycarum',0)
        // }
      }
    }
    // 切换tab刷新购物车数量
    document.addEventListener('visibilitychange',function(){ //浏览器tab切换监听事件
        if(document.visibilityState=='visible') { //状态判断：显示（切换到当前页面）
            // 切换到页面执行事件
            // var normal_title=document.title; //title标签的内容
            // alert(normal_title);
            if(_this.$store.state.user){
              var _carlist = setCarlist({method:'get'})
              if(_carlist){
                var _ids = '',nums='',types='',idcxs='';
                for(let i in _carlist){
                  if(i<(_carlist.length-1)){
                    _ids+=_carlist[i].carId+','
                    nums+=_carlist[i].buynumber+','
                    types+=_carlist[i].type+','
                    idcxs+=_carlist[i].idcx+','
                  }
                  if(i==(_carlist.length-1)){
                    _ids+=_carlist[i].carId
                    nums+=_carlist[i].buynumber
                    types+=_carlist[i].type
                    idcxs+=_carlist[i].idcx
                  }
                }

                _this.addUserCarlist({CustomerID:_this.$store.state.user.CustomerID,_ids:_ids,nums:nums,types:types,fn:()=>{
                  setCarlist({method:'del'})
                  _this.getUserCarlist({CustomerID:_this.$store.state.user.CustomerID})
                }})
              }else{
                _this.getUserCarlist({CustomerID:_this.$store.s1tate.user.CustomerID})
              }
            }else{
              var _carlist = setCarlist({method:'get'})
              if(_carlist){
                _this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
              }
              // else{
              //   _this.$store.dispatch('cmtBuycarum',0)
              // }
            }
        }
        // else if(document.visibilityState=='hidden'){//状态判断：隐藏（离开当前页面）
        //      // 离开页面执行事件
        //     var normal_title=document.title; //title标签的内容
        //     alert(normal_title);
        // }
    });
    this.axios.post(process.env.VUE_APP_URLS+'Home/GetGlobalSEO',_this.keys())
    .then(res => {
      var _obj = {
        PhoneNumber:res.data.Data.PhoneNumber,
        Copyright:res.data.Data.Copyright,
        SeoTitle:res.data.Data.SeoTitle,
        SeoKey:res.data.Data.SeoKey,
        SeoDescrition:res.data.Data.SeoDescrition
      }
      this.commonseo = _obj
      window.sessionStorage.setItem('commonseo',JSON.stringify(_obj))
    }, res => {
      // this.$MessageBox.alert('系统异常',{type:'error'});
    })
  },
  methods:{
    headPomise(v){
      this.$store.dispatch("cmtheadPomise",v);
    },
    footPomiseA(v){
      this.$store.dispatch("cmtfootPomiseA",v);
    },
    footPomiseB(v){
      this.$store.dispatch("cmtfootPomiseB",v);
    },
    getUserCarlist(params){
      // 获取用户购物车列表
      this._axios({then:()=>{
      // this.axios.post(process.env.VUE_APP_URL+'Shop/ShoppingCartList?CustomerId='+params.CustomerID,this.keys())
      this.axios.post(process.env.VUE_APP_URL+'Shop/ShoppingCartList',this.keys({token:this._token({method:'get'})}))
        .then(res => {
          if(res.data.Success){
            // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
            //   this.$store.dispatch("cmtSetUser",null);//赋值
            //   this._usermet({method:'del'});//存本地
            //   this._token({method:'del'});//存本地
            //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
            // }
            this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.list.length))
            // setTimeout(()=>{
            //   this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.list.length))
            // },100)
            // setTimeout(()=>{
            //   this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.list.length))
            // },500)
            // setTimeout(()=>{
            //   this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.list.length))
            // },1000)
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
    addUserCarlist(params){
      // 给用户批量添加数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/BatchAddShoppingCart?productIds='+params._ids+'&nums='+params.nums+'&SellingTypes='+params.types+'&dynamicIds='+params.idcxs,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              if(params.fn){
                params.fn()
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
        }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
  },
  watch: {
    $route(to, from) {
      // console.log(to,from)
      // console.log(to.path,from.path)
      // if(to.path=='/payment'&&from.path!='/'){
      //    // 刷新页面处理支付密码组件不能重复调用问题
      //    window.location.reload()
      // }
      // console.log(1)
      if(to.path.indexOf('/buycar')==-1&&to.path.indexOf('/accountlogin')==-1&&to.path.indexOf('/accountregister')==-1){
        if(this.$store.state.user){
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            var _ids = '',nums='',types='',idcxs='';
            for(let i in _carlist){
              if(i<(_carlist.length-1)){
                _ids+=_carlist[i].carId+','
                nums+=_carlist[i].buynumber+','
                types+=_carlist[i].type+','
                idcxs+=_carlist[i].idcx+','
              }
              if(i==(_carlist.length-1)){
                _ids+=_carlist[i].carId
                nums+=_carlist[i].buynumber
                types+=_carlist[i].type
                idcxs+=_carlist[i].idcx
              }
            }
            this.addUserCarlist({CustomerID:this.$store.state.user.CustomerID,_ids:_ids,nums:nums,types:types,fn:()=>{
              setCarlist({method:'del'})
              this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
            }})
          }else{

            this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
          }
        }else{
          // console.log(2)
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
          }
          // else{
          //   this.$store.dispatch('cmtBuycarum',0)
          // }
        }
      }
    },
    '$store.state.loginNum'(n,o){
      // console.log(n,o)
      // console.log(this.$route.path)
      // return;
      if(this.$route.path.indexOf('/buycar')==-1&&this.$route.path.indexOf('/accountlogin')==-1&&this.$route.path.indexOf('/accountregister')==-1){
        if(this.$store.state.user){
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            var _ids = '',nums='',types='',idcxs='';
            for(let i in _carlist){
              if(i<(_carlist.length-1)){
                _ids+=_carlist[i].carId+','
                nums+=_carlist[i].buynumber+','
                types+=_carlist[i].type+','
                idcxs+=_carlist[i].idcx+','
              }
              if(i==(_carlist.length-1)){
                _ids+=_carlist[i].carId
                nums+=_carlist[i].buynumber
                types+=_carlist[i].type
                idcxs+=_carlist[i].idcx
              }
            }
            this.addUserCarlist({CustomerID:this.$store.state.user.CustomerID,_ids:_ids,nums:nums,types:types,fn:()=>{
              setCarlist({method:'del'})
              this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
            }})
          }else{
            this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
          }
        }else{
          // console.log(2)
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
          }
          // else{
          //   this.$store.dispatch('cmtBuycarum',0)
          // }
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import url('./assets/css/init.css');//全局初始化样式
.globalcontent {
  // opacity:0;transition: opacity 0.5s;
  width: 100%;
  // .cap {
  //   width: 100vw;height: 100vh;position: fixed;display: block;background: #fff;top:0px;left:0px;z-index: 999;
  // }
  // &:after {
  //   content: "";display: block;width: 100vw;height: 100vh;background: #fff;top:0px;left:0px;z-index: 999;position: fixed;
  // }
}
.noAfter {
  &:after {
    display: none;
  }
}
@media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
	.globalcontent {
		width: 1200px;
	}
}
</style>
