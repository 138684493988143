<!-- 限时秒杀 -->
<template>
  <el-row v-if='carouselsbox&&carouselsbox.length>0'>
    <el-col :span="5" >
      <div class="limitLt">
        <img src="../../assets/img/limitAct/limitAct.png">
        <div class="startTime">
          <span v-show='start!="正在秒杀"&&start!="即将结束"&&start!="已截止"&&startTime'>{{startTime.split(' ')[0].slice(startTime.split(' ')[0].indexOf('-')+1,startTime.split(' ')[0].length)}}</span>
          <span v-show='start!="正在秒杀"&&start!="即将结束"&&start!="已截止"&&startTime'>{{startTime.split(' ')[1]}}</span>
          <!-- <span>{{endTime.split(' ')[0].slice(endTime.split(' ')[0].indexOf('-')+1,endTime.split(' ')[0].length)}}</span> -->
       <!--    <span><i>{{startTime.split(' ')[0].slice(startTime.split(' ')[0].indexOf('-')+1,startTime.split(' ')[0].length)}}</i>{{startTime.split(' ')[1]}}</span>
          <span><i>{{endTime.split(' ')[0].slice(endTime.split(' ')[0].indexOf('-')+1,endTime.split(' ')[0].length)}}</i>{{endTime.split(' ')[1]}}</span> -->
          <span class="outname">{{start}}</span>
        </div>
        <div class="downTime">
          <span>{{H}}</span>
          <span>{{M}}</span>
          <span>{{S}}</span>
        </div>
      </div>
    </el-col>
    <el-col :span="19" class='carBox'>
      <div  class="carsoulBox" :style='{height:height}' >
        <button @click='back' class="back"><svg class="icon"><use xlink:href="#iconziyuan2"></use></svg></button>
        <button @click='next' class="next"><svg class="icon"><use xlink:href="#iconziyuan4"></use></svg></button>
        <div class="listBox" :style='{height:height}'  >
          <div class="itemBox" :style='{width:carousels.length*245+"px",left:left+"px"}'  @click='linkLimit'>
            <div class="imgbox" v-for="i,index in carousels" :key="index">
              <div class="tips">
                <div>
                  <p v-show='start!="正在秒杀"&&start!="即将结束"&&start!="已截止"&&startTime'>{{startTime.split(' ')[1]}}</p>
                  <p>{{start}}</p>
                </div>
              </div>
              <div class="imgs">
                <img :src="i.src">
              </div>
              <div class="price">
                <p :title='i.name'>{{i.name}}</p>
                <p><span><i>&yen;</i>{{i.price | priceF}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-carousel :autoplay="false" arrow="hover" :loop='false' :height='height'>
        <el-carousel-item v-for="item,index in carousels" :key="index">
          <div class="box"  @click='linkLimit'>
            <div class="imgbox" v-for='i,n in item.arr' :key='n'>
              <div class="tips">
                <div>
                  <p v-show='start!="正在秒杀"&&start!="即将结束"&&start!="已截止"&&startTime'>{{startTime.split(' ')[1]}}</p>
                  <p>{{start}}</p>
                </div>
              </div>
              <div class="imgs">
                <img :src="i.src">
              </div>
              <div class="price">
                <p :title='i.name'>{{i.name}}</p>
                <p><span><i>&yen;</i>{{i.price | priceF}}</span></p>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'limitAct',
  props: {
    height: String,
  },
  data(){
    return {
      left:0,
      startTime:'',
      endTime:'',
      start:null,
      idxId:null,//秒杀ID
      H:null,
      M:null,
      S:null,
      carousels:[
        // {
        //   arr:[
        //     {src:require('../../assets/img/limitAct/img.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img1.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img2.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img3.jpg'),name:'纸巾定制',price:380},
        //   ]
        // },
        // {
        //   arr:[
        //     {src:require('../../assets/img/limitAct/img.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img1.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img2.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img3.jpg'),name:'纸巾定制',price:380},
        //   ]
        // },
        // {
        //   arr:[
        //     {src:require('../../assets/img/limitAct/img.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img1.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img2.jpg'),name:'纸巾定制',price:380},{src:require('../../assets/img/limitAct/img3.jpg'),name:'纸巾定制',price:380},
        //   ]
        // },
      ],
      carouselsbox:null,
    }
  },
  methods:{
    back(){
      // 后退
      if(this.left<0){
        this.left +=900
        if(this.left>0){
          this.left = 0
        }
      }
    },
    next(){
      // 前进
      if(this.left>-(this.carousels.length*245-950)){
        this.left-=900
        if(this.left<-(this.carousels.length*245-950)){
          this.left=-(this.carousels. length*245-1000)
        }
      }
    },
    limitTime(){
      var _this = this;
      var nowTime = Date.now()
      var strTime = new Date(this.startTime).getTime();
      var edTime = new Date(this.endTime).getTime();
      var leftTime = edTime - nowTime; //时间差
      var befTime = strTime - nowTime;
      var  h, m, s, ms;
      if(( nowTime  - strTime)<=0){
        if((strTime - nowTime)>600000){
          this.start = '准点开抢';
        }else{
          this.start = '即将开始';
        }
        h = Math.floor(befTime / 1000 / 60 / 60%24);
        h = Math.floor(befTime / 1000 / 60 / 60);
        m = Math.floor(befTime / 1000 / 60 % 60);
        s = Math.floor(befTime / 1000 % 60);
        if(s < 10) {
            s = "0" + s;
        }
        if(m < 10) {
            m = "0" + m;
        }
        if(h < 10) {
            h = "0" + h;
        }
        this.H = h;
        this.M = m;
        this.S = s;
        return setTimeout(_this.limitTime, 1000);
      }
      if(leftTime >= 0) {
          if(leftTime>600000){
            this.start = '正在秒杀';
          }else{
            this.start = '即将结束';
          }
          // d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
          // h = Math.floor(leftTime / 1000 / 60 / 60%24);
          h = Math.floor(leftTime / 1000 / 60 / 60);
          m = Math.floor(leftTime / 1000 / 60 % 60);
          s = Math.floor(leftTime / 1000 % 60);
          if(s < 10) {
              s = "0" + s;
          }
          if(m < 10) {
              m = "0" + m;
          }
          if(h < 10) {
              h = "0" + h;
          }
          this.H = h;
          this.M = m;
          this.S = s;
          setTimeout(_this.limitTime, 1000);
      } else {
        this.H = '00';
        this.M = '00';
        this.S = '00';
        this.start = '已截止';
        // console.log('已截止')
      }
    },
    linkLimit(){
      // this.$router.push('limit')
      // this._open({path:'/limit',query:{idxId:this.idxId}})
      // var id = this.idxId +'.html'
      // this._open({path:'/limit',query:{idxId:id}})
      this._open({path:'/limit.html'})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this =this;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      var c = function() {
          return new Promise(function(resolve, reject) {
              // 秒杀列表数据
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetSeckillData?CustomerId='+CustomerID,_this.keys())
                .then(res => {
                    if(res.data.Success){
                      if(res.data.Data.SecKillDto.StartDate){

                      _this.startTime = res.data.Data.SecKillDto.StartDate.split('T')[0]+' '+res.data.Data.SecKillDto.StartDate.split('T')[1]
                      }
                      if(res.data.Data.SecKillDto.EndDate){
                      _this.endTime = res.data.Data.SecKillDto.EndDate.split('T')[0]+' '+res.data.Data.SecKillDto.EndDate.split('T')[1]
                      }
                      _this.idxId = res.data.Data.SecKillDto.Id
                      _this.limitTime();
                      var _data = [];
                      var _obj = {};
                          _obj.arr = [];
                      if(res.data.Data.DzproducttypeVM.length<4){
                        for(let i in res.data.Data.DzproducttypeVM){
                          _obj.arr.push({src:_this._src+res.data.Data.DzproducttypeVM[i].ImagePath,name:res.data.Data.DzproducttypeVM[i].AttachTypeName,price:res.data.Data.DzproducttypeVM[i].Price});
                        }
                        _data.push(_obj)
                      }else{
                        for(let i in res.data.Data.DzproducttypeVM){
                          _obj.arr.push({src:_this._src+res.data.Data.DzproducttypeVM[i].ImagePath,name:res.data.Data.DzproducttypeVM[i].AttachTypeName,price:res.data.Data.DzproducttypeVM[i].Price});
                          if(i>0&&(parseInt(i)+1)%4==0){
                            _data.push(_obj)
                            _obj = {};
                            _obj.arr = [];
                          }
                        }
                        if(res.data.Data.DzproducttypeVM.length%4!=0){
                          _data.push(_obj)
                        }
                      }
                      var _datas = [];
                      for(let i in _data){
                        _datas = _datas.concat(_data[i].arr)
                      }
                      _this.$set(_this,'carousels',_datas)
                      _this.$set(_this,'carouselsbox',res.data.Data.DzproducttypeVM)
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      this.$emit('pomise',c)
    })
  },
  filters:{
    priceF(v){
      return parseInt(v);
    }
  }
}
</script>
<style  lang="scss" scoped>
.limitLt {
  width: 218px;height: 250px;background-image: linear-gradient(#bc0120, #b90220);border-radius: 10px;padding-top:29px;
  img {
    display: block;margin:0 auto;
  }
  .startTime {
    text-align: center;padding-top:20px;
    span {
      display: inline-block;height: 26px;text-align: center;line-height: 26px;border-radius: 5px;color:#fff;font-size: 14px;
      i {
        font-style: normal;display: block;line-height: normal;height: auto;margin-top:-6px;
      }
      &:nth-child(1) {
        margin-right:5px;position: relative;
      }
      &:nth-child(2) {
        margin-right:20px;position: relative;
        // &:after {
        //   content: '';display: block;position: absolute;width: 12px;height: 1px;background: #fff;top:12px;right:-17px;
        // }
      }
      // &:nth-child(2) {
      //   margin-right:10px;
      // }
    }
    .outname {
    }
  }
  .downTime {
    text-align: center;padding-top:10px;
    span {
      background: #343434;display: inline-block;width: 50px;height: 40px;text-align: center;line-height: 40px;color:#fff;font-size: 20px;margin-right:15px;position: relative;border-radius: 5px;
      &:last-child {
        margin-right: 0px;
      }
      &:nth-child(1),&:nth-child(2) {
        &:after {
          content: '';display: block;position: absolute;width: 5px;height: 5px;background: #343434;top:10px;right:-10px;border-radius: 50%;
        }
        &:before {
          content: '';display: block;position: absolute;width: 5px;height: 5px;background: #343434;top:26px;right:-10px;border-radius: 50%;
        }
      }
    }
  }
}
// .box {
//   width: 950px;height: 289px;cursor: pointer;
//   .imgbox {
//     width: 210px;height: 270px;border:4px solid #bc0120;float:left;background: #fff;margin-right: 26px;border-radius: 10px;position: relative;
//     &:last-child {
//       margin-right: 0px;
//     }
//     .imgs {
//       width: 210px;height: 230px;display: flex;justify-content: center;align-items: center;
//       img {
//         display: block;max-width: 150px;max-height: 150px;
//       }
//     }
//     .price {
//       height: 40px;width: 210px;background: #bc0120;position: relative;
//       p {
//         &:nth-child(1) {
//           height: 40px;line-height: 40px;color:#fff;font-size: 16px;padding-left:10px;width: 110px;overflow: hidden;
//           text-overflow:ellipsis;white-space: nowrap;
//         }
//         &:nth-child(2) {
//           position: absolute;right:-4px;bottom:-4px;color:#fff;width: 110px;height: 70px;overflow: hidden;
//           span {
//             font-size: 20px;background: #362c37;width: 100px;height: 70px;display: block;border-top-left-radius: 40px;position: absolute;bottom:-20px;right:-20px;line-height: 55px;text-align: center;padding-right:15px;box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.6);
//             i {
//               font-style: normal;font-size: 16px;margin-right: 2px;
//             }
//           }
//         }
//       }
//     }
//     &:hover {
//       opacity: 0.7;
//       // box-shadow: 0 12px 36px rgba(0,0,0,0.1);
//     }
//     .tips {
//       position: absolute;top:0px;background: #bc0120;left:calc((220px - 50px)/2);width: 50px;text-align: center;height: 36px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;display: flex;align-items: center;margin-top:-5px;justify-content: center;
//       p {
//         color:#fff;font-size: 12px;display: block;width: 100%;
//         &:nth-child(1) {
//           margin-top:-2px;
//         }
//       }
//     }
//     .tips1 {
//       background: #fb7474;width: 60px;height: 20px;color:#fff;text-align: center;line-height: 20px;position: absolute;top:115px;left:calc((220px - 60px)/2);
//     }
//   }
// }
.carBox {
  // /deep/.el-carousel__arrow {
  //   border-radius: 0px;width: 25px;height: 56px;background-color:rgba(0,0,0,0.2);
  //   .el-icon-arrow-left:before  {
  //     color:#908c8d;font-weight: bold;
  //   }
  //   .el-icon-arrow-right:before {
  //     color:#908c8d;font-weight: bold;
  //   }
  // }
  // /deep/.el-carousel__indicators {
  //   display: none;
  // }
  .carsoulBox {
    position: relative;width: 950px;
    .listBox {
      width: 950px;overflow: hidden;position: absolute;
      .itemBox {
        position: absolute;left:0px;transition: left 0.5s;
        .imgbox {
          width: 210px;height: 270px;border:4px solid #bc0120;float:left;background: #fff;margin-right: 26px;border-radius: 10px;position: relative;
          &:last-child {
            margin-right: 0px;
          }
          .imgs {
            width: 210px;height: 230px;display: flex;justify-content: center;align-items: center;
            img {
              display: block;max-width: 150px;max-height: 150px;
            }
          }
          .price {
            height: 40px;width: 210px;background: #bc0120;position: relative;
            p {
              &:nth-child(1) {
                height: 40px;line-height: 40px;color:#fff;font-size: 16px;padding-left:10px;width: 110px;overflow: hidden;
                text-overflow:ellipsis;white-space: nowrap;
              }
              &:nth-child(2) {
                position: absolute;right:-4px;bottom:-4px;color:#fff;width: 110px;height: 70px;overflow: hidden;
                span {
                  font-size: 20px;background: #362c37;width: 100px;height: 70px;display: block;border-top-left-radius: 40px;position: absolute;bottom:-20px;right:-20px;line-height: 55px;text-align: center;padding-right:15px;box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.6);
                  i {
                    font-style: normal;font-size: 16px;margin-right: 2px;
                  }
                }
              }
            }
          }
          &:hover {
            opacity: 0.7;
            // box-shadow: 0 12px 36px rgba(0,0,0,0.1);
          }
          .tips {
            position: absolute;top:0px;background: #bc0120;left:calc((220px - 50px)/2);width: 50px;text-align: center;height: 36px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;display: flex;align-items: center;margin-top:-5px;justify-content: center;
            p {
              color:#fff;font-size: 12px;display: block;width: 100%;
              &:nth-child(1) {
                margin-top:-2px;
              }
            }
          }
          .tips1 {
            background: #fb7474;width: 60px;height: 20px;color:#fff;text-align: center;line-height: 20px;position: absolute;top:115px;left:calc((220px - 60px)/2);
          }
        }
      }
    }
    .back,.next {
      position: absolute;top:90px;width: 30px;height: 70px;line-height: 70px;background:rgba(0,0,0,0.3);z-index: 999;visibility: hidden;
      svg {
        width:20px;height: 20px;vertical-align: -5px;fill: #fff;
      }
    }
    .back {
      left:0px;
    }
    .next {
      right:0px;
    }
    &:hover {
      .back,.next {
        visibility: visible;
      }
    }
  }
}
</style>
