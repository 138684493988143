<!-- 弹窗 登录 -->
<template>
  <div class="register">
    <div v-show='scanOrId'  class="content">
      <button class="close" @click='closeReg'><svg class="icon"><use xlink:href="#iconshanchu"></use></svg></button>
      <h6 class="h6">
        账号密码登录
        <div class="rt" @click='taggerLoginMethod'>
          <p class="sm">扫码安全登录<span></span></p>
        </div>
      </h6>
      <!-- :class='{err:errorData.phonetxt}' -->
      <div class="ipt" >
        <svg class="icon"><use xlink:href="#iconzhanghao"></use></svg>
        <!-- <input type="password" style="position: fixed;z-index:-9999;"> -->
        <input type="text" autocomplete="new-password" v-model='phone' @focus='iptfocus("phonetxt")' :placeholder="phonetxt">
        <span class="errorData">{{errorData.phonetxt}}</span>
      </div>
      <!-- :class='{err:passwordreg}' -->
      <div class="ipt" >
        <svg class="icon"><use xlink:href="#iconmima"></use></svg>
        <!-- <input type="password" style="position: fixed;z-index:-9999;"> -->
        <!-- <input type="password" autocomplete="new-password" @input='pasIpt' @keyup.enter='regbtn' v-model='password'  :placeholder="passwordtxt"> -->
        <input type="password" autocomplete="new-password"  @keyup.enter='regbtn' @focus='iptfocus("passwordtxt")' v-model='password'  :placeholder="passwordtxt">
        <span class="errorData">{{errorData.passwordtxt}}</span>
      </div>
      <div class="autologin">
        <div class="lt">
          <input type="checkbox"  id="autologin"  v-model="autologin">
          <label for="autologin">记住账号</label>
        </div>
        <div class="rt">
          <!-- <router-link to="/setsteps?types=3">忘记密码?</router-link> -->
          <a href="javascript:;" @click='linkfind'>忘记密码?</a>
        </div>
      </div>
      <button class="reg" :disabled='regck' @click='regbtn'>{{regtxt}}</button>
      <div class="other">
        <div class="lt">
          <button @click='qqlogin' :disabled="qqpoplogin"><svg class="icon"><use xlink:href="#iconQQ"></use></svg></button>
          <button @click='wxlogin' :disabled="!scanOrId"><svg class="icon"><use xlink:href="#iconweixin"></use></svg></button>
        </div>
        <div class="rt">
          还没有账号？<button @click='taggerRegister'>免费注册></button>
        </div>
      </div>
    </div>
    <div v-show='!scanOrId' class="content content1">
      <button class="close" @click='closeReg'><svg class="icon"><use xlink:href="#iconshanchu"></use></svg></button>
      <h6 class="h6">
        {{scantxt}}安全登录
        <div class="rt" @click='taggerLoginMethod'>
          <p class="sm">账号安全登录<span></span></p>
        </div>
      </h6>
      <div class="codebox" v-show='scantxt=="微信"'>
        <div class="loading" v-show='wechatcodeCap'><img  src="../../assets/img/login/loading.png"></div>
        <img :src="scancode">
      </div>
      <div class="btmmsg" v-show='scantxt=="微信"'>
        <p>{{scantxt}}扫描二维码登录<button @click='refreshcode'>刷新二维码</button></p>
        <p>扫码代表同意<a href="javascript:;" @click='linkUrl'>《服务协议》</a></p>
      </div>
      <div class="other">
        <div class="lt">
          <button v-show='wxOrQq' @click='qqlogin' :disabled="qqpoplogin"><svg class="icon"><use xlink:href="#iconQQ"></use></svg></button>
          <button v-show='!wxOrQq' @click='wxlogin'><svg class="icon"><use xlink:href="#iconweixin"></use></svg></button>
        </div>
        <div class="rt">
          还没有账号？<button @click='taggerRegister'>免费注册></button>
        </div>
      </div>
    </div>
    <el-dialog title="QQ登录" :visible.sync="qqpoplogin" :modal-append-to-body='false' custom-class='qqpoplogin'>
      <iframe class='iframe' width="800" :src="qqloginurl"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import {regPhone,setOrGetPhone,usermethod} from '../../assets/js/common.js' //公用js
export default {
  name: 'login',
  data(){
    return {
      phone:'',
      password:'',
      password_:'',
      phonetxt:'手机号',
      phonereg:false,
      passwordtxt:'输入密码',
      passwordreg:false,
      regtxt:'登录',
      regck:false,//请求按钮不能多次点击
      autologin:false,//记住账号
      scantxt:'微信',
      wxOrQq:true,//默认微信
      scanOrId:true,//默认账号登录
      scancode:null,
      qqloginurl:null,//QQ登录url
      qqpoplogin:false,//qq登录弹窗
      unionidQQ:'',//QQ登录唯一的参数作为监听id
      unionidWechat:'',//微信登录唯一的参数作为监听id
      wechatcodeCap:true,//微信二维码遮罩
      errorData:{
        phonetxt:'',
        passwordtxt:''
      },
      wswechat:null,//io
    }
  },
  components: {
  },
  props: {
    msg: String
  },
  methods:{
    linkUrl(){
      this.$store.dispatch("cmtregtagger",false)
      this.$store.dispatch("cmtlogintagger",false)
      this._router({then:()=>{
        this.$router.push({path: "/aboutusservice.html"})
      }})
    },
    linkfind(){
      // 忘记密码
      this.$store.dispatch("cmtregtagger",false)
      this.$store.dispatch("cmtlogintagger",false)
      this._router({then:()=>{
        this.$router.push({path: "/setsteps?types=3.html"})
        // this._open({path: "/changepas"})
      },exp:false,catch:()=>{
        var pms = this.$route.fullPath + '.html'
        this.$router.push({path:'/accountlogin',query:{redirect:pms}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    iptfocus(v){
      // 重置验证提示
      for(let i in this.errorData){
        if(i==v){
          this.errorData[i]=''
        }
      }
    },
    regbtn(){
      var _this = this;
      // this.phonetxt='请输入手机号码';
      this.phonereg=false;
      // this.passwordtxt='设置密码';
      this.passwordreg=false;
      // 立即注册
      if(this.phone==''){
        this.errorData.phonetxt = '请输入手机号码'
        this.phonereg = true
      }else if(!regPhone(this.phone)){
        this.errorData.phonetxt = '请填写正确的手机号码'
        this.phonereg = true
        // this.phone=''
      }
      if(this.password==''){
        this.errorData.passwordtxt = '请填写密码'
        this.passwordreg = true
        // this.password = ''
        // this.password_ = ''
      }
      if(!this.phonereg&&!this.passwordreg){
        // 发送登录请求
        this.regtxt = '登录中...'
        this.regck = true
        if(this.autologin){
          // 记录账号到本地
          setOrGetPhone({method:'set',phone:this.phone})
        }else{
          // 删除本地记录的账号
          setOrGetPhone({method:'del'})
        }
        this.axios.post(process.env.VUE_APP_URL+'Login/PhoneLogin?phone='+this.phone+'&pwd='+this.password,this.keys())
        .then(res => {
          if(res.data.Success){
            _this.regtxt = '登录'
            _this.regck = false
            // 存数据/跳转
            _this.reset();
            _this.$store.dispatch("cmtSetUser",res.data.Data.CustomersDto);//改状态
            _this.$store.dispatch("cmtsetloginNum",1);//改状态
            // _this.$store.dispatch("cmttopimgshow",false);//改状态
            usermethod({method:'set',user:res.data.Data.CustomersDto});//存数据
            _this._token({method:'set',token:res.data.Data.token})
            window.sessionStorage.setItem('topImg',1)
            $('#topImgBanner').hide()
            // 关闭弹窗---如果重定向登录跳转重定向
            if(_this.$route.query.redirect){
              _this.$router.push(_this.$route.query.redirect)
            }else{
              if(_this.$route.path.indexOf('/accountlogin')!=-1){
                // this._open({path:'/'})
                // window.location.href='/'
                _this.$store.dispatch("cmtregtagger",false)
                _this.$store.dispatch("cmtlogintagger",false)
                this.$router.push('/index.html')
              }else{
                _this.$store.dispatch("cmtregtagger",false)
                _this.$store.dispatch("cmtlogintagger",false)
              }
            }
          }else{
            // this.$MessageBox.alert(res.data.Error,{type:'error'})
             _this.password='';
          // _this.password_='';
           _this.errorData.passwordtxt=res.data.Error
             _this.passwordreg=false
            _this.regtxt = '登录'
            _this.regck = false
          }
        }, res => {
          // _this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }
    },
    closeReg(){
      // 关闭登录
      this.reset();
      this.scantxt='微信'
      this.scanOrId=true
      this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
    },
    taggerLoginMethod(){
      // 切换登录方法-默认切换微信扫码
      this.reset();
      this.initPhone()
      this.scantxt= '微信'
      this.wxOrQq = true;
      this.scanOrId = !this.scanOrId
      if(!this.scanOrId){
        this.refreshcode()
      }
    },
    wxlogin(){
      // 微信扫码登录
      this.wechatcodeCap= true;
      this.scantxt= '微信'
      this.wxOrQq = true;
      this.scanOrId = false
      this.refreshcode()
    },
    refreshcode(){
      // 微信刷新二维码
      this.wechatcodeCap= true;
      this.unionidWechat =this.guid();
      if(!this.scanOrId){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Login/CtuWxLogin?unionid='+this.unionidWechat,this.keys())
          .then(res => {
            if(res.data.Success){
              this.scancode = res.data.Data.url
              setTimeout(()=>{this.wechatcodeCap=false},500)
              this.wechatWatch()
            }else{
            }
          }, res => {
          })
        }})
      }
    },
    wechatWatch(){
      var _this = this;
      // 微信登录推送
      // console.log(_this.$route.path)
      // console.log(this.unionidQQ)
      // _this.$router.push(_this.$route.path)
      //页面接收推送---微信登录验证数据
      this.wswechat.on(this.unionidWechat,  function  (data)  {
        _this.axios.post(process.env.VUE_APP_URL+'Login/ObtainCustomersByWxId?WxId='+data.Id,_this.keys())
        .then(res => {
          if(res.data.Success){
            if(res.data.Data.token==''){
              _this._router({then:()=>{
                window.sessionStorage.setItem('seninfo',JSON.stringify({url:res.data.Data.CustomersDto.WxDisplayUrl,name:res.data.Data.CustomersDto.wxName}))
                _this.$router.push({path: "/bindaccount?id="+res.data.Data.WeixinID+'&type=1.html'})
                // this._open({path: "/bindaccount"})
              }})
              return;
            }
            _this.$store.dispatch("cmtSetUser",res.data.Data.CustomersDto);//赋值
            _this.$store.dispatch("cmtsetloginNum",1);//改状态
            // _this.$store.dispatch("cmttopimgshow",false);//改状态
            usermethod({method:'set',user:res.data.Data.CustomersDto});//存本地
            _this._token({method:'set',token:res.data.Data.token})
            window.sessionStorage.setItem('topImg',1)
            $('#topImgBanner').hide()
            // 弹窗登录关闭弹窗就行
            // 如果是重定向到登录页就跳转参数里的重定向地址
            if(_this.$route.query.redirect){
              _this.$router.push(_this.$route.query.redirect)
            }else{
              if(_this.$route.path.indexOf('/accountlogin')!=-1){
                // this._open({path:'/'})
                // window.location.href='/'
                _this.$store.dispatch("cmtregtagger",false)
                _this.$store.dispatch("cmtlogintagger",false)
                _this.$router.push('/index.html')
              }else{
               _this.$store.dispatch("cmtregtagger",false)
                _this.$store.dispatch("cmtlogintagger",false)
              }
            }
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'})
          }
        }, res => {
          // _this.$MessageBox.alert('系统异常',{type:'error'});
        })
        // console.log(data.Id)
        // ws.emit("tasktip",  {  type:  "tt_dm",  taskid:  data.taskid,  busid:  data.busid  });
      });
    },
    qqlogin(){
      var _this = this;
      // QQ扫码登录
      // this.scantxt= 'QQ'
      // this.wxOrQq = false;
      // this.scanOrId = false
      // 请求qq登录url地址然后弹窗
      this.unionidQQ =this.guid();
      this.axios.post(process.env.VUE_APP_URL+'Login/CtuQQLogin?unionid='+this.unionidQQ,this.keys())
      .then(res => {
        if(res.data.Success){
          _this.qqloginurl = res.data.Data.url
          _this.qqpoplogin = true;
          _this.ioWatch()
        }else{
          this.$MessageBox.alert(res.data.Error,{type:'error'})
        }
      }, res => {
        // _this.$MessageBox.alert('系统异常',{type:'error'});
      })
    },
    taggerRegister(){
      // 切换注册弹窗
      this.reset();
      this.initPhone()
      this.scantxt='微信'
      this.scanOrId=true
      if(this.$route.path.indexOf('/accountlogin')!=-1){
        // 跳转购物车
        this._router({exp:false,catch:()=>{
          // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
        },then:()=>{
          this.$router.push('/accountregister.html')
        }})
      }else{
        this.$store.dispatch("cmtregtagger",!this.$store.state.regtagger)
        this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
      }
    },
    reset(){
      // 重置输入框
      this.phone='';
      this.password='';
      this.password_='';
      this.phonetxt='手机号'
      this.phonereg=false
      this.passwordtxt='输入密码'
      this.passwordreg=false
      this.regtxt='登录'
    },
    pasIpt(){
      // 处理input为密码的时候会保存账号密码
      // 监听密码输入
      // console.log(this.password_.length,this.password.length)
      if(this.password_.length>this.password.length){
        //获取最后一个字符加到到str,因为除了最后一个字符，其他的已经为*
        this.password+=this.password_.substr(this.password_.length-1,1);
        this.password_ = this.password_.replace(/./g,'*') //输入框内容全部变为*
      }else{
        this.password = this.password.substring(0,this.password.length - 1)

      }
      // console.log(this.password); //str即为输入框的内容
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
      });
    },
    ioWatch(){
      var _this = this;
      // QQ登录推送
      var  ws  =  io("wss://ctumin.51ctu.com:8018/api_net");//线上地址
      // var  ws  =  io("ws://192.168.1.35:8018/ctu");//测试地址
      ws.on("connect",  function  (e)  {
          // console.log('链接成功'+' ' + new Date())
      });
      // console.log(_this.$route.path)
      // console.log(this.unionidQQ)
      // _this.$router.push(_this.$route.path)
      //页面接收推送---qq登录验证数据
      ws.on(this.unionidQQ,  function  (data)  {
        _this.axios.post(process.env.VUE_APP_URL+'Login/ObtainCustomersByQQId?QQId='+data.Id,_this.keys())
        .then(res => {
          if(res.data.Success){
            if(res.data.Data.token==''){
              _this._router({then:()=>{
                window.sessionStorage.setItem('seninfo',JSON.stringify({url:res.data.Data.CustomersDto.WxDisplayUrl,name:res.data.Data.CustomersDto.QQName}))
                _this.$router.push({path: "/bindaccount?id="+res.data.Data.QQId+'&type=2.html'})
                // this._open({path: "/bindaccount"})
              }})
              return;
            }
            _this.$store.dispatch("cmtSetUser",res.data.Data.CustomersDto);//赋值
            _this.$store.dispatch("cmtsetloginNum",1);//改状态
            // _this.$store.dispatch("cmttopimgshow",false);//改状态
            usermethod({method:'set',user:res.data.Data.CustomersDto});//存本地
            _this._token({method:'set',token:res.data.Data.token})
            window.sessionStorage.setItem('topImg',1)
            $('#topImgBanner').hide()
            // 弹窗登录关闭弹窗就行
            // 如果是重定向到登录页就跳转参数里的重定向地址
            if(_this.$route.query.redirect){
              _this.$router.push(_this.$route.query.redirect)
            }else{
              if(_this.$route.path.indexOf('/accountlogin')!=-1){
                // this._open({path:'/'})
                // window.location.href='/'
                _this.$store.dispatch("cmtregtagger",false)
                _this.$store.dispatch("cmtlogintagger",false)
                _this.$router.push('/index.html')
              }else{
                _this.$store.dispatch("cmtregtagger",false)
                _this.$store.dispatch("cmtlogintagger",false)
                _this.qqpoplogin = false
              }
            }
            // setTimeout(()=>{
            //   _this.$router.push(_this.$route.path)
            // },200)
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'})
          }
        }, res => {
          // _this.$MessageBox.alert('系统异常',{type:'error'});
        })
        // console.log(data.Id)
        // ws.emit("tasktip",  {  type:  "tt_dm",  taskid:  data.taskid,  busid:  data.busid  });
      });
      ws.on("disconnect",  function  (res)  {
           // $("#linkstate").text("链接断开,重新连接中...");
      });
    },
    initPhone(){
      // 记录账号--自动填入账号
      var _data = setOrGetPhone({method:'get'})
      this.password=''
      this.password_=''
      if(_data){
        this.autologin = true;
        this.phone = _data
      }else{
        this.autologin = false;
      }
    }
  },
  mounted(){
    this.$nextTick(() => {
      this.wswechat  =  io("wss://ctumin.51ctu.com:8018/api_net");//线上地址
      // var  ws  =  io("ws://192.168.1.35:8018/ctu");//测试地址
      this.wswechat.on("connect",  function  (e)  {
          // console.log('链接成功'+' ' + new Date())
      });
      this.wswechat.on("disconnect",  function  (res)  {
           // $("#linkstate").text("链接断开,重新连接中...");
      });
      this.initPhone()
    })
  },
  watch:{
    '$store.state.logintagger'(n,o){
      // 记录账号--自动填入账号
      this.initPhone()
    }
  }
}
</script>

<style lang="scss">
.qqpoplogin {
  width: 800px;height:505px;position: absolute;left:calc((100% - 800px)/2);top:calc((100% - 505px)/2);margin:auto!important;
  .el-dialog__body {
    padding:0px;
  }
  .iframe {
    width: 800px;height: 450px;
  }
}
</style>
<style  lang="scss" scoped>
.register  {
  width: 100vw;height: 100vh;display: block;position: fixed;top:0px;left:0px;background: rgba(0,0,0,0.6);z-index: 999;
  .content {
    width: 460px;height: 450px;background: #fff;border-radius: 5px;position: absolute;top:calc((100vh - 450px)/2);left:calc((100vw - 460px)/2);
    .close {
      width: 35px;height: 35px;background: none;position: absolute;top:0px;right:-47px;background: #fff;border-radius: 50%;
      svg {
        width: 20px;height:20px;fill: #6f6d6e;vertical-align: -2px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .h6 {
      font-size:20px;height:100px;line-height: 75px;padding:0 0 0 45px;font-weight: normal;
      .rt {
        width: 86px;height: 86px;background: url('../../assets/img/login/towcode.jpg') 0px 0px no-repeat;float:right;border-radius: 5px;cursor: pointer;
        .sm {
          width: 103px;height: 28px;line-height: 28px;border:1px solid #e8e8e8;text-align: center;background: #fff;border-radius: 5px;margin-left:-100px;margin-top:20px;display: none;
          span {
            width: 1px;height: 10px;float:right;margin-top:9px;margin-right: -1px;position: relative;z-index: 9;background: #fff;
            &:before {
              content: '';display: block;position: absolute;width: 10px;height: 1px;background: #e8e8e8;transform: rotate(35deg);top:2px;left:0px;
            }
            &:after {
              content: '';display: block;position: absolute;width: 10px;height: 1px;background: #e8e8e8;transform: rotate(-35deg);bottom:2px;left:0px;
            }
          }
        }
        &:hover {
          opacity: 0.8;
          .sm {
            display: block;
          }
        }
      }
    }
    .ipt {
      width: 370px;height: 46px;margin:0 auto;position: relative;margin-bottom:26px;
      svg {
        position: absolute;top:14px;left:10px;width: 18px;height: 18px;fill: #d9d9d9;
      }
      input {
        width: 333px;height: 44px;border:1px solid #e9e9e9;border-radius: 5px;padding-left:35px;
        &::placeholder {
          color:#b1b1b1;
        }
      }
      .errorData {
        font-size:12px;color:#fa686e;
      }
    }
    .err {
      svg {
        fill: #fa686e;
      }
      input {
        border:1px solid #fa686e;
        &::placeholder {
          color:#fa686e;
        }
      }
    }
    .reg {
      width: 370px;height: 46px;margin:0 auto;display: block;border-radius: 5px;margin-top:25px;background: #f54e59;color:#fff;font-size: 16px;line-height: 46px;
      &:hover {
        opacity: 0.8;
      }
    }
    .autologin {
      width: 370px;margin:0 auto;zoom:1;
      &:after {
        content: '';display: block;clear: both;
      }
      .lt {
        float:left;
        input {
          vertical-align: -1px;margin-right: 3px;
        }
        label {
          color:#404040;cursor: pointer;
        }
      }
      .rt {
        float:right;
        a {
          color:#404040;
        }
      }
    }
    .other {
      height: 65px;background: #f2f2f2;position: absolute;bottom:0px;left:0px;width: 100%;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;
      .lt {
        float:left;margin-left:30px;height: 65px;
        button {
          background: none;width: 40px;height: 40px;background: #d5d5d5;border-radius: 50%;margin-top:12px;margin-right: 15px;
          svg {
            width: 30px;height: 30px;fill: #fff;vertical-align: -4px;
          }
        }
      }
      .rt {
        float:right;margin-right:30px;height: 65px;line-height: 65px;
        button {
          background: none;color:#f56077;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .content1 {
    height: 525px;top:calc((100vh - 525px)/2);
    .h6 {
      .rt {
        background: url('../../assets/img/login/pc.jpg') 0px 0px no-repeat;
      }
    }
    .codebox {
      width: 254px;height: 254px;margin:0 auto;border:1px solid #e8e8e8;border-radius: 5px;display: flex;align-items: center;justify-content: center;position: relative;
      img {
        display: block;width: 220px;height: 220px;
      }
      .loading {
        position: absolute;width: 100%;height: 100%;background: rgba(0,0,0,0.6);display: flex;justify-content: center;align-items: center;
        img {
          display: block;width: 100px;height: 100px;animation:turn 3s linear infinite;
        }
        @keyframes turn{
          0%{-webkit-transform:rotate(0deg);}
          25%{-webkit-transform:rotate(90deg);}
          50%{-webkit-transform:rotate(180deg);}
          75%{-webkit-transform:rotate(270deg);}
          100%{-webkit-transform:rotate(360deg);}
        }
      }
    }
    .btmmsg {
      p {
        font-size:14px;text-align: center;color:#404040;margin-top:15px;
        button {
          background: none;color:#397af0;margin-left:5px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      p:nth-child(2) {
        color:#b1b1b1;
        a {
          color:#f44e65;
        }
      }
    }
    .qqloginBox {
      position: relative;z-index: 9999;
      iframe {
        width: 800px;height: 400px;
      }
    }
  }
}
</style>
