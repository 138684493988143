<!-- 热销产品 -->
<template>
  <div class="cnt-hotproduct" v-if='(carouselsData&&carouselsData.length>0)||(hotPorData&&hotPorData.length>0)'>
    <h6 class="h6">热销产品</h6>
    <div class="lt">
      <carousel height='488px' :autoplay='true'  :carousels='carouselsData'></carousel>
    </div>
    <div class="rt">
      <el-row  :gutter='10' >
        <el-col :span="6" v-for='item,index in hotPorData' :key='index'>
          <div class="box" @click='links(item)'>
            <div  class="imgs">
              <img :src="item.src">
            </div>
            <h6 :title='item.name'>{{item.name}}</h6>
            <p class="price"><span>&yen;</span>{{item.price}}</p>
            <p class="people">{{item.people}}人购买</p>
          </div>
        </el-col>
      </el-row>
  <!--     <el-row  :gutter='10'>
        <el-col :span="6"  v-for='item,index in hotPorData.slice(4,hotPorData.length)'>
          <div class="box">
            <div  class="imgs">
              <img :src="item.src">
            </div>
             <h6>{{item.name}}</h6>
             <p class="price"><span>&yen;</span>{{item.price}}</p>
             <p class="people">{{item.people}}人购买</p>
          </div>
        </el-col>
      </el-row> -->
    </div>
  </div>
</template>
<script>
import carousel from '@/components/carousel/carousel.vue';//轮播组件
export default {
  name: 'hotProduct',
  props: {
    height: String,
  },
  components: {
    carousel,
  },
  data(){
    return {
      carouselsData:[
      ],
      hotPorData:[
      ]
    }
  },
  methods:{
    links(i){
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      var g = function() {
          return new Promise(function(resolve, reject) {
              // 请求轮播图
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetHotCustomizedImg?type=0',_this.keys())
                .then(res => {
                    if(res.data.Success){
                      var _data= [];
                      for(let i in res.data.Data.List){
                        var _obj = {};
                        _obj.src = _this._src + res.data.Data.List[i].ImgPath
                        _obj.title =  res.data.Data.List[i].ImgName
                        _obj.linkurl =  res.data.Data.List[i].LinkUrl
                        _data.push(_obj)
                      }
                      _this.$set(_this,'carouselsData',_data)
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      var h = function() {
          return new Promise(function(resolve, reject) {
              // 请求热销列表
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetProductHotOrCustomized?type=1&CustomerID='+CustomerID,_this.keys())
                .then(res => {
                    if(res.data.Success){
                     var _data = [];
                     for(let i in res.data.Data){
                      var _obj = {};
                      _obj.src = _this._src + res.data.Data[i].ImagePath
                      _obj.name = res.data.Data[i].AttachTypeName
                      _obj.price = res.data.Data[i].Price
                      _obj.people = res.data.Data[i].SalesVolume
                      _obj.Id = res.data.Data[i].Id
                      _data.push(_obj)
                     }
                     _this.$set(_this,'hotPorData',_data)
                     resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      var _gh = {
        g:g,
        h:h
      }
      this.$emit('pomise',_gh)
    })
  }
}
</script>
<style  lang="scss" scoped>
.cnt-hotproduct {
  zoom:1;
  .h6 {
    height: 64px;line-height: 64px;font-size: 26px;font-weight: normal;
  }
  &:after {
    display: block;content: '';clear:both;visibility: hidden;height: 0px;
  }
  .lt {
    float:left;width: 294px;height: 488px;border-radius: 10px;overflow:hidden;
    /deep/.el-carousel {
      .el-carousel__indicators {
        width: 190px;
      }
    }
  }
  .rt {
    float:left;width: 894px;height: 488px;margin-left:10px;
    .el-row  {
      // margin-bottom:10px;
      // &:last-child {
      //   margin-bottom:0px;
      // }
      .el-col {
        height: 239px;border-radius: 10px;margin-bottom:10px;
        .box {
          background: #fff;height: 239px;border-radius: 10px;
          .imgs {
            height: 170px;display: flex;justify-content: center;align-items: center;
            img {
              display: block;max-width: 150px;max-height: 150px;
            }
          }
          h6 {
            font-size:14px;color:#333333;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;padding:0 15px;text-align: center;
          }
          .price {
            color:#d43f3b;font-weight: bold;text-align: center;font-size: 14px;margin-top:6px;
          }
          .people {
            text-align:center;font-size: 12px;color:#8b8b8b;display: block;transform:scale(0.9);
          }
          &:hover {
            // opacity: 0.8;
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
            cursor: pointer;
            h6 {
              color:#d43f3b;
            }
          }
        }
      }
    }
  }
}
</style>
