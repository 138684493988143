<template>
  <div class="imghr" v-if='cntBnsShow&&cntBnsShow.length>0'>
    <div class="imghr">
      <a :href="cntBnsUrl?cntBnsUrl:'javascript:;'"><img  :title='cntBnstit'  :src="cntBns"></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'imghr',
  data(){
    return {
       cntBns:null,
      cntBnsUrl:null,//中间广告条url
      cntBnstit:null,//中间广告条urlname
      cntBnsShow:null,
    }
  },
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var k = function() {
          return new Promise(function(resolve, reject) {
              // 获取首页中间广告banner
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAdvertisingImg?type=1',_this.keys())
                .then(res => {
                    if(res.data.Success){
                      if(res.data.Data.List&&res.data.Data.List.length>0){
                        _this.cntBns = _this._src + res.data.Data.List[0].AdvertisingPath
                        _this.cntBnsUrl = res.data.Data.List[0].LinkUrl
                        _this.cntBnstit = res.data.Data.List[0].AdvertisingName
                      }
                      _this.$set(_this,'cntBnsShow',res.data.Data.List)
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      this.$emit('pomise',k)
    })
  }
}
</script>
<style  lang="scss" scoped>
.imghr {
  width: 1200px;margin:0 auto;margin-top:20px;
  a {
    img {
      display: block;border-radius: 10px;
    }
  }
}
</style>
