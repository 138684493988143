<!-- 限时秒杀 -->
<template>
  <div style="width: 100%;overflow:hidden;">
    <div class="limit-content">
      <limitKillerTop @limitKillerId='limitKillerId'></limitKillerTop>
      <limitkillerlist :idxId='idxId'  class='limitkillerlist' :arrlists='arr' :start='start'></limitkillerlist>
      <!-- <limitkillerbtm class='limitKillerbtm'></limitkillerbtm> -->
    </div>
    <div class="vhtml" v-html='RichText'></div>
    <div class="btm">
       <likes class='likes'></likes>
       <infiniteImg type='0' class='infiniteImg'></infiniteImg>
    </div>
  </div>
</template>

<script>
import likes from '@/components/likes/likes.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import limitKillerTop from '@/components/limitKillerTop/limitKillerTop.vue';//秒杀头部
import limitkillerlist from '@/components/limitkillerlist/limitkillerlist.vue';//秒杀产品列表
// import limitkillerbtm from '@/components/limitkillerbtm/limitkillerbtm.vue';//秒杀底部
export default {
  name: 'limitKiller',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
  	return {
      htmltitle:'限时秒杀-微微定',
      arr:[],
      start:false,
      RichText:'',
      idxId:null,//秒杀id
  	}
  },
  components: {
  	likes,
  	infiniteImg,
    limitKillerTop,
    limitkillerlist,
    // limitkillerbtm
  },
  methods:{
    limitKillerId(item){
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      // 获取秒杀选择日期id，请求秒杀产品数据输出
      // console.log(item)
      this.idxId = item.Id
      // console.log(new Date(item.start).getTime()>Date.now())
      // this.arr = item.id;//默认第一个秒杀项id,请求数据传入数组
      // 模拟请求的数据--传入属性arr，传入组件显示列表数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URLS+'SecKill/GetProducts?SecKillId='+item.Id+'&CustomerID='+CustomerID,this.keys())
        .then(res => {
            if(res.data.Success){
              var _data = [];
              for(let i in res.data.Data){
                var _obj = {};
                _obj.url= this._src + res.data.Data[i].ImagePath
                _obj.title = res.data.Data[i].AttachTypeName
                _obj.pirce = res.data.Data[i].Price
                _obj.oldPirce = res.data.Data[i].OriginalPrice
                _obj.all = res.data.Data[i].Stock
                _obj.out = res.data.Data[i].SalesVolume
                _obj.Id = res.data.Data[i].Id
                _data.push(_obj)
              }
              this.$set(this,'RichText',item.RichText)
              this.$set(this,'arr',_data)

              if(new Date(item.start).getTime()>Date.now()){
                // this.start = false
              }else if(new Date(item.start).getTime()<Date.now()&&new Date(item.end).getTime()>Date.now()){
                // this.start = true
              }else if(new Date(item.end).getTime()<Date.now()){
                this.start = true
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
      // var arrs = [
      //   {url:require('../assets/img/limitkillerList/1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:200,out:1},
      //   {url:require('../assets/img/limitkillerList/2.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:100,out:100},
      //   {url:require('../assets/img/limitkillerList/3.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:20,out:20},
      //   {url:require('../assets/img/limitkillerList/4.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:80,out:30},
      //   {url:require('../assets/img/limitkillerList/1.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:90,out:40},
      //   {url:require('../assets/img/limitkillerList/2.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:300,out:50},
      //   {url:require('../assets/img/limitkillerList/3.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:400,out:60},
      //   {url:require('../assets/img/limitkillerList/4.jpg'),title:'荷包纸巾定制荷包纸巾定制荷包纸巾定制',pirce:380,oldPirce:380,all:500,out:70},
      // ]
      // this.arr  = arrs;
      // new Date(item.start).getTime()>Date.now()
      // new Date(item.start).getTime()<Date.now()&&new Date(item.end).getTime()>Date.now()
      // new Date(item.end).getTime()<Date.now()
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(this.$route.query)
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style lang="scss" scoped>
.limit-content {
	width: 1200px;margin:0 auto;position: relative;
  .limitkillerlist {
    width: 1200px;overflow: hidden;margin-top:-195px;
  }
}
.vhtml {
  width: 100%;
}
.btm {
  width: 1200px;margin:0 auto;position: relative;
  .likes {
    width: 1200px;overflow: hidden; margin-top:40px;
  }
  .infiniteImg {
    margin-top:40px;
  }
  .limitKillerbtm {
    margin-left:calc((1200px -  100vw)/2);
  }
  @media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
    .limitKillerbtm {
      margin-left:calc((1200px -  1200px)/2);
    }
  }
}
</style>
