<!-- 新品首发/在线直播 -->
<template>
  <!--  -->
  <el-row  class="cnt" v-if='livelist&&carousels&&carousels.length>0&&livelist.length>0'>
    <el-col :span="12" class='lt'>
     <div class="new">
       <h6 >新品上市<button @click='linknewandnow'>更多<svg class="icon"><use xlink:href="#iconziyuan12"></use></svg></button></h6>
       <carouselCard height='320px' class='carouselCard' :loop='false' :carousels='carousels' :NewProductDto='NewProductDto'></carouselCard>
     </div>
    </el-col>
    <el-col :span="12" class='rt'>
      <div class="now">
        <h6>在线视频<button @click='linknewandnow1'>更多<svg class="icon"><use xlink:href="#iconziyuan12"></use></svg></button></button></h6>
        <div class="lts">
          <a href="javascript:;" @click='links(0)'><img :src="livePlay.VideoIMG"></a>
          <p :title='livePlay.VideoName' @click='links(0)'>
            <!-- <img src="../../assets/img/newAndNow/live.jpg"> -->
            {{livePlay.VideoName}}
          </p>
        </div>
        <div class="rts">
          <a href="javascript:;" @click='links(1)'><img :src="livePlay1.VideoIMG"></a>
          <a href="javascript:;" @click='links(2)'><img :src="livePlay2.VideoIMG"></a>
        </div>
      </div>
    </el-col>
    <div class="popplay" v-show='livePlay.lv_play'>
      <div class="box">
        <button @click.stop='donePlay(0)' class="closepop"><svg><use xlink:href="#iconshanchu"></use></svg></button>
        <video :src="livePlay.VideoPath" controls="controls" ref='video'>
        您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
    <div class="popplay" v-show='livePlay1.lv_play'>
      <div class="box">
        <button @click.stop='donePlay(1)' class="closepop"><svg><use xlink:href="#iconshanchu"></use></svg></button>
        <video :src="livePlay1.VideoPath" controls="controls" ref='video1'>
        您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
    <div class="popplay" v-show='livePlay2.lv_play'>
      <div class="box">
        <button @click.stop='donePlay(2)' class="closepop"><svg><use xlink:href="#iconshanchu"></use></svg></button>
        <video :src="livePlay2.VideoPath" controls="controls" ref='video2'>
        您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
  </el-row>
</template>
<script>
import carouselCard from '@/components/carouselCard/carouselCard.vue';//轮播组件
export default {
  name: 'newAndNow',
  props: {
    height: String,
  },
  components: {
    carouselCard,
  },
  data(){
    return {
      carousels:[
      ],
      livelist:[],//视频直播数据
      livePlay:{},//直播视频
      livePlay1:{},//直播视频
      livePlay2:{},//直播视频
      NewProductDto:null,//新品ID
    }
  },
  methods:{
    linknewandnow(){
       // this.$router.push('newpro')
       this._open({path:'/newpro.html'})
    },
    linknewandnow1(){
       // this.$router.push('liveonlin')
       this._open({path:'/liveonlin.html'})
    },
    links(v){
      // 跳转直播页
      // 打开直播
      if(v==0){
        this.$refs.video.play()
        this.livePlay.lv_play = true
      }
      if(v==1){
        this.$refs.video1.play()
        this.livePlay1.lv_play = true
      }
      if(v==2){
        this.$refs.video2.play()
        this.livePlay2.lv_play = true
      }
      // console.log(this.livePlay,this.livePlay1,this.livePlay2)
    },
    donePlay(v){
      // 关闭组件弹窗播放
      if(v==0){
        this.$refs.video.pause()
        this.livePlay.lv_play = false
      }
      if(v==1){
        this.$refs.video1.pause()
        this.livePlay1.lv_play = false
      }
      if(v==2){
        this.$refs.video2.pause()
        this.livePlay2.lv_play = false
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      var e = function() {
          return new Promise(function(resolve, reject) {
              // 请求新品首发数据
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetNewProductData?='+CustomerID,_this.keys())
                .then(res => {
                    if(res.data.Success){
                      var _data = [];
                      for(let i in res.data.Data.DzproducttypeVM){
                        var _obj = {}
                        _obj.src = _this._src + res.data.Data.DzproducttypeVM[i].ImagePath
                        _obj.title =  res.data.Data.DzproducttypeVM[i].AttachTypeName
                        _obj.price =  res.data.Data.DzproducttypeVM[i].Price
                        _obj.Id =  res.data.Data.DzproducttypeVM[i].Id
                        _obj.tip = '全新产品，最新发售'
                        _data.push(_obj)
                      }
                      _this.$set(_this,'NewProductDto',res.data.Data.NewProductDto)
                      _this.$set(_this,'carousels',_data)
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      var f = function() {
          return new Promise(function(resolve, reject) {
              // 在线直播
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetOnLinePlayData',_this.keys())
                .then(res => {
                    if(res.data.Success){
                      if(res.data.Data.List){
                        if(res.data.Data.List.length>=1){
                          res.data.Data.List[0].VideoIMG = _this._src + res.data.Data.List[0].VideoIMG
                          res.data.Data.List[0].VideoPath = _this._src + res.data.Data.List[0].VideoPath
                          res.data.Data.List[0].lv_play = false
                          _this.$set(_this,'livePlay',res.data.Data.List[0])
                        }
                        if(res.data.Data.List.length>=2){
                          res.data.Data.List[1].VideoIMG = _this._src + res.data.Data.List[1].VideoIMG
                          res.data.Data.List[1].VideoPath = _this._src + res.data.Data.List[1].VideoPath
                          res.data.Data.List[1].lv_play = false
                          _this.$set(_this,'livePlay1',res.data.Data.List[1])
                        }
                        if(res.data.Data.List.length>=3){
                          res.data.Data.List[2].VideoIMG = _this._src + res.data.Data.List[2].VideoIMG
                          res.data.Data.List[2].VideoPath = _this._src + res.data.Data.List[2].VideoPath
                          res.data.Data.List[2].lv_play = false
                          _this.$set(_this,'livePlay2',res.data.Data.List[2])
                        }
                        _this.$set(_this,'livelist', res.data.Data.List)
                      }
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      var _ef = {
        e:e,
        f:f
      }
      this.$emit('pomise',_ef)
    })
  }
}
</script>
<style type="text/css" lang="scss">
  .popplay {
    position: fixed;top:0px;left:0px;background:rgba(0,0,0,0.8);width: 100%;height: 100%;z-index: 999;
    // visibility: hidden;
    .box {
      width: 1200px;height: 600px;position: absolute;top:calc((100vh - 600px)/2);left:calc((100vw - 1200px)/2);display: flex;justify-content: center;align-items: center;
      video {
        width: 1000px;position: relative;z-index: 9999;object-fit:fill;height: 500px;
      }
      .closepop {
        position: absolute;top:0px;right:0px;width: 40px;height: 40px;background: none;
        svg {
          width: 26px;height: 26px;fill: #fff;
        }
      }
    }
  }
  // .popplay1 {
  //   visibility: visible;
  // }
</style>
<style  lang="scss" scoped>
.cnt {
  width: 1200px;margin:0 auto;overflow: hidden;
  .lt {
    .new {
      height: 400px;background: #fff;border-radius: 10px;margin-right:5px;
      h6 {
        height: 60px;line-height: 70px;padding-left:15px;font-size: 26px;font-weight: normal;
        button {
          background: none;float:right;margin-right: 15px;font-size: 16px;color:#797979;margin-top:25px;
          &:hover {
            opacity: 0.8;
          }
          svg {
            width: 12px;height: 12px;fill: #797979;
          }
        }
      }
      .carouselCard {
        margin:0px;padding:0px;width: 100%;
      }
      /deep/.el-carousel__arrow {
        border-radius: 0px;width: 30px;height: 70px;background-color:rgba(0,0,0,0.2);

        .el-icon-arrow-left:before  {
          color:#fff;font-size: 30px;
        }
        .el-icon-arrow-right:before {
          color:#fff;font-size: 30px;
        }
      }
      /deep/.el-carousel__arrow--left {
        left:0px!important;
      }
      /deep/.el-carousel__arrow--right {
        right:0px!important;
      }
      /deep/.el-carousel__indicators {
        display: none;
      }
      /deep/ .is-active  {
        img {
          // width: auto;
        }
        .info {
          display: block;
        }
      }
      /deep/.el-carousel__container {
        .imgbox {
          img {
            display: block;max-width: 95%;max-height: 95%;
          }
        }
      }
    }
  }
  .rt {
    .now {
      background: #fff;height: 400px;border-radius: 10px;margin-left:5px;
      h6 {
        height: 70px;line-height: 70px;padding-left:15px;font-size: 26px;font-weight: normal;
        button {
          background: none;float:right;margin-right: 15px;line-height: 35px;font-size: 16px;color:#797979;margin-top:20px;
          &:hover {
            opacity: 0.7;
          }
          svg {
            width: 12px;height: 12px;fill: #797979;
          }
        }
      }
      .lts {
        width: 350px;height: 310px;float:left;margin-left:15px;position: relative;
        a {
          cursor: pointer;display: block;width: 100%;height: 100%;
          img {
            display: block;width: 100%;height: 100%;
          }
        }
        p {
          background: rgba(0,0,0,0.1);cursor: pointer;
          img {
            display: inline-block;border-radius: 7px;vertical-align: -4.5px;
          }
          position: absolute;bottom:0px;left:0px;width: 300px;height: 40px;line-height: 40px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;padding:0 10px;color:#fff;font-size: 16px;padding-left:40px;
          &:after {
            content: "LIVE";display: block;width: 30px;height: 20px;line-height: 20px;border-radius: 5px;text-align: center; font-size: 12px;background: #dd2317;position: absolute;left:5px;top:10px;
          }
        }
        &:hover {
          opacity: 0.7;
        }
      }
      .rts {
        width: 175px;height: 310px;float:right;margin-right:15px;
        a {
          display: block;height: 150px;width: 170px;cursor: pointer;margin-bottom:5px;
          img {
            display: block;width: 175px;height: 155px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
