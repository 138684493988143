<!-- 限时秒杀列表 -->
<template>
  <el-row class='limitList' :gutter='20'>
    <el-col :span="6" v-for='item,index in arrlists' :key='index'>
      <div class="div">
        <div class="img">
          <img :src="item.url">
        </div>
        <h6 :title='item.title' class="h6">{{item.title}}</h6>
        <div class="price">
          <p><span>&yen;</span>{{item.pirce |formatPrice}}</p>
          <p>&yen;{{item.oldPirce|formatPrice}}</p>
        </div>
   <!--      <div class="number" v-if='start'>
          已售{{Math.floor(item.out/item.all*100*100)/100+'%'}}<span><i :style='{width:(item.all-item.out)/item.all*90+"px"}'></i></span>
        </div> -->
        <div class="number" v-if='start'>
          剩余 {{item.all}}
        </div>
        <div class="number" v-if='!start'>
          共{{item.all}}份<span><i :style='{width:"90px"}'></i></span>
        </div>
        <button v-if='type!=0' class="buy" :disabled="start"  @click='link(item)' :class='{disd:start}'>{{start?"已结束":'立即抢购'}}</button>
        <button v-if='type==0' class="buy"   @click='link(item)' >立即抢购</button>
        <!-- <button  class="buy"   @click='link(item)' >立即抢购</button> -->
      </div>
    </el-col>
  </el-row>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'limitkillerlist',
  mixins: [mixin],//混入
  props: {
    arrlists: Array,
    start:Boolean,
    idxId:null,
    type:null,
    DaySaleDto:null,//特卖ID
  },
  methods:{
    link(i){
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        if(this.type==0){
          this._open({path:'/predetail',query:{idcx:this.DaySaleDto.Id,id:id,}})
        }else{
          this._open({path:'/predetail',query:{idcx:this.idxId,id:id,}})
        }
      }})
    }
  },
  mounted(){
  	// console.log()
  }
}
</script>
<style  lang="scss" scoped>
.limitList {
  .el-col {
    margin-bottom:20px;
    .div {
      background: #fff;height: 345px;border-radius: 10px;position: relative;cursor: pointer;
      .img {
        width: 100%;height: 230px;display: flex;justify-content: center;align-items: center;
        img {
          display: block;max-width: 150px;max-height: 150px;
        }
      }
      .h6 {
        height: 40px;line-height: 40px;border-bottom:1px solid #f3f3f3;padding:0 15px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;font-weight: normal;font-size: 14px;
      }
      .price {
        zoom:1;padding-top:5px;
        &:after {
          content: '';display: block;clear:both;
        }
        p {
          float:left;color:#c9001c;font-size: 24px;margin-left:15px;
          span {
            font-size:14px;
          }
        }
        p:nth-child(2) {
          font-size: 14px;text-decoration:line-through;color:#a5a5a5;margin-top:10px;margin-left:8px;
        }
      }
      .number {
        font-size:14px;color:#a5a5a5;margin-left:15px;
        span {
          display: inline-block;width: 90px;height: 8px;border-radius: 3px;background: #e8e8e8;position: relative;margin-left:5px;
          i {
            display: block;height: 8px;position: absolute;top:0px;left: 0px;background: #bc0726;border-radius: 3px;
          }
        }
      }
      .buy {
        width: 80px;height:36px;position: absolute;bottom:20px;right:0px;text-align: center;line-height: 36px;color:#fff;background: #b90220;font-size: 14px;z-index: 9;
        &:hover {
          opacity: 0.6;
        }
      }
      .disd {
        background: #353535;
        &:hover {
          opacity: 1;cursor:no-drop;
        }
      }
    }
  }
}
</style>
