<!-- 限时秒杀头部 -->
<template>
  <div class="limitKillerTop fflimitKillerTop" ref='navtopbox'>
    <div class="navTop" ref='navtop'>
      <el-row :class='{allw:!times||times.length<1}'>
        <el-col :span="6"  :style='{width:100/times.length+"%"}' v-for='item,index in times' :class='{actives:index==sltidx}' @click.native='choice(index,item)' :key='index'>
          <h6 class="h6">
            <span class='midsp' :class='{midsps:index==sltidx}'>{{(parseInt(new Date(item.start).getMonth()) + 1)+'/'+new Date(item.start).getDate()}}</span>
            <div v-show='index==sltidx' class="div">
              <p><span>{{item.start.split(" ")[1]}}</span>
                <!-- <span>{{new Date(item.start).getTime()>Date.now()?"即将开始":'正在秒杀'}}</span> -->
                <span v-if='new Date(item.start).getTime()>Date.now()'>即将开始</span>
                <span v-if='new Date(item.start).getTime()<Date.now()&&new Date(item.end).getTime()>Date.now()'>正在秒杀</span>
                <!-- <span v-if='new Date(item.end).getTime()<Date.now()'>已经结束</span> -->
              </p>
              <p>
                <!-- <span>{{new Date(item.start).getTime()>Date.now()?"距开始":'距结束'}}</span>
                <span >{{new Date(item.start).getTime()>Date.now()?startTms[index]:endTms[index]}}</span> -->
                <span v-if='new Date(item.start).getTime()>Date.now()'>距开始 {{startTms[index]}}</span>
                <span v-if='new Date(item.start).getTime()<Date.now()&&new Date(item.end).getTime()>Date.now()'>距结束 {{endTms[index]}}</span>
                <span v-if='new Date(item.end).getTime()<Date.now()'>已结束</span>
              </p>
            </div>
           <!--  <span class="span" v-show='index!=sltidx'>
              {{new Date(item.start).getTime()>Date.now()?"即将开始":'正在秒杀'}}
            </span> -->
            <span class="span" v-show='index!=sltidx' v-if='new Date(item.start).getTime()>Date.now()'>即将开始</span>
            <span class="span" v-show='index!=sltidx' v-if='new Date(item.start).getTime()<Date.now()&&new Date(item.end).getTime()>Date.now()'>正在秒杀</span>
            <span class="span" v-show='index!=sltidx' v-if='new Date(item.end).getTime()<Date.now()'>已结束</span>
          </h6>
        </el-col>
      </el-row>
    </div>
    <img v-if='times&&times.length>0' class="bgimgUrl" :src="times[sltidx].BackGroundIMG">
  </div>
</template>
<script>
export default {
  name: 'limitKillerTop',
  props: {
    height: String,
    carousels:Array,
  },
  data(){
    return {
      times:[
        // {start:'2020-04-14 15:00',end:'2020-04-14 16:00',},
        // {start:'2020-04-23 15:00',end:'2020-04-23 18:00',},
        // {start:'2020-07-14 15:00',end:'2020-07-14 16:00',},
        // {start:'2020-07-14 15:00',end:'2020-07-14 16:00',},
      ],
      indexs:null,//秒杀数组首个没有结束或开始的秒杀
      sltidx:0,//秒杀分类选择索引
      endTms:[],//结束时间
      startTms:[],//开始时间
      t:0,//计时器变量
      bgimgUrl:null,
    }
  },
  methods:{
    startTime(v,index){
      var _this = this;

      // 距离开始
      var nowTime = Date.now();
      var edTime = new Date(v).getTime();
      var leftTime = edTime - nowTime; //时间差
      var  d,h, m, s;
      if(leftTime >= 0) {
          d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
          h = Math.floor(leftTime / 1000 / 60 / 60%24);
          m = Math.floor(leftTime / 1000 / 60 % 60);
          s = Math.floor(leftTime / 1000 % 60);
          if(s < 10) {
              s = "0" + s;
          }
          if(m < 10) {
              m = "0" + m;
          }
          if(h < 10) {
              h = "0" + h;
          }
          if(d>0){
            _this.$set( _this.startTms, index, d+'天'+h+':'+m+':'+s )
          }else{
            _this.$set( _this.startTms, index, h+':'+m+':'+s )
          }
      }
    },
    endTime(v,index){
      var _this = this;
      // 距离结束
      var nowTime = Date.now();
      var edTime = new Date(v).getTime();
      var leftTime = edTime - nowTime; //时间差
      var  d,h, m, s;
      if(leftTime >= 0) {
          d = Math.floor(leftTime / 1000 / 60 / 60 /24);
          h = Math.floor(leftTime / 1000 / 60 / 60%24);
          m = Math.floor(leftTime / 1000 / 60 % 60);
          s = Math.floor(leftTime / 1000 % 60);
          if(s < 10) {
              s = "0" + s;
          }
          if(m < 10) {
              m = "0" + m;
          }
          if(h < 10) {
              h = "0" + h;
          }
          if(d>0){
            _this.$set( _this.endTms, index, d+'天'+h+':'+m+':'+s )
          }else{
            _this.$set( _this.endTms, index, h+':'+m+':'+s )
          }
      }
    },
    choice(index,item){
      var _obj1 = {
        SeoTitle:item.SeoTitle,
        SeoKey:item.SeoKey,
        SeoDescrition:item.SeoDescrition,
      }
      window.sessionStorage.setItem('localseo',JSON.stringify(_obj1))
      if(_obj1.SeoTitle){
        document.title = _obj1.SeoTitle
      }
      if(_obj1.SeoDescrition){
        $('meta[name=description]').attr('content',_obj1.SeoDescrition)
      }
      if(_obj1.SeoKey){
        $('meta[name=Keywords]').attr('content',_obj1.SeoKey)
      }
      // 选择秒杀日期
      this.sltidx =index
      // console.log(item)
      this.$emit('limitKillerId',item)
    },
    getNowStart(){
      // 遍历数据传出第一个即将开始或者已经开始索引
      // console.log(this.times)
      for(var i in this.times){
        // console.log(new Date(this.times[i].start).getTime(),new Date(this.times[i].end).getTime())
        if(Date.now()<new Date(this.times[i].start).getTime()){
          this.startTime(this.times[i].end,0);//取出没有开始或结束的首个秒杀索引---然后默认第一个距离开始
          return i;
        }else if(Date.now()>new Date(this.times[i].start).getTime()&&Date.now()<new Date(this.times[i].end).getTime()){
          this.endTime(this.times[i].end,0);//取出没有开始或结束的首个秒杀索引---然后默认第一个距离结束
          return i;
        }
      }
    },
    initGet(params){
      // 初始化请求
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URLS+'SecKill/GetSeckillData',this.keys())
        .then(res => {
            if(res.data.Success){
              var _data = [];
              for(let i in res.data.Data.List){
                var _obj = {};
                _obj.start=res.data.Data.List[i].StartDate.split('T')[0]+' '+res.data.Data.List[i].StartDate.split('T')[1]
                _obj.end=res.data.Data.List[i].EndDate.split('T')[0] + ' '+res.data.Data.List[i].EndDate.split('T')[1]
                _obj.Id=res.data.Data.List[i].Id
                _obj.RichText=res.data.Data.List[i].RichText
                _obj.BackGroundIMG=this._src + res.data.Data.List[i].BackGroundIMG
                _obj.SeoTitle = res.data.Data.List[i].SeoTitle
                _obj.SeoKey = res.data.Data.List[i].SeoKey
                _obj.SeoDescrition = res.data.Data.List[i].SeoDescrition
                _data.push(_obj)
              }
              var _obj1 = {
                SeoTitle:_data[0].SeoTitle,
                SeoKey:_data[0].SeoKey,
                SeoDescrition:_data[0].SeoDescrition,
              }
              window.sessionStorage.setItem('localseo',JSON.stringify(_obj1))
              if(_obj1.SeoTitle){
                document.title = _obj1.SeoTitle
              }
              if(_obj1.SeoDescrition){
                $('meta[name=description]').attr('content',_obj1.SeoDescrition)
              }
              if(_obj1.SeoKey){
                $('meta[name=Keywords]').attr('content',_obj1.SeoKey)
              }
              // console.log(_data)
              // this.bgimgUrl = this._src + res.data.Data.List[0].BackGroundIMG
              this.$set(this,'times',_data)
              if(params.fn){
                params.fn()
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    localReset(){
      var _this = this;
      this.initGet({fn:()=>{
        if(!this.times||this.times.length<1){
          return;
        }
        // 头部倒计时标题
        // console.log(this.times)
        // this.sltidx = this.getNowStart()?this.getNowStart():0
        for(let i in this.times){
          if(this.times[i].Id==this._spf(this.$route.query.idxId)){
            this.sltidx = i
          }
        }
        this.$emit('limitKillerId',this.times[this.sltidx]);//首先传出默认第一个秒杀项id
        this.t = setInterval(()=>{
          for(let i in _this.times){//对每一个秒杀进行倒计时---对应距离开始和距离结束
            (function(i){
              _this.startTime(_this.times[i].start,i)
              _this.endTime(_this.times[i].end,i)
            })(i)
          }
        },1000)
        // end
      }})
      $(window).scroll(()=>{
        if(parseInt($(document).scrollTop())>parseInt($(this.$refs.navtopbox).offset().top)){
          $(this.$refs.navtop).css({
            position:'fixed',
            top:'0px',
            left:'calc((100vw - 1200px)/2)',
            "z-index":'99'
          })
        }else{
          $(this.$refs.navtop).css({
            position:'relative',
            top:'auto',
            left:'auto'
          })
        }
      })
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      this.localReset()
    })
  },
  watch:{
  },
  destroyed(){
    // 组件销毁清空定时变量
    clearInterval(this.t)
  }
}
</script>
<style  lang="scss" scoped>
.limitKillerTop {
  width: 100vw;margin-left:calc((1200px - 100vw)/2);overflow: hidden;
  .bgimgUrl {
    margin-top:-60px;margin-left:calc((100vw - 1920px)/2);min-height: 1110px;
  }
  // background: url('../../assets/img/limitKillerTop/bg.png') center center no-repeat;
  .navTop {
    width: 1200px;margin:0 auto;height: 60px;position: relative;
    &:after,&:before {
      content: '';display: block;position: absolute;width: calc((100vw - 1200px)/2);height: 60px;background: rgba(0,0,0,0.9);top:0px;
    }
    &:before {
      left:calc((1200px - 100vw)/2);
    }
    &:after {
      right:calc((1200px - 100vw)/2);
    }
    .el-row {
      .el-col {
        width: 20%;text-align: center;height: 60px;background:  rgba(0,0,0,0.9);line-height: 60px;color:#fff;cursor: pointer;
        .h6 {
          font-size: 18px;height: 60px;line-height: 60px;
          .span {
            border:1px solid #fff;border-radius: 20px;padding:3px 10px;margin-left:10px;
          }
          .midsp {
            font-size: 18px;
          }
          .midsps {
            vertical-align: 20px;font-size: 26px;
          }
          .div {
            display: inline-block;margin-left:15px;vertical-align:13px;
            p {
               font-size: 14px;height: 22px;line-height: 22px;font-weight: normal;
              span:nth-child(1) {
                margin-right: 5px;
              }
            }
          }
        }
      }
      .actives {
        background:  rgba(0,0,0,0.4);
      }
    }
    .allw {
      width: 100%;background:  rgba(0,0,0,0.9);height: 60px;
    }
  }
}
@-moz-document url-prefix() {
  .fflimitKillerTop {
    width: calc(100vw - 8px)!important;
  }
}
@media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
  .limitKillerTop {
    width: 1200px;margin-left:calc(( 1200px - 1200px)/2);
  }
  @-moz-document url-prefix() {
    .fflimitKillerTop {
      width: 1200px!important;margin-left:calc(( 1200px - 1200px)/2)!important;
    }
  }
}
</style>
