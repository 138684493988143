import { render, staticRenderFns } from "./newAndNow.vue?vue&type=template&id=5aca30b7&scoped=true&"
import script from "./newAndNow.vue?vue&type=script&lang=js&"
export * from "./newAndNow.vue?vue&type=script&lang=js&"
import style0 from "./newAndNow.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./newAndNow.vue?vue&type=style&index=1&id=5aca30b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aca30b7",
  null
  
)

export default component.exports