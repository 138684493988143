<!-- 每日特卖 -->
<template>
  <div class='carBox' v-if='carouselsbox&&carouselsbox.length>0'>
    <h5 >每日特卖<button class="morelist" @click='linksuperSell'>更多<svg class="icon"><use xlink:href="#iconziyuan12"></use></svg></button></h5>
    <div  class="carsoulBox" :style='{height:height}'>
      <button @click='back' class="back"><svg class="icon"><use xlink:href="#iconziyuan2"></use></svg></button>
      <button @click='next' class="next"><svg class="icon"><use xlink:href="#iconziyuan4"></use></svg></button>
      <div class="listBox" :style='{height:height}'>
        <div class="itemBox" :style='{width:carousels.length*245+"px",left:left+"px"}'>
          <div class="imgbox" v-for="i,index in carousels" :key="index" @click='linkpredetail(i,DaySaleDto.Id)'>
            <div class="imgs">
              <img :src="i.src">
            </div>
            <h6 :title='i.name'>{{i.name}}</h6>
            <div class="price">
              <p><span><i>&yen;</i>{{parseInt(i.price)}}</span></p>
              <p><span><i>&yen;</i>{{parseInt(i.oldP)}}</span></p>
            </div>
            <svg v-show='i.hot==1' class="icon hot"><use xlink:href="#iconziyuan6"></use></svg>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-carousel  :autoplay="false" arrow="hover" :loop='false' :height='height'>
      <el-carousel-item v-for="item,index in carousels" :key="index">
        <div class="box">
          <div class="imgbox" v-for='i,n in item.arr' :key='n' @click='linkpredetail(i,DaySaleDto.Id)'>
            <div class="imgs">
              <img :src="i.src">
            </div>
            <h6 :title='i.name'>{{i.name}}</h6>
            <div class="price">
              <p><span><i>&yen;</i>{{parseInt(i.price)}}</span></p>
              <p><span><i>&yen;</i>{{parseInt(i.oldP)}}</span></p>
            </div>
            <svg v-show='i.hot==1' class="icon hot"><use xlink:href="#iconziyuan6"></use></svg>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel> -->
  </div>
</template>
<script>
export default {
  name: 'everyDay',
  props: {
    height: String,
  },
  data(){
    return {
      left:0,
      carousels:[
      ],
      carouselsbox:null,
      DaySaleDto:null,//特卖类型
    }
  },
  methods:{
    back(){
      // 后退
      if(this.left<0){
        this.left +=600
        if(this.left>0){
          this.left = 0
        }
      }
    },
    next(){
      // 前进
      if(this.left>-(this.carousels.length*245-1200)){
        this.left-=600
        if(this.left<-(this.carousels.length*245-1200)){
          this.left=-(this.carousels. length*245-1223)
        }
      }
    },
    linksuperSell(){
      // this.$router.push('super')
      this._open({path:'/super.html'})
    },
    linkpredetail(i,Id){
      // 跳转产品详情
      // console.log(i)
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:Id,id:id,}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this =this;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      var d = function() {
          return new Promise(function(resolve, reject) {
              // 请求每日特卖数据
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetDaySaleData?CustomerId='+CustomerID,_this.keys())
                .then(res => {
                    if(res.data.Success){
                      var _data = [];
                      var _obj = {};
                          _obj.arr = [];
                      if(res.data.Data.DzproducttypeVM.length<5){
                        for(let i in res.data.Data.DzproducttypeVM){
                          _obj.arr.push({src:_this._src+res.data.Data.DzproducttypeVM[i].ImagePath,name:res.data.Data.DzproducttypeVM[i].AttachTypeName,price:res.data.Data.DzproducttypeVM[i].Price,oldP:res.data.Data.DzproducttypeVM[i].OriginalPrice,Id:res.data.Data.DzproducttypeVM[i].Id,hot:res.data.Data.DzproducttypeVM[i].IsHot});
                        }
                        _data.push(_obj)
                      }else{
                        for(let i in res.data.Data.DzproducttypeVM){
                          _obj.arr.push({src:_this._src+res.data.Data.DzproducttypeVM[i].ImagePath,name:res.data.Data.DzproducttypeVM[i].AttachTypeName,price:res.data.Data.DzproducttypeVM[i].Price,oldP:res.data.Data.DzproducttypeVM[i].OriginalPrice,Id:res.data.Data.DzproducttypeVM[i].Id,hot:res.data.Data.DzproducttypeVM[i].IsHot});
                          if(i>0&&(parseInt(i)+1)%5==0){
                            _data.push(_obj)
                            _obj = {};
                            _obj.arr = [];
                          }
                        }
                        if(res.data.Data.DzproducttypeVM.length%5!=0){
                          _data.push(_obj)
                        }
                      }
                      var _datas = [];
                      for(let i in _data){
                        _datas = _datas.concat(_data[i].arr)
                      }
                      _this.$set(_this,'carousels',_datas)
                      _this.$set(_this,'DaySaleDto',res.data.Data.DaySaleDto)
                      _this.$set(_this,'carouselsbox',res.data.Data.DzproducttypeVM)
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      this.$emit('pomise',d)
    })
  }
}
</script>
<style  lang="scss" scoped>
// 轮播插件
// .box {
//   .imgbox {
//     width: 218px;height: 260px;float:left;background: #fff;margin-right: 27.5px;border-radius: 10px;position: relative;
//     &:last-child {
//       margin-right: 0px;
//     }
//     .hot {
//       position: absolute;top:10px;right:10px;width: 30px;height: 30px;
//     }
//     .imgs {
//       width: 100%;height: 184px;display: flex;justify-content: center;align-items: center;margin:0px;padding:0px;
//       img {
//         display: block;max-width: 150px;max-height: 150px;
//       }
//     }
//     h6 {
//       font-size: 14px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding:0 20px;height: 40px;line-height: 40px;text-align: center;

//     }
//     .price {
//       height: 34px;width: 216px;border:1px solid #e1251a;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;overflow: hidden;
//       p {
//         float:left;
//         &:nth-child(1) {
//           width: 128px;background: #e1251a;height: 34px;line-height:34px;text-align: center;color:#fff;
//           span {
//             font-size: 26px;
//             i {
//               font-style:normal;font-size: 14px;margin-right: 2px;
//             }
//           }
//         }
//         &:nth-child(2) {
//           width: 88px;height: 34px;line-height:34px;text-align: center;color:#bebebe;position: relative;
//           span {
//             font-size: 18px;text-decoration:line-through;position: relative;z-index: 9;
//             i {
//               font-style:normal;font-size: 18px;margin-right: 2px;
//             }
//           }
//           &:after {
//             content: '';display: block;position: absolute;width: 40px;height: 50px;background: #fff;top:0px;left:-8px;transform:rotate(20deg);
//           }
//         }
//       }
//     }
//     &:hover {
//       // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
//       opacity: 0.7;
//       // box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
//       cursor: pointer;
//       h6 {
//         color:red;
//       }
//     }
//   }
// }
// 轮播插件
.carBox {
  h5  {
    height: 64px;line-height: 64px;font-size: 26px;font-weight: normal;
    .morelist {
      float:right;background: none;font-size: 16px;line-height: 75px;color:#797979;
      &:hover {
        opacity: 0.8;
      }
      svg {
        fill: #797979;width: 12px;height: 12px;
      }
    }
  }
  // 轮播插件
  // /deep/.el-carousel {
  //   width: 100%;
  // }
  // /deep/.el-carousel__arrow {
  //   border-radius: 0px;width: 30px;height: 70px;background-color:rgba(0,0,0,0.3);
  //   .el-icon-arrow-left:before  {
  //     color:#fff;font-size: 30px;
  //   }
  //   .el-icon-arrow-right:before {
  //     color:#fff;font-size: 30px;
  //   }
  // }
  // /deep/.el-carousel__indicators {
  //   display: none;
  // }
  // 轮播插件
  .carsoulBox {
    position: relative;width: 1200px;
    .listBox {
      width: 1200px;overflow: hidden;position: absolute;
      .itemBox {
        position: absolute;left:0px;transition: left 0.5s;
        .imgbox {
          width: 218px;height: 260px;float:left;background: #fff;margin-right: 27.5px;border-radius: 10px;position: relative;
          &:last-child {
            margin-right: 0px;
          }
          .hot {
            position: absolute;top:10px;right:10px;width: 30px;height: 30px;
          }
          .imgs {
            width: 100%;height: 184px;display: flex;justify-content: center;align-items: center;margin:0px;padding:0px;
            img {
              display: block;max-width: 150px;max-height: 150px;
            }
          }
          h6 {
            font-size: 14px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding:0 20px;height: 40px;line-height: 40px;text-align: center;

          }
          .price {
            height: 34px;width: 216px;border:1px solid #e1251a;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;overflow: hidden;
            p {
              float:left;
              &:nth-child(1) {
                width: 128px;background: #e1251a;height: 34px;line-height:34px;text-align: center;color:#fff;
                span {
                  font-size: 26px;
                  i {
                    font-style:normal;font-size: 14px;margin-right: 2px;
                  }
                }
              }
              &:nth-child(2) {
                width: 88px;height: 34px;line-height:34px;text-align: center;color:#bebebe;position: relative;
                span {
                  font-size: 18px;text-decoration:line-through;position: relative;z-index: 9;
                  i {
                    font-style:normal;font-size: 18px;margin-right: 2px;
                  }
                }
                &:after {
                  content: '';display: block;position: absolute;width: 40px;height: 50px;background: #fff;top:0px;left:-8px;transform:rotate(20deg);
                }
              }
            }
          }
          &:hover {
            // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
            opacity: 0.7;
            // box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
            cursor: pointer;
            h6 {
              color:red;
            }
          }
        }
      }
    }
    .back,.next {
      position: absolute;top:90px;width: 30px;height: 70px;line-height: 70px;background:rgba(0,0,0,0.3);z-index: 999;visibility: hidden;
      svg {
        width:20px;height: 20px;vertical-align: -5px;fill: #fff;
      }
    }
    .back {
      left:0px;
    }
    .next {
      right:0px;
    }
    &:hover {
      .back,.next {
        visibility: visible;
      }
    }
  }
}
</style>
