<!-- 右侧浮动 -->
<template>
  <div class="cnt">
    <button class="gouwu" @click='linkCar'>
      <svg class="icon"><use xlink:href="#icongouwucheman"></use></svg>
      <p>购物车<span class="tip">{{this.$store.state.buycarum}}</span></p>
    </button>
    <button class="kefu" @click='lianxikefu'>
      <svg class="icon"><use xlink:href="#iconkefu"></use></svg>
      <p>客服</p>
    </button>
    <button v-show='showTop' class="top" @click='backTop'>
      <svg class="icon"><use xlink:href="#iconziyuan7"></use></svg>
      <p>顶部</p>
    </button>
  </div>
</template>
<script>
export default {
  name: 'positionRt',
  props: {
    height: String,
    carousels:Array
  },
  data(){
    return {
      showTop:false,
    }
  },
  methods:{
    backTop(){
      $("body,html").animate({
          scrollTop: 0
        },1000);
    },
    linkCar(){
      // 跳转购物车
      this._router({exp:false,catch:()=>{
        // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
        this._open({path: "/accountlogin",query: {redirect:'/buycar.html'}})
      },then:()=>{
        // this.$router.push('buycar')
        this._open({path: "/buycar.html"})
      }})
    },
    lianxikefu(){
      // 联系客服
      _MEIQIA('showPanel');
    }
  },
  mounted(){
    this.$nextTick(()=>{
      $(window).scroll(()=>{
        if($(document).scrollTop()>600){
          this.showTop = true
        }else{
          this.showTop = false
        }
      })
    })
  }
}
</script>
<style  lang="scss" scoped>
.cnt {
  width: 50px;position: fixed;bottom: 200px;right:20px;background: #fb7474;z-index: 99;
  button {
    height: 50px;width: 50px;margin:5px 0px;background: none;
    svg {
      fill: #fff;width: 22px;height: 22px;
    }
    p {
      font-size: 12px;color:#fff;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .gouwu {
    position: relative;
    .tip {
      position: absolute;top:-3px;right:3px;background: #fff;display: block;width: 18px;height: 18px;border-radius: 50%;font-size: 12px;color:#fb7474;text-align: center;line-height: 18px;border:1px solid #f65c10;
    }
  }
}
</style>
