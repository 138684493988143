<!-- 精品推荐 -->
<template>
  <div class="cnt-choiceRec" v-if='(carouselsData&&carouselsData.length>0)||(hotPorData&&hotPorData.length>0)'>
    <h6 class="h6">
      精品推荐
      <ul>
        <li @click='choice(index)' :class='{choices:classidx==index}' v-for='item,index in classs' :key='index'>
          <span>{{item.name}}</span>
        </li>
      </ul>
    </h6>
    <div class="lt">
      <carousel height='488px' :autoplay='true' :carousels='carouselsData'></carousel>
    </div>
    <div class="rt">
      <el-row  :gutter='10' >
        <el-col :span="6" v-for='item,index in hotPorData' :key='index'>
          <div class="box" @click='links(item)'>
            <div  class="imgs">
              <img :src="item.src">
            </div>
            <h6 :title='item.name'>{{item.name}}</h6>
            <p class="price"><span>&yen;</span>{{item.price}}</p>
            <p class="people">{{item.people}}人购买</p>
          </div>
        </el-col>
      </el-row>
     <!--  <el-row  :gutter='10'>
        <el-col :span="6"  v-for='item,index in hotPorData.slice(4,hotPorData.length)'>
          <div class="box">
            <div  class="imgs">
              <img :src="item.src">
            </div>
             <h6>{{item.name}}</h6>
             <p class="price"><span>&yen;</span>{{item.price}}</p>
             <p class="people">{{item.people}}人购买</p>
          </div>
        </el-col>
      </el-row> -->
    </div>
  </div>
</template>
<script>
import carousel from '@/components/carousel/carousel.vue';//轮播组件
export default {
  name: 'choiceRec',
  props: {
    height: String,
  },
  components: {
    carousel,
  },
  data(){
    return {
      carouselsData:[
      ],
      hotPorData:[
      ],
      hotPorDataAll:[

      ],
      classs:[
      ],
      classidx:0,
    }
  },
  methods:{
    choice(index){
      this.classidx = index
      for(let i in this.hotPorDataAll){
        if(this.classs[index].Id==this.hotPorDataAll[i].Id){
          this.$set(this,'hotPorData',this.hotPorDataAll[i].arr)
        }
      }
    },
    links(i){
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _this = this;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      var i = function() {
          return new Promise(function(resolve, reject) {
              // 请求轮播图
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetHotCustomizedImg?type=1',_this.keys())
                .then(res => {
                    if(res.data.Success){
                      var _data= [];
                      for(let i in res.data.Data.List){
                        var _obj = {};
                        _obj.src = _this._src + res.data.Data.List[i].ImgPath
                        _obj.linkurl = res.data.Data.List[i].LinkUrl
                        _obj.title = res.data.Data.List[i].ImgName
                        _data.push(_obj)
                      }
                      _this.$set(_this,'carouselsData',_data)
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      var j = function() {
          return new Promise(function(resolve, reject) {
              // 请求精品推荐列表
              _this._axios({then:()=>{
                _this.axios.post(process.env.VUE_APP_URLS+'Home/GetProductHotOrCustomized?type=0&CustomerID='+CustomerID,_this.keys())
                .then(res => {
                    if(res.data.Success){
                      var _data = [];
                      var _data1 = [];
                      for(let i in res.data.Data){
                        if(res.data.Data[i].ParentID==0){
                          var _obj1 = {};
                          _obj1.name = res.data.Data[i].TypeName
                          _obj1.Id = res.data.Data[i].Id
                          _data1.push(_obj1)
                        }
                      }
                      for(let j in _data1){
                        var _obj = {};
                        _obj.arr = [];
                        _obj.Id = _data1[j].Id
                        for(let i in res.data.Data){
                          if(_data1[j].Id==res.data.Data[i].ParentID){
                            var _objs = {};
                            _objs.src = _this._src + res.data.Data[i].ImagePath
                            _objs.name = res.data.Data[i].AttachTypeName
                            _objs.price = res.data.Data[i].Price
                            _objs.people = res.data.Data[i].SalesVolume
                            _objs.Id = res.data.Data[i].Id
                            _objs.ParentID = res.data.Data[i].ParentID
                            _obj.arr.push(_objs)
                          }
                        }
                        _data.push(_obj)
                      }
                      // console.log(_data)
                      for(let i in _data){
                        if(_data1[_this.classidx].Id==_data[i].Id){
                          _this.$set(_this,'hotPorData',_data[i].arr)
                        }
                      }
                      _this.$set(_this,'hotPorDataAll',_data)
                      _this.$set(_this,'classs',_data1)
                      resolve()
                    }else{
                      resolve()
                      _this.$MessageBox.alert(res.data.Error,{type:'error'});
                    }
                  }, res => {
                    resolve()
                    // this.$MessageBox.alert('系统异常',{type:'error'});
                  })
              }})
              // end
          })
      }
      var _ij = {
        i:i,
        j:j
      }
      this.$emit('pomise',_ij)
    })
  }
}
</script>
<style  lang="scss" scoped>
.cnt-choiceRec {
  zoom:1;
  .h6 {
    height: 64px;line-height: 64px;font-size: 26px;font-weight: normal;
    ul {
      float:right;margin-right:20px;
      li {
        float:left;color:#575757;font-size: 14px;cursor: pointer;position: relative;width: 70px;text-align: center;height: 30px;line-height: 30px;padding:2px 15px!important;border-radius: 20px;text-align: center;margin:0 20px;margin-top:15px;
        span {
          display: block;width: 70px;overflow: hidden;height: 30px;line-height: 30px;text-align: center;
        }
        &:hover {
          background: #ff8487;color:#fff;
        }
      }
      .choices {
        background: #ff8487;color:#fff;
      }
    }
  }
  &:after {
    display: block;content: '';clear:both;visibility: hidden;height: 0px;
  }
  .lt {
    float:left;width: 294px;height: 488px;border-radius: 10px;overflow:hidden;
    /deep/.el-carousel {
      .el-carousel__indicators {
        width: 190px;
      }
    }
  }
  .rt {
    float:right;width: 894px;height: 488px;float:left;margin-left:10px;
    .el-row  {
      // margin-bottom:10px;
      // &:last-child {
      //   margin-bottom:0px;
      // }
      .el-col {
        height: 239px;border-radius: 10px; margin-bottom:10px;
        .box {
          background: #fff;height: 239px;border-radius: 10px;
          .imgs {
            height: 170px;display: flex;justify-content: center;align-items: center;
            img {
              display: block;max-width:150px;max-height: 150px;
            }
          }
          h6 {
            font-size:14px;color:#333333;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;padding:0 15px;text-align: center;
          }
          .price {
            color:#d43f3b;font-weight: bold;text-align: center;font-size: 14px;margin-top:6px;
          }
          .people {
            text-align:center;font-size: 12px;color:#8b8b8b;display: block;transform:scale(0.9);
          }
          &:hover {
            // opacity: 0.8;
            cursor: pointer;
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
            h6 {
              color:#d43f3b;
            }
          }
        }
      }
    }
  }
}
</style>
