<!-- 轮播 -->
<template>
  <el-carousel :autoplay="autoplay" :loop='loop' :arrow="arrow" :height='height'>
    <el-carousel-item v-for="item,index in carousels" :key="index">
      <img :title='item.title?item.title:""' :src='item.src' @click='links(item)'>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: 'carousel',
  props: {
    height: String,
    carousels:Array,
    autoplay:{
      type: Boolean,
      default: false
    },
    arrow:{
      default:'hover',
      type:String
    },
    loop:{
      default:true,
      type:Boolean
    }
  },
  data(){
    return {
    }
  },
  methods:{
    links(item){
      // 跳转
      // 切换栏目
      if(item.linkurl){
        window.open(item.linkurl)
      }else{
        // this.choiceI = index
        // this._router({then:()=>{
        //   this.$router.push({query:{linkes:index}})
        // }})
      }
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.el-carousel {
  img {
    cursor: pointer;
  }
}
</style>
