<!-- 带热门搜索组件 -->
<template>
  <div class="hotsearch" :class='{type:type==1}'>
    <h6  v-if='type==1'>全部搜索</h6>
    <!-- <input type="password" style="position: fixed;z-index:-9999;background: none;"> -->
    <input @focus='focus' @blur='blur' class="ipt" type="text" v-model='searchTxt' @keyup.enter='keyup'>
    <button @click='searchclick' class="sh"><svg class="icon"><use xlink:href="#iconziyuan13"></use></svg></button>
    <div v-show='hotShow' class="hots" v-if='hotSearch&&hotSearch.length>0'>
      <button @click='searchKeys(item)' v-for='item in hotSearch'>{{item}}</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'hotsearch',
  data(){
    return {
      hotSearch:null,
      // hotSearch:[],
      hotShow:true,
      searchTxt:null,
    }
  },
  props: {
    msg: String,
    type:{
      default:0,
      type:Number
    }
  },
  methods:{
    keyup(){
      // 键盘收入
      this.searchclick()
    },
    searchKeys(i){
      // 热门搜索
      this.searchTxt = i?i:''
      this.searchclick()
    },
    searchclick(){
      // 搜索跳转
      this.$router.push({path:this.$route.path,query:{keys:'.html'}})
      this.searchTxt = this.searchTxt?this.searchTxt:''
      this.$store.dispatch('cmtsearchClick',this.$store.state.searchClick+1)
      this.$store.dispatch('cmtsearchTxt',this.searchTxt?this.searchTxt:'')
      if(this.searchTxt){
        var _searchQueryObj = {
          key:this.searchTxt,
          orderBy:0,
          OrderT:1,
          parentId:'',
          startPrice:0,
          endPrice:0,
          index:1
        }
        window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
      }else{
         // var _searchQueryObj = {
         //    key:'',
         //    orderBy:0,
         //    OrderT:1,
         //    parentId:'',
         //    startPrice:0,
         //    endPrice:0,
         //    index:1
         //  }
         window.sessionStorage.removeItem('searchQuery')
      }
      // window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
      // if(this.$route.path!='/classlist'){

      //   this._router({exp:false,catch:()=>{
      //     // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
      //     this._open({path: "/accountlogin",query: {redirect:'/'}})
      //   },then:()=>{
      //     if(this.searchTxt){
      //       var _searchQueryObj = {
      //         key:this.searchTxt,
      //         orderBy:0,
      //         OrderT:1,
      //         parentId:'',
      //         startPrice:0,
      //         endPrice:0,
      //         index:1
      //       }
      //     }else{
      //        var _searchQueryObj = {
      //           key:'',
      //           orderBy:0,
      //           OrderT:1,
      //           parentId:'',
      //           startPrice:0,
      //           endPrice:0,
      //           index:1
      //         }
      //     }
      //     window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
      //     // this._open({path: "/buycar"})
      //   }})
      // }
      if(this.searchTxt){
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetSearchSEO?SearchKey='+this.searchTxt,this.keys())
        .then(res => {
          if(res.data.Data){
            var _obj = {
              SeoTitle:res.data.Data.SeoTitle,
              SeoKey:res.data.Data.SeoKey,
              SeoDescrition:res.data.Data.SeoDescrition
            }
            window.sessionStorage.setItem('classlistseo',JSON.stringify(_obj))
            window.sessionStorage.setItem('localseo',window.sessionStorage.getItem('classlistseo'))
          }
         this._open({path:'/classlist',query:{keys:this.searchTxt+'.html'}})
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }else{
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetSearchSEO?SearchKey=',this.keys())
        .then(res => {
          if(res.data.Data){
            var _obj = {
              SeoTitle:res.data.Data.SeoTitle,
              SeoKey:res.data.Data.SeoKey,
              SeoDescrition:res.data.Data.SeoDescrition
            }
            window.sessionStorage.setItem('classlistseo',JSON.stringify(_obj))
            window.sessionStorage.setItem('localseo',window.sessionStorage.getItem('classlistseo'))
          }
          this._open({path:'/classlist',query:{keys:this.searchTxt+'.html'}})
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }
      // this.$router.push({name:'classlist',query:{keys:this.searchTxt}})
    },
    focus(){
      // 搜索框聚焦
      this.hotShow = false;
    },
    blur(){
      // 搜索框失去焦点
      if(!this.searchTxt){
        this.hotShow = true;
      }
    },
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(this.$store.state.searchTxt)
      // this.searchTxt = this.$store.state.searchTxt
      // this._axios({then:()=>{
      //   this.axios.post(process.env.VUE_APP_URL+'Product/initList',this.keys())
      //   .then(res => {
      //       if(res.data.Success){
      //         this.$set(this,'hotSearch',res.data.Data)
      //       }else{
      //         this.$MessageBox.alert(res.data.Error,{type:'error'});
      //       }
      //     }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
      //     })
      // }})
      this.axios.post(process.env.VUE_APP_URLS+'Home/GetSearchKey',this.keys())
      .then(res => {
        if(res.data.Data.SearchKey){
          if(res.data.Data.SearchKey.indexOf(',')!=-1){
            this.hotSearch = res.data.Data.SearchKey.split(',')
          }else{
            this.hotSearch = res.data.Data.SearchKey
          }
        }
      }, res => {
        // this.$MessageBox.alert('系统异常',{type:'error'});
      })
    })
  },
  watch:{
    '$store.state.searchTxt'(n,o){
      this.searchTxt = n
      if(n){
        this.hotShow = false;
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.hotsearch {
    height: 40px;line-height:40px;text-align: right;position: relative;
    .ipt {
      width: 200px;height: 28px;background: #ebebeb;border-radius:20px;border:1px solid #ebebeb;padding-left:20px;
      border:1px solid #cecece;
      &:hover {
        // border:1px solid #cecece;
      }
    }
    .sh {
      position: absolute;top:2px;right:0px;background: none;width:40px;height: 40px;line-height: 40px;
      svg {
        width: 16px;height: 16px;vertical-align: -1px;fill: #333;
      }
    }
    .hots {
      position: absolute;top:0px;right: 40px;
      button {
        margin-left:20px;background: none;font-size: 12px;color:#6e6e6e;
        &:hover {
          color:#a43444;
        }
        &:first-child {
          margin-left:0px;
        }
      }
    }
}
.type {
  background: #fff;width: 252px;padding:0 15px;height: 70px;
  h6 {
    position: relative;height: 24px;line-height: 24px;font-size: 14px;padding-left:13px;color:#252525;padding-top:17px;text-align:left;
    &:after{
      content: '';display: block;position: absolute;width: 8px;height: 24px;background: #fe7c80;border-radius: 5px;top:17px;left:0px;
    }
  }
  .ipt {
    width: 242px;padding-left:10px;
  }
  .sh  {
    right:13px;top:43px;
  }
  .hots {
    top:40px;right: 50px;
  }
}
</style>
