function Base64code() {
    // private property
    let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    // public method for encoding
    this.encode = function(input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = _utf8_encode(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
                _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
                _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
        }
        return output;
    }
    // public method for decoding
    this.decode = function(input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            enc1 = _keyStr.indexOf(input.charAt(i++));
            enc2 = _keyStr.indexOf(input.charAt(i++));
            enc3 = _keyStr.indexOf(input.charAt(i++));
            enc4 = _keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }
        output = _utf8_decode(output);
        return output;
    }
    // private method for UTF-8 encoding
    var _utf8_encode = function(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    }
    // private method for UTF-8 decoding
    var _utf8_decode = function(utftext) {
        var string = "";
        var i = 0;
        var c = 0,
            c1 = 0,
            c2 = 0,
            c3
        while (i < utftext.length) {
            c = utftext.charCodeAt(i);
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    }
}
/*
 * [js-sha1]{@link https://github.com/emn178/js-sha1}
 *
 * @version 0.6.0
 * @author Chen, Yi-Cyuan [emn178@gmail.com]
 * @copyright Chen, Yi-Cyuan 2014-2017
 * @license MIT
 */
// /*jslint bitwise: true */秘钥加密
(function() {
  'use strict';

  var root = typeof window === 'object' ? window : {};
  var NODE_JS = !root.JS_SHA1_NO_NODE_JS && typeof process === 'object' && process.versions && process.versions.node;
  if (NODE_JS) {
    root = global;
  }
  var COMMON_JS = !root.JS_SHA1_NO_COMMON_JS && typeof module === 'object' && module.exports;
  var AMD = typeof define === 'function' && define.amd;
  var HEX_CHARS = '0123456789abcdef'.split('');
  var EXTRA = [-2147483648, 8388608, 32768, 128];
  var SHIFT = [24, 16, 8, 0];
  var OUTPUT_TYPES = ['hex', 'array', 'digest', 'arrayBuffer'];

  var blocks = [];

  var createOutputMethod = function (outputType) {
    return function (message) {
      return new Sha1(true).update(message)[outputType]();
    };
  };

  var createMethod = function () {
    var method = createOutputMethod('hex');
    if (NODE_JS) {
      method = nodeWrap(method);
    }
    method.create = function () {
      return new Sha1();
    };
    method.update = function (message) {
      return method.create().update(message);
    };
    for (var i = 0; i < OUTPUT_TYPES.length; ++i) {
      var type = OUTPUT_TYPES[i];
      method[type] = createOutputMethod(type);
    }
    return method;
  };

  var nodeWrap = function (method) {
    var crypto = eval("require('crypto')");
    var Buffer = eval("require('buffer').Buffer");
    var nodeMethod = function (message) {
      if (typeof message === 'string') {
        return crypto.createHash('sha1').update(message, 'utf8').digest('hex');
      } else if (message.constructor === ArrayBuffer) {
        message = new Uint8Array(message);
      } else if (message.length === undefined) {
        return method(message);
      }
      return crypto.createHash('sha1').update(new Buffer(message)).digest('hex');
    };
    return nodeMethod;
  };

  function Sha1(sharedMemory) {
    if (sharedMemory) {
      blocks[0] = blocks[16] = blocks[1] = blocks[2] = blocks[3] =
      blocks[4] = blocks[5] = blocks[6] = blocks[7] =
      blocks[8] = blocks[9] = blocks[10] = blocks[11] =
      blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
      this.blocks = blocks;
    } else {
      this.blocks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    this.h0 = 0x67452301;
    this.h1 = 0xEFCDAB89;
    this.h2 = 0x98BADCFE;
    this.h3 = 0x10325476;
    this.h4 = 0xC3D2E1F0;

    this.block = this.start = this.bytes = this.hBytes = 0;
    this.finalized = this.hashed = false;
    this.first = true;
  }

  Sha1.prototype.update = function (message) {
    if (this.finalized) {
      return;
    }
    var notString = typeof(message) !== 'string';
    if (notString && message.constructor === root.ArrayBuffer) {
      message = new Uint8Array(message);
    }
    var code, index = 0, i, length = message.length || 0, blocks = this.blocks;

    while (index < length) {
      if (this.hashed) {
        this.hashed = false;
        blocks[0] = this.block;
        blocks[16] = blocks[1] = blocks[2] = blocks[3] =
        blocks[4] = blocks[5] = blocks[6] = blocks[7] =
        blocks[8] = blocks[9] = blocks[10] = blocks[11] =
        blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
      }

      if(notString) {
        for (i = this.start; index < length && i < 64; ++index) {
          blocks[i >> 2] |= message[index] << SHIFT[i++ & 3];
        }
      } else {
        for (i = this.start; index < length && i < 64; ++index) {
          code = message.charCodeAt(index);
          if (code < 0x80) {
            blocks[i >> 2] |= code << SHIFT[i++ & 3];
          } else if (code < 0x800) {
            blocks[i >> 2] |= (0xc0 | (code >> 6)) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | (code & 0x3f)) << SHIFT[i++ & 3];
          } else if (code < 0xd800 || code >= 0xe000) {
            blocks[i >> 2] |= (0xe0 | (code >> 12)) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | ((code >> 6) & 0x3f)) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | (code & 0x3f)) << SHIFT[i++ & 3];
          } else {
            code = 0x10000 + (((code & 0x3ff) << 10) | (message.charCodeAt(++index) & 0x3ff));
            blocks[i >> 2] |= (0xf0 | (code >> 18)) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | ((code >> 12) & 0x3f)) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | ((code >> 6) & 0x3f)) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | (code & 0x3f)) << SHIFT[i++ & 3];
          }
        }
      }

      this.lastByteIndex = i;
      this.bytes += i - this.start;
      if (i >= 64) {
        this.block = blocks[16];
        this.start = i - 64;
        this.hash();
        this.hashed = true;
      } else {
        this.start = i;
      }
    }
    if (this.bytes > 4294967295) {
      this.hBytes += this.bytes / 4294967296 << 0;
      this.bytes = this.bytes % 4294967296;
    }
    return this;
  };

  Sha1.prototype.finalize = function () {
    if (this.finalized) {
      return;
    }
    this.finalized = true;
    var blocks = this.blocks, i = this.lastByteIndex;
    blocks[16] = this.block;
    blocks[i >> 2] |= EXTRA[i & 3];
    this.block = blocks[16];
    if (i >= 56) {
      if (!this.hashed) {
        this.hash();
      }
      blocks[0] = this.block;
      blocks[16] = blocks[1] = blocks[2] = blocks[3] =
      blocks[4] = blocks[5] = blocks[6] = blocks[7] =
      blocks[8] = blocks[9] = blocks[10] = blocks[11] =
      blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
    }
    blocks[14] = this.hBytes << 3 | this.bytes >>> 29;
    blocks[15] = this.bytes << 3;
    this.hash();
  };

  Sha1.prototype.hash = function () {
    var a = this.h0, b = this.h1, c = this.h2, d = this.h3, e = this.h4;
    var f, j, t, blocks = this.blocks;

    for(j = 16; j < 80; ++j) {
      t = blocks[j - 3] ^ blocks[j - 8] ^ blocks[j - 14] ^ blocks[j - 16];
      blocks[j] =  (t << 1) | (t >>> 31);
    }

    for(j = 0; j < 20; j += 5) {
      f = (b & c) | ((~b) & d);
      t = (a << 5) | (a >>> 27);
      e = t + f + e + 1518500249 + blocks[j] << 0;
      b = (b << 30) | (b >>> 2);

      f = (a & b) | ((~a) & c);
      t = (e << 5) | (e >>> 27);
      d = t + f + d + 1518500249 + blocks[j + 1] << 0;
      a = (a << 30) | (a >>> 2);

      f = (e & a) | ((~e) & b);
      t = (d << 5) | (d >>> 27);
      c = t + f + c + 1518500249 + blocks[j + 2] << 0;
      e = (e << 30) | (e >>> 2);

      f = (d & e) | ((~d) & a);
      t = (c << 5) | (c >>> 27);
      b = t + f + b + 1518500249 + blocks[j + 3] << 0;
      d = (d << 30) | (d >>> 2);

      f = (c & d) | ((~c) & e);
      t = (b << 5) | (b >>> 27);
      a = t + f + a + 1518500249 + blocks[j + 4] << 0;
      c = (c << 30) | (c >>> 2);
    }

    for(; j < 40; j += 5) {
      f = b ^ c ^ d;
      t = (a << 5) | (a >>> 27);
      e = t + f + e + 1859775393 + blocks[j] << 0;
      b = (b << 30) | (b >>> 2);

      f = a ^ b ^ c;
      t = (e << 5) | (e >>> 27);
      d = t + f + d + 1859775393 + blocks[j + 1] << 0;
      a = (a << 30) | (a >>> 2);

      f = e ^ a ^ b;
      t = (d << 5) | (d >>> 27);
      c = t + f + c + 1859775393 + blocks[j + 2] << 0;
      e = (e << 30) | (e >>> 2);

      f = d ^ e ^ a;
      t = (c << 5) | (c >>> 27);
      b = t + f + b + 1859775393 + blocks[j + 3] << 0;
      d = (d << 30) | (d >>> 2);

      f = c ^ d ^ e;
      t = (b << 5) | (b >>> 27);
      a = t + f + a + 1859775393 + blocks[j + 4] << 0;
      c = (c << 30) | (c >>> 2);
    }

    for(; j < 60; j += 5) {
      f = (b & c) | (b & d) | (c & d);
      t = (a << 5) | (a >>> 27);
      e = t + f + e - 1894007588 + blocks[j] << 0;
      b = (b << 30) | (b >>> 2);

      f = (a & b) | (a & c) | (b & c);
      t = (e << 5) | (e >>> 27);
      d = t + f + d - 1894007588 + blocks[j + 1] << 0;
      a = (a << 30) | (a >>> 2);

      f = (e & a) | (e & b) | (a & b);
      t = (d << 5) | (d >>> 27);
      c = t + f + c - 1894007588 + blocks[j + 2] << 0;
      e = (e << 30) | (e >>> 2);

      f = (d & e) | (d & a) | (e & a);
      t = (c << 5) | (c >>> 27);
      b = t + f + b - 1894007588 + blocks[j + 3] << 0;
      d = (d << 30) | (d >>> 2);

      f = (c & d) | (c & e) | (d & e);
      t = (b << 5) | (b >>> 27);
      a = t + f + a - 1894007588 + blocks[j + 4] << 0;
      c = (c << 30) | (c >>> 2);
    }

    for(; j < 80; j += 5) {
      f = b ^ c ^ d;
      t = (a << 5) | (a >>> 27);
      e = t + f + e - 899497514 + blocks[j] << 0;
      b = (b << 30) | (b >>> 2);

      f = a ^ b ^ c;
      t = (e << 5) | (e >>> 27);
      d = t + f + d - 899497514 + blocks[j + 1] << 0;
      a = (a << 30) | (a >>> 2);

      f = e ^ a ^ b;
      t = (d << 5) | (d >>> 27);
      c = t + f + c - 899497514 + blocks[j + 2] << 0;
      e = (e << 30) | (e >>> 2);

      f = d ^ e ^ a;
      t = (c << 5) | (c >>> 27);
      b = t + f + b - 899497514 + blocks[j + 3] << 0;
      d = (d << 30) | (d >>> 2);

      f = c ^ d ^ e;
      t = (b << 5) | (b >>> 27);
      a = t + f + a - 899497514 + blocks[j + 4] << 0;
      c = (c << 30) | (c >>> 2);
    }

    this.h0 = this.h0 + a << 0;
    this.h1 = this.h1 + b << 0;
    this.h2 = this.h2 + c << 0;
    this.h3 = this.h3 + d << 0;
    this.h4 = this.h4 + e << 0;
  };

  Sha1.prototype.hex = function () {
    this.finalize();

    var h0 = this.h0, h1 = this.h1, h2 = this.h2, h3 = this.h3, h4 = this.h4;

    return HEX_CHARS[(h0 >> 28) & 0x0F] + HEX_CHARS[(h0 >> 24) & 0x0F] +
           HEX_CHARS[(h0 >> 20) & 0x0F] + HEX_CHARS[(h0 >> 16) & 0x0F] +
           HEX_CHARS[(h0 >> 12) & 0x0F] + HEX_CHARS[(h0 >> 8) & 0x0F] +
           HEX_CHARS[(h0 >> 4) & 0x0F] + HEX_CHARS[h0 & 0x0F] +
           HEX_CHARS[(h1 >> 28) & 0x0F] + HEX_CHARS[(h1 >> 24) & 0x0F] +
           HEX_CHARS[(h1 >> 20) & 0x0F] + HEX_CHARS[(h1 >> 16) & 0x0F] +
           HEX_CHARS[(h1 >> 12) & 0x0F] + HEX_CHARS[(h1 >> 8) & 0x0F] +
           HEX_CHARS[(h1 >> 4) & 0x0F] + HEX_CHARS[h1 & 0x0F] +
           HEX_CHARS[(h2 >> 28) & 0x0F] + HEX_CHARS[(h2 >> 24) & 0x0F] +
           HEX_CHARS[(h2 >> 20) & 0x0F] + HEX_CHARS[(h2 >> 16) & 0x0F] +
           HEX_CHARS[(h2 >> 12) & 0x0F] + HEX_CHARS[(h2 >> 8) & 0x0F] +
           HEX_CHARS[(h2 >> 4) & 0x0F] + HEX_CHARS[h2 & 0x0F] +
           HEX_CHARS[(h3 >> 28) & 0x0F] + HEX_CHARS[(h3 >> 24) & 0x0F] +
           HEX_CHARS[(h3 >> 20) & 0x0F] + HEX_CHARS[(h3 >> 16) & 0x0F] +
           HEX_CHARS[(h3 >> 12) & 0x0F] + HEX_CHARS[(h3 >> 8) & 0x0F] +
           HEX_CHARS[(h3 >> 4) & 0x0F] + HEX_CHARS[h3 & 0x0F] +
           HEX_CHARS[(h4 >> 28) & 0x0F] + HEX_CHARS[(h4 >> 24) & 0x0F] +
           HEX_CHARS[(h4 >> 20) & 0x0F] + HEX_CHARS[(h4 >> 16) & 0x0F] +
           HEX_CHARS[(h4 >> 12) & 0x0F] + HEX_CHARS[(h4 >> 8) & 0x0F] +
           HEX_CHARS[(h4 >> 4) & 0x0F] + HEX_CHARS[h4 & 0x0F];
  };

  Sha1.prototype.toString = Sha1.prototype.hex;

  Sha1.prototype.digest = function () {
    this.finalize();

    var h0 = this.h0, h1 = this.h1, h2 = this.h2, h3 = this.h3, h4 = this.h4;

    return [
      (h0 >> 24) & 0xFF, (h0 >> 16) & 0xFF, (h0 >> 8) & 0xFF, h0 & 0xFF,
      (h1 >> 24) & 0xFF, (h1 >> 16) & 0xFF, (h1 >> 8) & 0xFF, h1 & 0xFF,
      (h2 >> 24) & 0xFF, (h2 >> 16) & 0xFF, (h2 >> 8) & 0xFF, h2 & 0xFF,
      (h3 >> 24) & 0xFF, (h3 >> 16) & 0xFF, (h3 >> 8) & 0xFF, h3 & 0xFF,
      (h4 >> 24) & 0xFF, (h4 >> 16) & 0xFF, (h4 >> 8) & 0xFF, h4 & 0xFF
    ];
  };

  Sha1.prototype.array = Sha1.prototype.digest;

  Sha1.prototype.arrayBuffer = function () {
    this.finalize();

    var buffer = new ArrayBuffer(20);
    var dataView = new DataView(buffer);
    dataView.setUint32(0, this.h0);
    dataView.setUint32(4, this.h1);
    dataView.setUint32(8, this.h2);
    dataView.setUint32(12, this.h3);
    dataView.setUint32(16, this.h4);
    return buffer;
  };

  var exports = createMethod();

  if (COMMON_JS) {
    module.exports = exports;
  } else {
    root.sha1 = exports;
    if (AMD) {
      define(function () {
        return exports;
      });
    }
  }
})();

// 秘钥表单
function keys(parms){
    if(parms){
      var secret = 'y148ertyjhg698428r147ytu36985472';
      parms.app_key = 'omsa786df67r5d0w6407p';
      parms.timestamp = Math.round(new Date()/1000)+10;
      // console.log(parms)
      for(let i in parms){
        if(parms[i]&&i!='FileBase'&&i!='fileBase'&&i!='file'&&i!='File'&&i.indexOf('file')==-1&&i!='token'){
          secret +=i + parms[i]
        }
      }
      secret +='y148ertyjhg698428r147ytu36985472';
      // console.log(secret)
      // return;
      var sha = sha1(secret)
      var datat = new FormData();
      for(let i in parms){
        if(parms[i]){
          datat.append(i,parms[i]);
        }
      }
      datat.append('sign',sha.toUpperCase());
      return datat
    }else{
      var timestamp = Math.round(new Date()/1000)+10;
      var app_key =  'omsa786df67r5d0w6407p';
      var secret = 'y148ertyjhg698428r147ytu36985472';
      var sha = sha1(secret+'app_key'+app_key+'timestamp'+timestamp+secret)
      var datat = new FormData();
      datat.append('app_key',app_key);
      datat.append('timestamp',timestamp);
      datat.append('sign',sha.toUpperCase());
      return datat;
    }
}

function regPhone(v) {// 手机号验证
  var TEL_REGEXP = /^1[3456789]\d{9}$/;
  return TEL_REGEXP.test(v);
}
function regEmail(v) {// 邮箱验证
  var TEL_REGEXP = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
  return TEL_REGEXP.test(v);
}
function startTimeCount(v){
  var _this = this;
  // 倒计时---距离开始
  var nowTime = Date.now();
  var edTime = new Date(v).getTime();
  var leftTime = edTime - nowTime; //时间差
  var  d,h, m, s,hs;
  if(leftTime >= 0) {
      d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      h = Math.floor(leftTime / 1000 / 60 / 60%24);
      hs = Math.floor(leftTime / 1000 / 60 / 60);
      m = Math.floor(leftTime / 1000 / 60 % 60);
      s = Math.floor(leftTime / 1000 % 60);
      if(s < 10) {
          s = "0" + s;
      }
      if(m < 10) {
          m = "0" + m;
      }
      if(h < 10) {
          h = "0" + h;
      }
      if(hs < 10) {
          hs = "0" + hs;
      }
      if(d>0){
        // return d+'天'+h+':'+m+':'+s
        return {
          d:d,
          h:h,
          hs:hs,
          m:m,
          s:s
        }
        // _this.$set( _this.startTms, index, d+'天'+h+':'+m+':'+s )
      }else{
        // return  h+':'+m+':'+s
        return {
          h:h,
          hs:hs,
          m:m,
          s:s
        }
        // _this.$set( _this.startTms, index, h+':'+m+':'+s )
      }
  }
}
function endTimeCount(v){
  var _this = this;
  // 倒计时---距离结束
  var nowTime = Date.now();
  var edTime = new Date(v).getTime();
  var leftTime = edTime - nowTime; //时间差
  var  d,h, m, s;
  if(leftTime >= 0) {
      d = Math.floor(leftTime / 1000 / 60 / 60 /24);
      h = Math.floor(leftTime / 1000 / 60 / 60%24);
      m = Math.floor(leftTime / 1000 / 60 % 60);
      s = Math.floor(leftTime / 1000 % 60);
      if(s < 10) {
          s = "0" + s;
      }
      if(m < 10) {
          m = "0" + m;
      }
      if(h < 10) {
          h = "0" + h;
      }
      if(d>0){
        // return d+'天'+h+':'+m+':'+s
        return {
          d:d,
          h:h,
          m:m,
          s:s
        }
        // _this.$set( _this.endTms, index, d+'天'+h+':'+m+':'+s )
      }else{
        // return h+':'+m+':'+s
        return {
          h:h,
          m:m,
          s:s
        }
        // _this.$set( _this.endTms, index, h+':'+m+':'+s )
      }
  }
}
function setOrGetCode(param){
  // 存取验证码
  if(param.method=='set'){
    var _data = {
      code:param.code,
      time:Date.now()
    }
    window.sessionStorage.setItem('phonecode',JSON.stringify(_data))
  }
  if(param.method=='get'){
    var _time = Date.now()
    var _data = JSON.parse(window.sessionStorage.getItem('phonecode'))
    if(_data&&((_time - _data.time)>=600000)){
      // 超过十分钟验证码失效
      window.sessionStorage.removeItem('phonecode')
      return null
    }else if(_data&&((_time - _data.time)<600000)){
      return _data.code
    }else{
      return null
    }
  }
}
function setOrGetPhone(param){
  // 存取记住账号状态和账号
  if(param.method=='set'){
    window.localStorage.setItem('phonedata',param.phone)
  }
  if(param.method=='get'){
    var _data = JSON.parse(window.localStorage.getItem('phonedata'))
    return _data
  }
  if(param.method=='del'){
    window.localStorage.removeItem('phonedata')
  }
}
function usermethod(param){
  // 用户数据存取
  if(param.method=='set'){
    var _data = {
      user:param.user,
      time:Date.now()
    }
    window.localStorage.setItem('userdata',JSON.stringify(_data))
  }
  if(param.method=='get'){
    return JSON.parse(window.localStorage.getItem('userdata'))
  }
  if(param.method=='del'){
    window.localStorage.removeItem('userdata')
  }
}
function tokenmethod(param){
  // token数据存取
  if(param.method=='set'){
    var _token = {
      token:param.token,
      time:Date.now()
    }
    window.localStorage.setItem('tokens',JSON.stringify(_token))
  }
  if(param.method=='get'){
    var _token = JSON.parse(window.localStorage.getItem('tokens'));
    var _nowDate = Date.now();
    if(_token){
      if((parseInt(_nowDate)-parseInt(_token.time))>=86400000){
        // 超过24小时过期-删除token
        window.localStorage.removeItem('tokens')
        return ''
      }else{
        // 没过期重新存储
        var _token = {
          token:_token.token,
          time:Date.now()
        }
        window.localStorage.setItem('tokens',JSON.stringify(_token))
        return JSON.parse(window.localStorage.getItem('tokens')).token
      }
    }else{
      return ''
    }
  }
  if(param.method=='del'){
    window.localStorage.removeItem('tokens')
  }
}
function setCarlist(param){
  // 存储本地购物车数据
  if(param.method=='get'){
    var _list = JSON.parse(window.localStorage.getItem('carlist'));
    if(_list){
      for(let i in _list){
        if(!_list[i].carId){
          _list.splice(i,1)
        }
      }
    }
    return _list;
  }
  if(param.method=='set'){
    var list = JSON.parse(window.localStorage.getItem('carlist'))
    if(list){
      var _add = 0;
      for(let i in list){
        if(param.data.carId==list[i].carId){
          _add = 1;
          // list[i].buynumber = parseInt(list[i].buynumber)+parseInt(param.data.buynumber)
          list[i].buynumber = parseInt(param.data.buynumber)
        }
      }
      if(_add==0){
        list.push(param.data)
      }
      for(let i in list){
        if(!list[i].carId){
          list.splice(i,1)
        }
      }
      window.localStorage.setItem('carlist',JSON.stringify(list))
    }else{
      var _list = []
      _list.push(param.data)
      window.localStorage.setItem('carlist',JSON.stringify(_list))
    }
  }
  if(param.method=='del'){
    window.localStorage.removeItem('carlist')
  }
}
// 图片dataBase64转bold
function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
var mixin = {// 公共混入对象
  filters: {
    formatPrice: function (value) {
      // 价格格式化过滤器
      value = Math.floor(value*100)/100;
      if(value%1===0){
      	value +='.00'
      }
      return value;
    },
    formatPhoneHide: function (value,number) {
      // 手机号加星号
      value+=''
      if(regPhone(value)){
        if(value!=''&&!number){
          var left = value.slice(0,3)
          var right = value.slice(-2,value.length)
          value = left + "******" + right
        }
        if(value!=''&&number){
          var left = value.slice(0,3)
          var right = value.slice(-4,value.length)
          value = left + "****" + right
        }
        return value;
      }else{
        return value
      }
    },
    timefiter(v,typ){
      if(typ&&typ==1&&v){
        return v.split('T')[0]
      }
      if(typ&&typ==0&&v){
        return v.split('T')[0]+' '+v.split('T')[1]
      }
      return '无时间'
    }
  },
  methods:{
    randomColor(){//浅色随机
      var _r = 242;
      var _g = 206;
      var start = _r-_g+1;
      var rgb = Math.floor(Math.random()*3);
      var r,g,b;
      switch(rgb) {
        case 0:
          r=_r;
          var gb = Math.floor(Math.random()*2);
          switch(gb) {
            case 0:
              g=_g;
              b=Math.floor(Math.random()*start)+_r;
              break;
            case 1:
              b=_g;
              g=Math.floor(Math.random()*start)+_r;
              break;
          }
          break;
        case 1:
          g=_r
          var rb = Math.floor(Math.random()*2);
          switch(rb) {
            case 0:
              r=_g;
              b=Math.floor(Math.random()*start)+_r;
              break;
            case 1:
              b=_g;
              r=Math.floor(Math.random()*start)+_r;
              break;
          }
          break;
        case 2:
          b=_r
          var rg = Math.floor(Math.random()*2);
          switch(rg) {
            case 0:
              r=_g;
              g=Math.floor(Math.random()*start)+_r;
              break;
            case 1:
              g=_g;
              r=Math.floor(Math.random()*start)+_r;
              break;
          }
          break;
      }
      // console.log('rgba('+r+','+g+','+b+',1'+')')
      return 'rgba('+r+','+g+','+b+',1'+')'
    },
    randomColor1(){//深色随机
      var _r = 127;
      var _g = 199;
      var start = _r-_g+1;
      var rgb = Math.floor(Math.random()*3);
      var r,g,b;
      switch(rgb) {
        case 0:
          r=_r;
          var gb = Math.floor(Math.random()*2);
          switch(gb) {
            case 0:
              g=_g;
              b=Math.floor(Math.random()*start)+_r;
              break;
            case 1:
              b=_g;
              g=Math.floor(Math.random()*start)+_r;
              break;
          }
          break;
        case 1:
          g=_r
          var rb = Math.floor(Math.random()*2);
          switch(rb) {
            case 0:
              r=_g;
              b=Math.floor(Math.random()*start)+_r;
              break;
            case 1:
              b=_g;
              r=Math.floor(Math.random()*start)+_r;
              break;
          }
          break;
        case 2:
          b=_r
          var rg = Math.floor(Math.random()*2);
          switch(rg) {
            case 0:
              r=_g;
              g=Math.floor(Math.random()*start)+_r;
              break;
            case 1:
              g=_g;
              r=Math.floor(Math.random()*start)+_r;
              break;
          }
          break;
      }
      // console.log('rgba('+r+','+g+','+b+',1'+')')
      return 'rgba('+r+','+g+','+b+',1'+')'
    }
  }
}
export  {
  keys,
  mixin,
  regPhone,
  regEmail,
  startTimeCount,
  endTimeCount,
  setOrGetCode,
  setOrGetPhone,
  usermethod,
  setCarlist,
  Base64code,
  tokenmethod
}

