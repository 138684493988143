<!-- 卡片轮播 -->
<template>
  <el-carousel  :autoplay="false" :arrow="arrow" :initial-index='start' :loop='loop' @change='change' :height='height'  type="card" ref='cardCal'>
    <el-carousel-item v-for="item,index in carousels" :key="index" >
      <div @click='links(item,NewProductDto.Id)'>
        <div class="imgbox">
          <img :src='item.src'>
          <svg class="icon"><use xlink:href="#iconziyuan21"></use></svg>
        </div>
        <div class="info">
          <h6 :title='item.title'>{{item.title}}</h6>
          <p class="tip">{{item.tip}}</p>
          <p class="price"><span>&yen;</span>{{item.price}}</p>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: 'carouselCard',
  props: {
    height: String,
    carousels:Array,
    arrow:{
      default:'hover',
      type:String
    },
    loop:{
      default:false,
      type:Boolean
    },
    start:{
      default:1,
      type:Number
    },
    NewProductDto:null
  },
  data(){
    return {
    }
  },
  methods:{
    change(v){
      // console.log(v,console.log(this.carousels.length))
      // if(v==0){
      //   this.$refs.cardCal.setActiveItem(1)
      // }

      // if(v==(parseInt(this.carousels.length)-1)){
      //   this.$refs.cardCal.setActiveItem((parseInt(this.carousels.length)-2))
      // }
    },
    links(i,Id){
      // console.log(i)
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:Id,id:id,}})
      }})
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>

.el-carousel__item {
  .imgbox {
    width: 240px;height: 240px;display: flex;justify-content: center;align-items: center;position: relative;margin:0 auto;
    img {
      width: 150px;height: 150px;
    }
    svg {
      position: absolute;top:10px;right:0px;width: 40px;height: 40px;display: none;
    }
  }
}
.info {
  display: none;
  h6 {
    text-align: center;width: 100%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size: 16px;height: 26px;line-height: 26px;
  }
  .tip {
    text-align: center;color:#818181;font-size: 16px;
  }
  .price {
    font-size:24px;font-weight:bold;color:#d43f3b;text-align: center;height: 32px;line-height:32px;margin-top:2px;
    span {
      font-size: 16px;margin-right: 2px;
    }
  }
}
.is-active {
  .imgbox {
    width: 240px;height: 240px;margin:0 auto;
    img {
      width: 240px;height: 240px;
    }
    svg {
      display: block;
    }
  }
}
.is-active:hover {
  .info {
    opacity: 0.8;
  }
}
</style>
