import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// '/', '/limit', '/super', '/predetail', '/newpro', '/accountlogin', '/accountregister', '/buycar', '/settlebill', '/payment', '/paymentalipay', '/paymentwechat', '/payresult', '/liveonlin', '/showcolumn', '/classlist'
import MetaInfo from "vue-meta-info"; //seo优化
import {keys,usermethod,tokenmethod} from './assets/js/common.js';//秘钥
// import './registerServiceWorker'
// import ElementUI from 'element-ui'; //element-ui
// import 'element-ui/lib/theme-chalk/index.css';//element-ui
// import {Message,MessageBox} from 'element-ui';//单独提示组件可布局全局对象
import {Row,Col,Carousel,CarouselItem,MessageBox,InputNumber,Pagination,Message,Collapse,
Tabs,CollapseItem,TabPane,Image,Radio,RadioGroup,RadioButton,Select,Option,Popover,Dialog,
Timeline,TimelineItem,Upload,DatePicker} from 'element-ui';//单独提示组件可布局全局对象
import SlideVerify from 'vue-monoplasty-slide-verify';//滑动验证

// import vUploader from 'v-uploader';//批量上传图片
// // v-uploader plugin global config
// const uploaderConfig = {//批量上传图片
//   // file uploader service url
//   uploadFileUrl: 'http://xxx/upload/publicFileUpload',
//   // file delete service url
//   deleteFileUrl: 'http://xxx/upload/deleteUploadFile',
//   // set the way to show upload message(upload fail message)
//   showMessage: (vue, message) => {
//     //using v-dialogs to show message
//     vue.$dlg.alert(message, {messageType: 'error'});
//   }
// };


import NProgress from "nprogress";//页面加载进度
import "nprogress/nprogress.css";//页面加载进度

Vue.config.productionTip = false
Vue.use(SlideVerify);//滑动验证
// Vue.use(ElementUI);//element-ui
Vue.use(Row);//行布局
Vue.use(Col);//列布局
Vue.use(Carousel);//走马灯
Vue.use(CarouselItem);//走马灯
Vue.use(InputNumber);//计数
Vue.use(Collapse);//折叠面板
Vue.use(CollapseItem);//折叠面板
Vue.use(Tabs);//标签页
Vue.use(TabPane);//标签页
Vue.use(Image);//图片组件
Vue.use(Radio);//单选组件
Vue.use(RadioGroup);//单选组件
Vue.use(RadioButton);//单选组件
Vue.use(Select);//下拉选择
Vue.use(Option);//下拉选择
Vue.use(Popover);//冒泡弹出框
Vue.use(Dialog);//自定义弹窗
Vue.use(Upload);//上传
Vue.use(Pagination);//分页
Vue.use(Timeline);//时间轴
Vue.use(TimelineItem);//时间轴
Vue.use(DatePicker);//时间选择框
Vue.use(MetaInfo);//seo优化
// Vue.use(vUploader, uploaderConfig);//批量上传图片
Vue.prototype.axios = axios
Vue.prototype.keys=keys;
Vue.prototype.$MessageBox = MessageBox;//单独提示组件可布局全局对象
Vue.prototype.$Message = Message;//单独提示组件可布局全局对象
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype._src = 'http://192.168.1.240:8003/';//全局文件地址
Vue.prototype._src = 'http://files.wwding.com/';//全局文件地址线上
Vue.prototype._token = tokenmethod;//全局token存取方式
Vue.prototype._usermet = usermethod;//全局user存取方式

Vue.prototype._axios = function(param){
  // 自定义请求是否带权限---必须登录才能请求
  // exp--是否验证权限
  // then--不用验证或者验证权限成功
  // catch--验证权限失败
  if(param.exp){
    if(usermethod({method:'get'})){
      // 每次访问路由判断用户数据是否过期默认不操作30分钟---需要验证用户信息的页面跳转的事件手动判断存储的用户信息是否存在
      // 604800000   ---7天
      // 86400000   ---24小时
      var _users = usermethod({method:'get'})
      var _token = tokenmethod({method:'get'})
      var _nowDate = Date.now();
      if((parseInt(_nowDate)-parseInt(_users.time))>=86400000){
        // 退出登陆
        store.dispatch("cmtSetUser",null)
        usermethod({method:'del'});
        tokenmethod({method:'del'});
        param.catch()
      }else{
        // 保持用户信息
        store.dispatch("cmtSetUser",_users.user)
        //重新存储---每次存储重置缓存时间
        usermethod({method:'set',user:_users.user});
        tokenmethod({method:'set',token:_token});
        param.then()
      }
    }else{
      param.catch()
    }
  }else{
    param.then()
  }
}
Vue.prototype._router = function(param){
  if(usermethod({method:'get'})){
    // 每次访问路由判断用户数据是否过期默认不操作30分钟---需要验证用户信息的页面跳转的事件手动判断存储的用户信息是否存在
    var _users = usermethod({method:'get'})
    var _token = tokenmethod({method:'get'})
    var _nowDate = Date.now();
    if((parseInt(_nowDate)-parseInt(_users.time))>=86400000){
      // 退出登陆
      store.dispatch("cmtSetUser",null)
      usermethod({method:'del'});
      tokenmethod({method:'del'});
    }else{
      // 保持用户信息
      store.dispatch("cmtSetUser",_users.user)
      //重新存储---每次存储重置缓存时间
      usermethod({method:'set',user:_users.user});
      tokenmethod({method:'set',token:_token});
    }
  }
  //自定义跳转是否带权限---必须登录才能路由
  // exp--是否验证权限
  // path--跳转路径
  // then--不用验证或者验证权限成功
  // catch--验证权限失败
  if(param.exp){
    if(!usermethod({method:'get'})) {
      // console.log(router)
      // console.log(store.state.toFullPath)
      param.catch()
      // console.log(router)
      // param.then()
    }else{
      param.then()
    }
  }else{
    param.then()
  }
}
Vue.prototype._open=function(param){
  // 路由跳转新窗口
  let routeUrl = router.resolve(param);
  window.open(routeUrl.href, '_blank');
}

Vue.prototype._spf = function(parms){//参数处理
  if(parms){
    parms +=''
    if(parms.indexOf('.html')==-1){
      return parms
    }else{
      return parms.substring(0,parms.indexOf('.html'))
    }
  }else{
    return false
  }
}

Vue.prototype._NProgress = NProgress;

// 组织函数队列
Vue.prototype._reduce = function reduce(arr) {
  var sequence = Promise.resolve()
  arr.forEach(function(item) {
      sequence = sequence.then(item)
  })
  return sequence
}
Vue.prototype._settitle = function(param){
  // 微微定wwding.com-纸巾定制上微微定 品质保障
  window.document.title = param.title
}
router.beforeEach((to,from,next) => {
  NProgress.start();//页面加载进度
  // store.dispatch("cmtCap",true);//打开关闭遮罩
  store.dispatch("cmttoFullPath",to.fullPath)
  store.dispatch("cmtFromFullPath",from.fullPath)
  store.dispatch("cmtSetLayout",true);//默认设置布局变量
  store.dispatch("cmtLayoutBuyCar",true);//默认设置布局变量
  // console.log(router);//获得所有路由参数，匹配不到重定向404页面。
  // console.log(to,from)
  if(usermethod({method:'get'})){
    // 每次访问路由判断用户数据是否过期默认不操作30分钟---需要验证用户信息的页面跳转的事件手动判断存储的用户信息是否存在
    var _users = usermethod({method:'get'})
    var _token = tokenmethod({method:'get'})
    var _nowDate = Date.now();
    if((parseInt(_nowDate)-parseInt(_users.time))>=86400000){
      // 退出登陆
      store.dispatch("cmtSetUser",null)
      usermethod({method:'del'});
      tokenmethod({method:'del'});
    }else{
      // 保持用户信息
      store.dispatch("cmtSetUser",_users.user)
      //重新存储---每次存储重置缓存时间
      usermethod({method:'set',user:_users.user});
      tokenmethod({method:'set',token:_token});
    }
  }else{
    if(window.sessionStorage.getItem('topImg')){
      if(JSON.parse(window.sessionStorage.getItem('topImg')).val){
        store.dispatch("cmttopimgshow",true);//改状态
      }
    }else{
      store.dispatch("cmttopimgshow",true);//改状态
    }
  }
  // if(to.path!='/liveonlin'&&to.path!='/newpro'&&to.path!='/super'&&to.path!='/limit'&&to.path!='/predetail'&&to.path!='/buycar'&&to.path!='/settlebill'&&to.path!='/payment'&&to.path!='/paymentalipay'&&to.path!='/paymentwechat'&&to.path!='/classlist'&&to.path!='/payresult'){
  //   window.document.title ='微微定wwding.com-纸巾定制上微微定 品质保障'
  // }
  if(to.path.indexOf('/accountlogin')!=-1||to.path.indexOf('/accountregister')!=-1||to.path.indexOf('/bindaccount')!=-1||to.path.indexOf('/findpassword')!=-1){
    // 根据请求的路径判断是否加载布局组件
    store.dispatch("cmtSetLayout",false)
  }
  if(to.path.indexOf('/buycar')!=-1||to.path.indexOf('/settlebill')!=-1||to.path.indexOf('/payment')!=-1||to.path.indexOf('/paymentalipay')!=-1||to.path.indexOf('/paymentwechat')!=-1||to.path.indexOf('/payresult')!=-1){
    // 根据请求的路径判断是否加载布局组件
    // console.log(store)
    store.dispatch("cmtSetLayout",true)
    store.dispatch("cmtLayoutBuyCar",false)
    // console.log(store)
  }
  if(to.path.indexOf('/showcolumn')!=-1){
    store.dispatch("cmtSetLayout",false)
  }
  if(to.path.indexOf('/classlist')==-1){
    store.dispatch('cmtsearchTxt','');//初始化搜索状态
    window.sessionStorage.removeItem('searchQuery');//不是搜索页面删除搜索缓存
  }
  if(to.path.indexOf('/usercenterorder')==-1){
    window.sessionStorage.removeItem('ordersearchdata');//删除我的订单页面缓存
  }
  if(to.path.indexOf('/usercenter')==-1){
    window.sessionStorage.removeItem('userOrderlist');//删除我的订单页面缓存
  }
  if(to.path.indexOf('/buycar')==-1){
    window.sessionStorage.removeItem('carlist');//不是购物车页面删除搜索缓存
  }
  // if(to.path=='/usercenter'||to.path=='/usercenterorder'||to.path=='/orderdetail'){
  //   // 登录验证--需要登录的路由页面如果没有登录信息就跳转登录
  //   if(usermethod({method:'get'})){
  //     // 每次访问路由判断用户数据是否过期默认不操作30分钟---需要验证用户信息的页面跳转的事件手动判断存储的用户信息是否存在
  //     var _users = usermethod({method:'get'})
  //     var _nowDate = Date.now();
  //     if((parseInt(_nowDate)-parseInt(_users.time))>=5000){
  //       // console.log(to,from,next)
  //       store.dispatch("cmtCap",true);//打开关闭遮罩
  //       NProgress.start();//页面加载进度
  //       next({ path: '/' })
  //       // router.push({ path: '/accountlogin',query:{redirect:to.fullPath},replace:true})
  //     }
  //   }else{
  //     store.dispatch("cmtCap",true);//打开关闭遮罩
  //     NProgress.start();//页面加载进度
  //     next({ path: '/' })
  //     // router.push({ path: '/accountlogin',query:{redirect:to.fullPath},replace:true})
  //     // console.log(to,from,next)
  //   }
  // }
  // if(to.path=='/settlebill'){
  //   if(!store.state.user){
  //     next({
  //       path:'accountlogin',
  //       query:{redirect:this.$route.fullPath}
  //     })
  //   }
  // }
  next()
})

router.afterEach(() => {
  // window.scrollTo(0,0);
  NProgress.done();//页面加载进度
  // store.dispatch("cmtCap",false);//打开关闭遮罩
})

// 请求拦截-请求头信息中添加token
// axios.interceptors.request.use(config => {
//   // config.headers.token = tokenmethod({method:'get'});
//   return config;
// },error => {
//   return Promise.reject(error)
// })

// axios.interceptors.request.use(config => {
//   if(tokenmethod({method:'get'})){
//     // token存在发送token
//     // config.headers.token = tokenmethod({method:'get'})
//   }
//   // console.log(config)
//   return config;
// },err => {
//   return Promise.reject(err);
// });

// 请求拦截
// axios.interceptors.request.use(config => {
// //页面加载进度
// // store.dispatch("cmtCap",true);//打开关闭遮罩
// // NProgress.start();
// return config
// },error => {
// return Promise.reject(error)
// })
axios.interceptors.response.use(response => {
// 预处理相应的数据
// store.dispatch("cmtCap",false);//打开关闭遮罩
// NProgress.done();//页面加载进度
// window.scrollTo(0,0);
  if(response.data&&response.data.Data&&response.data.Data.LoinState&&response.data.Data.LoinState=='fail'){
    store.dispatch("cmtSetUser",null);//赋值
    Vue.prototype._usermet({method:'del'});//存本地
    Vue.prototype._token({method:'del'});//存本地
    window.location.href='/accountlogin?redirect='+store.state.fromFullPath+'.html'
    // router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
  }
  return response
}, error => {
// 错误返回 状态码验证
return Promise.reject(error)
})
// 请求拦截edn
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
