import { render, staticRenderFns } from "./Vfooter.vue?vue&type=template&id=163b28db&scoped=true&"
import script from "./Vfooter.vue?vue&type=script&lang=js&"
export * from "./Vfooter.vue?vue&type=script&lang=js&"
import style0 from "./Vfooter.vue?vue&type=style&index=0&id=163b28db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163b28db",
  null
  
)

export default component.exports